import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Typography } from "@mui/material";

import {
  getApplicationListSelect,
  getCountries,
  getReportsByApplication,
  getReportsBySector,
  getReportByGroupSku,
  getReportByShelfShares,
  getReportByShelfSharesSalePoint,
  getReportByShelfSharesPartners,
  getReportBySegmentBrands,
  getReportBySegmentTypeOfPackaging,
  getReportBySegmentPackagingFormat,
  getSkuGroupeListSelect,
  getSkuListSelect,
  getReportByMSL,
  getId,
  getCitiesSelect,
  getDistrictsSelect,
} from "api";
import { FormInput } from "shared";
import { createStoreData, updateStoreData } from "store";
import ByApplication from "components/Reports/ByApplication";
import BySector from "components/Reports/BySector";
import ByGroupSku from "components/Reports/ByGroupSku";
import ByShelfShares from "components/Reports/ByShelfShares";
import ByShelfSharesSalePoint from "components/Reports/ByShelfSharesSalePoint";
import ByShelfSharesPartners from "components/Reports/ByShelfSharesPartners";
import BySegmentBrands from "components/Reports/BySegmentBrands";
import BySegmentTypeOfPackaging from "components/Reports/BySegmentTypeOfPackaging";
import BySegmentPackagingFormat from "components/Reports/BySegmentPackagingFormat";
import ByMSL from "components/Reports/ByMSL";
import { initialState } from "store/initialState";
import {
  GroupSkuAutocomplete,
  SalePointCategoryAutocomplete,
  BrandAutocomplete,
  TypeOfPackagingAutocomplete,
  PackagingFormatAutocomplete,
  SkuAutocomplete,
} from "components/Autocompletes";
import DatePickerReport from "shared/DatePickerReport";
import { FilterAutocomplete } from "components/Autocompletes/FilterAutocomplete";
import { ApplicationsReports } from "components/Autocompletes/ApplicationsReports";
import { FilterPartner } from "components/Autocompletes/FilterPartner";

export function Reports() {
  const dispatch = useDispatch();
  const {
    user,
    reportsByApplication,
    reportsBySector,
    reportsByGroupSku,
    reportsByShelfShares,
    reportsByShelfSharesSalePoint,
    reportsByShelfSharesPartners,
    reportsBySegmentBrands,
    reportsBySegmentTypeOfPackaging,
    reportsBySegmentPackagingFormat,
    reportsByMSL,
    reportsPersisting: {
      reportType,
      dateRange,
      country,
      city,
      district,
      partner,
      skuGroup,
      sku,
      applicationIds,
      salePointCategory,
      brand,
      typeOfPackaging,
      packagingFormat,
    },
    countries,
    citiesSelect,
    districtsSelect,
    brands,
  } = useSelector((store) => store);
  const [isLoading, setLoading] = useState(false);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportParams, setReportParams] = useState({
    applicationIds: [],
    cityId: null,
    countryId: null,
    dateFrom: dateRange[0],
    dateTo: dateRange[1],
    districtId: null,
    partnerId: null,
  });
  const [reportTypeIndex, setReportTypeIndex] = useState(0);

  React.useMemo(() => {
    if (reportParams.countryId) {
      dispatch(
        getCitiesSelect({
          countryId: reportParams.countryId,
        })
      );
    }
    if (reportParams.cityId) {
      dispatch(
        getDistrictsSelect({
          cityId: reportParams.cityId,
        })
      );
    }
    getReportFiltered(reportTypeIndex);
  }, [reportParams, reportTypeIndex]);

  let isAdmin = user?.role === process.env.REACT_APP_ADMIN;

  // React.useEffect(() => {
  //   setLoading(true);

  //   dispatch(
  //     getReportsByApplication(
  //       {
  //         partnerId: getId(partner),
  //         countryId: getId(country),
  //         cityId: getId(city),
  //         districtId: getId(district),
  //         applicationIds: applicationIds.map((e) => e.id),
  //         dateFrom: dateRange[0],
  //         dateTo: dateRange[1],
  //       },
  //       () => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCountries());
    dispatch(getSkuGroupeListSelect());
    dispatch(getApplicationListSelect());
    dispatch(getSkuListSelect());
  }, [dispatch]);

  React.useEffect(() => {
    let r = [
      { title: "По заявкам", value: 0 },
      { title: "По сектору", value: 1 },
      { title: "По группе товара", value: 2 },
      {
        title: "По доли полки по району",
        value: 3,
      },
      { title: "По доле полки по ТТ", value: 4 },
      {
        title: "По доле полки по партнерам",
        value: 5,
      },
      { title: "По брендам", value: 6 },
      { title: "По типу упаковки", value: 7 },
      { title: "По формату упаковки", value: 8 },
      { title: "По MSL", value: 9 },
    ];
    if (!isAdmin) {
      r.splice(5, 1);
    }
    setReportTypes(r);
  }, [isAdmin]);

  const getFiltered = (param) => {
    const params = {
      reportType,
      countryId: getId(country),
      cityId: getId(city),
      districtId: getId(district),
      skuGroupId: getId(skuGroup),
      skuId: getId(sku),
      partnerId: getId(partner),
      applicationIds,
      salePointCategoryId: getId(salePointCategory),
      brandId: getId(brand),
      typeOfPackagingId: getId(typeOfPackaging),
      packagingFormatId: getId(packagingFormat),
      ...param,
    };

    const getDataFilter = (fields) => {
      const result = {};
      fields.forEach((key) => (result[key] = params[key]));
      return {
        ...result,
        dateFrom: params.dateRange?.[0],
        dateTo: params.dateRange?.[1],
      };
    };

    switch (params.reportType) {
      case 0: {
        setLoading(true);
        dispatch(
          getReportsByApplication(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "applicationIds",
              "dateFrom",
              "dateTo",
            ]),
            (success) => {
              setLoading(false);
            }
          )
        );
        break;
      }
      case 1: {
        if (!params.skuGroupId || !params.dateRange[0] || !params.dateRange[1])
          break;
        setLoading(true);
        dispatch(
          getReportsBySector(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "skuId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => {
              setLoading(false);
            }
          )
        );
        break;
      }
      case 2: {
        if (!params.dateRange[0] || !params.dateRange[1]) break;
        setLoading(true);
        dispatch(
          getReportByGroupSku(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "skuId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => {
              setLoading(false);
            }
          )
        );
        break;
      }
      case 3: {
        if (
          (isAdmin && !params.partnerId) ||
          !params.dateRange[0] ||
          !params.dateRange[1]
        )
          break;
        setLoading(true);
        dispatch(
          getReportByShelfShares(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 4: {
        if (
          (isAdmin && !params.partnerId) ||
          !params.dateRange[0] ||
          !params.dateRange[1]
        )
          break;
        setLoading(true);
        dispatch(
          getReportByShelfSharesSalePoint(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 5: {
        if (!params.dateRange[0] || !params.dateRange[1]) break;
        setLoading(true);
        dispatch(
          getReportByShelfSharesPartners(
            getDataFilter([
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 6: {
        if (!params.dateRange[0] || !params.dateRange[1]) break;
        setLoading(true);
        dispatch(
          getReportBySegmentBrands(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "brandId",
              "typeOfPackagingId",
              "packagingFormatId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 7: {
        if (!params.dateRange[0] || !params.dateRange[1]) break;
        setLoading(true);
        dispatch(
          getReportBySegmentTypeOfPackaging(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "brandId",
              "typeOfPackagingId",
              "packagingFormatId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 8: {
        if (!params.dateRange[0] || !params.dateRange[1]) break;
        setLoading(true);
        dispatch(
          getReportBySegmentPackagingFormat(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "skuGroupId",
              "salePointCategoryId",
              "brandId",
              "typeOfPackagingId",
              "packagingFormatId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      case 9: {
        if (
          (isAdmin && !params.partnerId) ||
          !params.salePointCategoryId ||
          !params.dateRange[0] ||
          !params.dateRange[1]
        )
          break;
        setLoading(true);
        dispatch(
          getReportByMSL(
            getDataFilter([
              "partnerId",
              "countryId",
              "cityId",
              "districtId",
              "salePointCategoryId",
              "dateFrom",
              "dateTo",
            ]),
            (success) => setLoading(false)
          )
        );
        break;
      }
      default: {
      }
    }
  };

  function getReportFiltered(reportType) {
    switch (reportType) {
      case 0: {
        setLoading(true);
        dispatch(
          getReportsByApplication(reportParams, (success) => {
            setLoading(false);
          })
        );
        break;
      }
      case 1: {
        setLoading(true);
        dispatch(
          getReportsBySector(reportParams, (success) => {
            setLoading(false);
          })
        );
        break;
      }
      case 2: {
        setLoading(true);
        dispatch(
          getReportByGroupSku(reportParams, (success) => {
            setLoading(false);
          })
        );
        break;
      }
      case 3: {
        setLoading(true);
        dispatch(
          getReportByShelfShares(reportParams, (success) => setLoading(false))
        );
        break;
      }
      case 4: {
        setLoading(true);
        dispatch(
          getReportByShelfSharesSalePoint(reportParams, (success) =>
            setLoading(false)
          )
        );
        break;
      }
      case 5: {
        setLoading(true);
        dispatch(
          getReportByShelfSharesPartners(reportParams, (success) =>
            setLoading(false)
          )
        );
        break;
      }
      case 6: {
        setLoading(true);
        dispatch(
          getReportBySegmentBrands(reportParams, (success) => setLoading(false))
        );
        break;
      }
      case 7: {
        setLoading(true);
        dispatch(
          getReportBySegmentTypeOfPackaging(reportParams, (success) =>
            setLoading(false)
          )
        );
        break;
      }
      case 8: {
        setLoading(true);
        dispatch(
          getReportBySegmentPackagingFormat(reportParams, (success) =>
            setLoading(false)
          )
        );
        break;
      }
      case 9: {
        setLoading(true);
        dispatch(getReportByMSL(reportParams, (success) => setLoading(false)));
        break;
      }
      default: {
      }
    }
  }

  function onChangeReportType(event) {
    let { value } = event.target;
    setReportTypeIndex(value);
    dispatch(
      createStoreData({
        reportsByApplication: [],
        reportsBySector: [],
        reportsByGroupSku: [],
        reportsByShelfShares: [],
        reportsByShelfSharesSalePoint: [],
        reportsByShelfSharesPartners: [],
        reportsBySegmentBrands: [],
        reportsBySegmentTypeOfPackaging: [],
        reportsBySegmentPackagingFormat: [],
        reportsByMSL: [],
      })
    );
    updateStorePersisting({
      reportType: value,
    });
  }
  function updateStorePersisting(data) {
    dispatch(
      updateStoreData({
        key: "reportsPersisting",
        data,
      })
    );
  }
  return (
    <div>
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Typography variant="h3">Отчеты</Typography>
      </header>
      <div
        className="flex_box"
        style={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 12,
          flexWrap: "wrap",
        }}
      >
        {reportTypes.length && (
          <FormInput
            value={reportType}
            onChange={onChangeReportType}
            style={{ width: "auto" }}
            select
          >
            {reportTypes.map((item) => (
              <MenuItem key={item.value} value={item.value} autoFocus={false}>
                {item.title}
              </MenuItem>
            ))}
          </FormInput>
        )}
        {/* <ThemeDateRangePicker
          value={dateRange}
          onChange={(range) => {
            updateStorePersisting({
              dateRange: range,
            });
            if (
              range[0] !== null &&
              range[1] !== null
            ) {
              getFiltered({ dateRange: range });
            }
          }}
          clearable={reportType === 0}
          textFieldProps={{
            error:
              dateRange[0] === null ||
              dateRange[1] === null,
          }}
        /> */}
        <DatePickerReport
          label="Дата от"
          value={dateRange[0]}
          onChange={(newDate) => {
            setReportParams((prevState) => ({
              ...prevState,
              dateFrom: newDate,
            }));
            updateStorePersisting({
              dateRange: [newDate, dateRange[1]],
            });
          }}
        />
        <DatePickerReport
          label="Дата до"
          value={dateRange[1]}
          onChange={(newDate) => {
            setReportParams((prevState) => ({
              ...prevState,
              dateTo: newDate,
            }));
            updateStorePersisting({
              dateRange: [dateRange[0], newDate],
            });
          }}
        />
        {/* <CountryAutocomplete
          value={country}
          updateValue={(country) => {
            updateStorePersisting({
              country,
              // city: initialState.citiesSelect[0],
              // district:
              //   initialState.districtsSelect[0],
            });
            getFiltered({
              countryId: getId(country),
              city: null,
              district: null,
            });
          }}
        /> */}
        <FilterAutocomplete
          label="Страна"
          name="countryName"
          options={countries}
          onChange={(country) => {
            setReportParams((prevState) => ({
              ...prevState,
              countryId: country?.id || null,
              cityId: null,
              districtId: null,
            }));
          }}
        />
        {/* <CityAutocomplete
          value={city}
          updateValue={(city) => {
            updateStorePersisting({
              city,
              // district:
              //   initialState.districtsSelect[0],
            });
            getFiltered({
              cityId: getId(city),
              districtId: null,
            });
          }}
          countryId={getId(country)}
        /> */}
        <FilterAutocomplete
          label="Город"
          name="cityName"
          options={citiesSelect}
          reportParams={reportParams}
          onChange={(city) => {
            setReportParams((prevState) => ({
              ...prevState,
              cityId: city?.id || null,
              districtId: null,
            }));
          }}
        />
        {/* <DistrictAutocomplete
          value={district}
          updateValue={(district) => {
            updateStorePersisting({
              district,
            });
            getFiltered({
              districtId: getId(district),
            });
          }}
          cityId={getId(city)}
        /> */}
        <FilterAutocomplete
          label="Район"
          name="districtName"
          options={districtsSelect}
          onChange={(district) => {
            setReportParams((prevState) => ({
              ...prevState,
              districtId: district?.id || null,
            }));
          }}
          width="300px"
        />

        {isAdmin && reportType !== 5 && (
          // <PartnerAutocomplete
          //   value={partner}
          //   updateValue={(partner) => {
          //     updateStorePersisting({
          //       partner,
          //       sku: initialState.reportsPersisting.sku,
          //     });
          //     getFiltered({
          //       partnerId: getId(partner),
          //       skuId: null,
          //     });
          //   }}
          // />
          <FilterPartner
            label="Партнер"
            onChange={(partner) => {
              setReportParams((prevState) => ({
                ...prevState,
                partnerId: partner?.id || null,
              }));
            }}
            width="300px"
          />
        )}
        {reportType !== 0 && reportType !== 9 && (
          <GroupSkuAutocomplete
            value={skuGroup}
            updateValue={(skuGroup) => {
              // updateStorePersisting({
              //   skuGroup,
              //   sku: initialState.reportsPersisting.sku,
              // });
              // getFiltered({
              //   skuGroupId: getId(skuGroup),
              //   skuId: null,
              // });
              setReportParams((prevState) => ({
                ...prevState,
                skuGroupId:
                  skuGroup?.skuGroupId === "all"
                    ? null
                    : skuGroup?.skuGroupId || null,
              }));
            }}
            partnerId={getId(partner)}
          />
        )}
        {(reportType === 1 || reportType === 2) && (
          <SkuAutocomplete
            value={sku}
            updateValue={(sku) => {
              // updateStorePersisting({
              //   sku,
              // });
              // getFiltered({
              //   skuId: getId(sku),
              // });
              setReportParams((prevState) => ({
                ...prevState,
                skuId: sku?.skuId === "all" ? null : sku?.skuId || null,
              }));
            }}
            partnerId={getId(partner)}
          />
        )}
        {reportType === 0 && (
          // <ApplicationAutocomplete
          //   value={applicationIds}
          //   updateValue={(applicationIds) => {
          //     updateStorePersisting({
          //       applicationIds,
          //     });
          //     getFiltered({
          //       applicationIds: applicationIds.map((e) => e.id),
          //     });
          //   }}
          //   multiple
          // />
          <ApplicationsReports
            label="Заявки"
            options={citiesSelect}
            width="300px"
            onChange={(applications) => {
              setReportParams((prevState) => ({
                ...prevState,
                applicationIds: applications.map((item) => item.id),
              }));
            }}
          />
        )}
        {reportType > 2 && (
          <SalePointCategoryAutocomplete
            value={salePointCategory}
            updateValue={(salePointCategory) => {
              // updateStorePersisting({
              //   salePointCategory,
              // });
              // getFiltered({
              //   salePointCategoryId: getId(salePointCategory),
              // });
              setReportParams((prevState) => ({
                ...prevState,
                salePointCategoryId:
                  salePointCategory?.referenceId === "all"
                    ? null
                    : salePointCategory?.referenceId || null,
              }));
            }}
          />
        )}
        {reportType > 5 && reportType !== 9 && (
          <>
            <BrandAutocomplete
              value={brand}
              updateValue={(brand) => {
                // updateStorePersisting({
                //   brand,
                // });
                // getFiltered({
                //   brandId: getId(brand),
                // });
                setReportParams((prevState) => ({
                  ...prevState,
                  brandId: brand?.referenceId || null,
                }));
              }}
            />
            <TypeOfPackagingAutocomplete
              value={typeOfPackaging}
              updateValue={(typeOfPackaging) => {
                // updateStorePersisting({
                //   typeOfPackaging,
                // });
                // getFiltered({
                //   typeOfPackagingId: getId(typeOfPackaging),
                // });
                setReportParams((prevState) => ({
                  ...prevState,
                  typeOfPackagingId:
                    typeOfPackaging?.referenceId === "all"
                      ? null
                      : typeOfPackaging?.referenceId || null,
                }));
              }}
            />
            <PackagingFormatAutocomplete
              value={packagingFormat}
              updateValue={(packagingFormat) => {
                // updateStorePersisting({
                //   packagingFormat,
                // });
                // getFiltered({
                //   packagingFormatId: getId(packagingFormat),
                // });
                setReportParams((prevState) => ({
                  ...prevState,
                  packagingFormatId:
                    packagingFormat?.referenceId === "all"
                      ? null
                      : packagingFormat?.referenceId || null,
                }));
              }}
            />
          </>
        )}
      </div>
      <Typography
        variant="body1"
        style={{
          marginTop: 40,
          marginBottom: 30,
        }}
      >
        {reportTypes.find((e) => e.value === reportType)?.title ?? ""}
      </Typography>
      {reportType === 0 && (
        <ByApplication
          reportList={reportsByApplication}
          isLoading={isLoading}
        />
      )}
      {reportType === 1 && (
        <BySector reportList={reportsBySector} isLoading={isLoading} />
      )}
      {reportType === 2 && (
        <ByGroupSku reportList={reportsByGroupSku} isLoading={isLoading} />
      )}
      {reportType === 3 && (
        <ByShelfShares
          reportList={reportsByShelfShares}
          isLoading={isLoading}
        />
      )}
      {reportType === 4 && (
        <ByShelfSharesSalePoint
          reportList={reportsByShelfSharesSalePoint}
          isLoading={isLoading}
        />
      )}
      {reportType === 5 && (
        <ByShelfSharesPartners
          reportList={reportsByShelfSharesPartners}
          isLoading={isLoading}
        />
      )}
      {reportType === 6 && (
        <BySegmentBrands
          reportList={reportsBySegmentBrands}
          isLoading={isLoading}
        />
      )}
      {reportType === 7 && (
        <BySegmentTypeOfPackaging
          reportList={reportsBySegmentTypeOfPackaging}
          isLoading={isLoading}
        />
      )}
      {reportType === 8 && (
        <BySegmentPackagingFormat
          reportList={reportsBySegmentPackagingFormat}
          isLoading={isLoading}
        />
      )}
      {reportType === 9 && (
        <ByMSL reportList={reportsByMSL} isLoading={isLoading} />
      )}
    </div>
  );
}
