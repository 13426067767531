import { makeRequest } from "api";

export const deleteAgent = (id, callback) => (dispatch) => {
  makeRequest("/agent", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deletePartner = (id, callback) => (dispatch) => {
  makeRequest("/partner", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteSku = (id, callback) => (dispatch) => {
  makeRequest("/Sku", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteOutlet = (id, callback) => (dispatch) => {
  makeRequest("/salePoint", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteCountry = (id, callback) => (dispatch) => {
  makeRequest("/country", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteCity = (id, callback) => (dispatch) => {
  makeRequest("/city", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteDistrict = (id, callback) => (dispatch) => {
  makeRequest("/district", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteSkuGroup = (id, callback) => (dispatch) => {
  makeRequest("/Sku/SkuGroup", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteApplication = (id, callback) => (dispatch) => {
  makeRequest("/application", dispatch, "delete", { id }).then((response) => {
    callback(response.status === 204);
  });
};

export const deleteReferenceValue = (id, callback) => (dispatch) => {
  makeRequest(`/References/${id}`, dispatch, "delete").then((response) => {
    callback(response.status === 204);
  });
};

export const deleteSkuMsl = (id, callback) => (dispatch) => {
  makeRequest("/Sku/DeleteSkuMsl", dispatch, "delete", { id }).then(
    (response) => {
      callback(response.status === 204);
    }
  );
};
