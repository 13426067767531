import React from "react";
import { CircularProgress } from "@mui/material";

import { NoResult } from "./NoResult";

export const HocMini = ({ haveData, isLoading, children }) =>
  isLoading ? (
    <div className="flex_box">
      <CircularProgress />
    </div>
  ) : haveData ? (
    children
  ) : (
    <NoResult title="Данные не найдены" />
  );
