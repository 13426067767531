import { makeRequest } from "api";
import { createStoreData } from "store";
import { updateStoreData } from "store";
import {
  getUrlString,
  parseApplicationList,
  parseFuckingData,
} from "../utils";

let autocompletesPageSize = 10000;

export const getUserInfo = () => (dispatch) => {
  makeRequest(
    "/auth/getUserInfo",
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({ key: "user", data: json })
    );
  });
};

export const getAgentsList =
  ({ pageIndex, pageSize = 10 }, query) =>
  (dispatch) => {
    makeRequest(
      `/agent/list/${pageIndex}/${pageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "agentsList",
            data: { [pageIndex]: json.items },
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: {
              agents: {
                totalCount: json.totalCount,
                totalPages: json.totalPages,
              },
            },
          })
        );
      }
    });
  };

export const getPartnersList =
  (
    { pageIndex, pageSize = 10 },
    query,
    callback
  ) =>
  (dispatch) => {
    makeRequest(
      `/partner/list/${pageIndex}/${pageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "partnersList",
            data: { [pageIndex]: json.items },
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: {
              partners: {
                totalCount: json.totalCount,
                totalPages: json.totalPages,
              },
            },
          })
        );
      }
      if (callback) {
        callback();
      }
    });
  };

export const getPartnerListSelect =
  (query = {}, callback) =>
  (dispatch) => {
    makeRequest(
      `/partner/list/0/${autocompletesPageSize}?${getUrlString(
        query
      )}`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (json) {
          dispatch(
            createStoreData({
              partnersListSelect:
                json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getSkuList = (
  path = { pageIndex: 0, pageSize: 10 },
  query
) => {
  let { pageIndex, pageSize } = path;
  return (dispatch) => {
    makeRequest(
      `/sku/list/${pageIndex}/${pageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      let isPartnersSku =
        query &&
        typeof query.partnerId !== "undefined";
      dispatch(
        updateStoreData({
          key: isPartnersSku
            ? `partnersSku.${query.partnerId}`
            : "skuList",
          data: {
            [pageIndex]: json ? json.items : [],
          },
        })
      );
      dispatch(
        updateStoreData({
          key: isPartnersSku
            ? `partnerTotals.${query.partnerId}`
            : "totals",
          data: {
            sku: {
              totalCount: json
                ? json.totalCount
                : 0,
              totalPages: json
                ? json.totalPages
                : 0,
            },
          },
        })
      );
    });
  };
};

export const getSkuListSelect =
  (query = {}, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/sku/list/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        dispatch(
          createStoreData({
            skuListSelect: json.items
              ? json.items.map((sku) => ({
                  skuId: sku.skuId,
                  skuName: sku.skuName,
                }))
              : [],
          })
        );
      })
      .finally(callback);
  };

export const getOutletsList =
  (data) => (dispatch) => {
    makeRequest(
      "/salePoint/list",
      dispatch,
      "post",
      data
    ).then((json) => {
      if (json) {
        let outletSelectModal =
          typeof data.skuIds !== "undefined";
        dispatch(
          updateStoreData({
            key: outletSelectModal
              ? "outletsListSelect"
              : "outletsList",
            data: {
              [data.pageIndex]: json.items,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: outletSelectModal
              ? {
                  outletsSelect: {
                    totalCount: json.totalCount,
                    totalPages: json.totalPages,
                  },
                }
              : {
                  outlets: {
                    totalCount: json.totalCount,
                    totalPages: json.totalPages,
                  },
                },
          })
        );
      }
    });
  };

export const getSkuOutlets =
  (data, skuId) => (dispatch) => {
    data.skuIds = [skuId];
    makeRequest(
      "/salePoint/list",
      dispatch,
      "post",
      data
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "skuOutlets",
            data: { [skuId]: json.items },
          })
        );
      }
    });
  };

export const getApplicationsList =
  ({ pageIndex, pageSize = 10 }, query) =>
  (dispatch) => {
    makeRequest(
      `/application/list/${pageIndex}/${pageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "applicationsList",
            data: {
              [pageIndex]: parseApplicationList(
                json.items
              ),
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: {
              applications: {
                totalCount: json.totalCount,
                totalPages: json.totalPages,
              },
            },
          })
        );
      }
    });
  };

export const getTasksList =
  ({ pageIndex, pageSize = 10 }, query) =>
  (dispatch) => {
    makeRequest(
      `/application/list/${pageIndex}/${pageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "tasksList",
            data: { [pageIndex]: json.items },
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: {
              tasks: {
                totalCount: json.totalCount,
                totalPages: json.totalPages,
              },
            },
          })
        );
      }
    });
  };

export const getApplicationListSelect =
  (query, callback) => (dispatch) => {
    makeRequest(
      `/application/list/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (Array.isArray(json?.items)) {
          json.items = json.items.map((a) => ({
            id: a.id,
            applicationName: a.applicationName,
          }));
          dispatch(
            createStoreData({
              applicationsListSelect:
                json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getAgentFromID =
  (agentID) => (dispatch) => {
    makeRequest(
      `/agent/${agentID}`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "agents",
            data: { [agentID]: json },
          })
        );
      }
    });
  };

export const getPartnerFromID =
  (partnerID) => (dispatch) => {
    makeRequest(
      `/partner/${partnerID}`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "partners",
            data: { [partnerID]: json },
          })
        );
      }
    });
  };

export const getOutletFromID =
  (outletID) => (dispatch) => {
    makeRequest(
      `/salePoint/${outletID}`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "outlets",
            data: { [outletID]: json },
          })
        );
      }
    });
  };

export const getCountries =
  (query) => (dispatch) => {
    makeRequest(
      `/country/list/0/20000?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          createStoreData({
            countries: json.items,
          })
        );
      }
    });
  };

export const getCities =
  (query, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/city/list/0/20000?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        if (query?.countryId) {
          dispatch(
            updateStoreData({
              key: "countryCities",
              data: {
                [query.countryId]: json.items,
              },
            })
          );
        } else {
          dispatch(
            createStoreData({
              cities: json.items,
            })
          );
        }
      }
      callback();
    });
  };

export const getDistricts =
  (query, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/district/list/0/20000?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        if (query?.cityId) {
          dispatch(
            updateStoreData({
              key: "cityDistricts",
              data: {
                [query.cityId]: json.items,
              },
            })
          );
        } else {
          dispatch(
            createStoreData({
              districts: json.items,
            })
          );
        }
      }
      callback(Boolean(json));
    });
  };

export const getCountriesSelect =
  (query, callback) => (dispatch) => {
    makeRequest(
      `/country/list/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (json) {
          dispatch(
            createStoreData({
              countriesSelect: json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getCitiesSelect =
  (query, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/city/list/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (json) {
          dispatch(
            createStoreData({
              citiesSelect: json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getDistrictsSelect =
  (query, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/district/list/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (json) {
          dispatch(
            createStoreData({
              districtsSelect: json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getSkuGroupes =
  (query) => (dispatch) => {
    makeRequest(
      `/sku/SkuGroupList/0/20000?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          createStoreData({
            skuGroupes: json.items,
          })
        );
      }
    });
  };

export const getSkuGroupeListSelect =
  (query = {}, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/sku/SkuGroupList/0/${autocompletesPageSize}?${
        query ? getUrlString(query) : ""
      }`,
      dispatch,
      "get"
    )
      .then((json) => {
        if (json) {
          dispatch(
            createStoreData({
              skuGroupListSelect:
                json?.items ?? [],
            })
          );
        }
      })
      .finally(callback);
  };

export const getCountryById =
  (id) => (dispatch) => {
    makeRequest(
      `/country/${id}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "countriesStore",
          data: { [id]: json || {} },
        })
      );
    });
  };

export const getCityById = (id) => (dispatch) => {
  makeRequest(
    `/city/${id}`,
    dispatch,
    "get"
  ).then((json) => {
    dispatch(
      updateStoreData({
        key: "citiesStore",
        data: { [id]: json || {} },
      })
    );
  });
};

export const getDistrictById =
  (id) => (dispatch) => {
    makeRequest(
      `/district/${id}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "districtsStore",
          data: { [id]: json || {} },
        })
      );
    });
  };

export const getSkuGroupById =
  (id) => (dispatch) => {
    makeRequest(
      `/sku/SkuGroup/${id}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "districtsStore",
          data: { [id]: json || {} },
        })
      );
    });
  };

export const getRegistrationReuqests =
  ({ pageIndex, pageSize = 10 }) =>
  (dispatch) => {
    makeRequest(
      `/agent/RegistrationRequests/${pageIndex}/${pageSize}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "registrationRequests",
          data: {
            [pageIndex]: json ? json.items : [],
          },
        })
      );
      dispatch(
        updateStoreData({
          key: "totals",
          data: {
            registrationRequests: {
              totalCount: json
                ? json.totalCount
                : 0,
              totalPages: json
                ? json.totalPages
                : 0,
            },
          },
        })
      );
    });
  };

export const getMlTaskResults =
  (
    { pageIndex = 0, pageSize = 10 },
    query,
    callback
  ) =>
  (dispatch) => {
    makeRequest(
      `/Ml/GetMlTaskResult/${pageIndex}/${pageSize}?${getUrlString(
        query
      )}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "taskResults",
          data: {
            [query.applicationId]:
              json && json.items.length
                ? parseFuckingData(json.items)
                : [],
          },
        })
      );
      callback();
    });
  };

export const getReferences =
  (
    referenceType = "SalePointType",
    query = {},
    callback = () => {}
  ) =>
  (dispatch) => {
    const typesDictionary = {
      SalePointType: "salePointTypes",
      Category: "categories",
      Brand: "brands",
      TypeOfPackaging: "typesOfPackaging",
      PackagingFormat: "packagingFormats",
    };
    makeRequest(
      `/References/${referenceType}/0/${autocompletesPageSize}?${getUrlString(
        query
      )}`,
      dispatch,
      "get"
    ).then((json) => {
      if (
        referenceType === "Category" &&
        !query.parentReferenceId
      ) {
        json.items.forEach((item) => {
          dispatch(
            getReferences(referenceType, {
              parentReferenceId: item.referenceId,
            })
          );
        });
      }
      if (query.parentReferenceId) {
        dispatch(
          updateStoreData({
            key: "category",
            data: {
              [query.parentReferenceId]:
                json?.items ?? [],
            },
          })
        );
      } else {
        dispatch(
          createStoreData({
            [typesDictionary[referenceType]]:
              json?.items ?? [],
          })
        );
        dispatch(
          updateStoreData({
            key: "totals",
            data: {
              [typesDictionary[referenceType]]: {
                totalCount: json
                  ? json.totalCount
                  : 0,
                totalPages: json
                  ? json.totalPages
                  : 0,
              },
            },
          })
        );
      }
      callback();
    });
  };

export const getWithdrawalList =
  ({ pageIndex, pageSize = 10 }) =>
  (dispatch) => {
    makeRequest(
      `/agent/GetWithdrawalRequests/${pageIndex}/${pageSize}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "withdrawalRequests",
          data: {
            [pageIndex]: json ? json.items : [],
          },
        })
      );
      dispatch(
        updateStoreData({
          key: "totals",
          data: {
            withdrawalRequests: {
              totalCount: json
                ? json.totalCount
                : 0,
              totalPages: json
                ? json.totalPages
                : 0,
            },
          },
        })
      );
    });
  };

export const getTransactionsHistory =
  (
    { pageIndex = 0, pageSize = 10 },
    query = {}
  ) =>
  (dispatch) => {
    makeRequest(
      `/Agent/GetCompletedWithdrawalRequests/${pageIndex}/${pageSize}?${getUrlString(
        query
      )}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "transactionsHistory",
          data: {
            [pageIndex]: json ? json.items : [],
          },
        })
      );
      dispatch(
        updateStoreData({
          key: "totals",
          data: {
            transactionsHistory: {
              totalCount: json
                ? json.totalCount
                : 0,
              totalPages: json
                ? json.totalPages
                : 0,
            },
          },
        })
      );
    });
  };

export const getSkuMslList =
  (partnerId) => (dispatch) => {
    makeRequest(
      `/Sku/GetSkuMskList/${partnerId}`,
      dispatch,
      "get"
    ).then((json) => {
      dispatch(
        updateStoreData({
          key: "msl",
          data: { [partnerId]: json ?? [] },
        })
      );
    });
  };
