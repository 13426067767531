import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Map, Placemark } from "react-yandex-maps";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { createStoreData } from "store";
import { FormInput, ThemeButton, ValidatedFormInput } from "shared";
import pinIcon from "assets/icons/MapPin.svg";
import crossIcon from "assets/icons/Cross.svg";
import {
  createOutlet,
  deleteOutlet,
  getCities,
  getDistricts,
  updateOutlet,
} from "api";
import {
  CountryAutocomplete,
  CityAutocomplete,
  DistrictAutocomplete,
  SalePointTypeAutocomplete,
  SalePointCategoryAutocomplete,
} from "../Autocompletes";

const MapComponent = React.memo(({ mapPinCoords, setMapPinCoords }) => {
  const { user } = useSelector((store) => store);
  let isPartner = user?.role === process.env.REACT_APP_PARTNER,
    isDefaultCoords =
      mapPinCoords[0] === 43.237163 && mapPinCoords[1] === 76.945627;
  return (
    <Map
      modules={["geocode"]}
      defaultState={{
        center: mapPinCoords,
        zoom: isDefaultCoords ? 13 : 16,
      }}
      style={{
        width: "50%",
        height: isPartner ? 534 : 720,
        marginTop: 32,
      }}
      onClick={(yEvent) => {
        if (!isPartner) {
          let coordinates = yEvent.get("coords");
          setMapPinCoords(coordinates);
        }
      }}
    >
      {!isDefaultCoords && (
        <Placemark
          geometry={mapPinCoords}
          options={{
            iconLayout: "default#image",
            iconImageHref: pinIcon,
            iconImageSize: [40, 55],
            iconOffset: [-10, -20],
          }}
        />
      )}
    </Map>
  );
});
const initialValuesConstant = {
  name: "",
  countryId: null,
  cityId: null,
  districtId: null,
  contactPerson: "",
  phone: "",
  note: "",
  isHalal: true,
  categoryId: null,
  salePointTypeId: null,
  guiD_FOR_ERP: "",
};
const validationConstant = {
  name: Yup.string().required("Поле обязательно к заполнению"),
  countryId: Yup.object()
    .shape({
      id: Yup.string(),
      countryName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  cityId: Yup.object()
    .shape({
      id: Yup.string(),
      cityName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  districtId: Yup.object()
    .shape({
      id: Yup.string(),
      districtName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  address: Yup.string().required("Поле обязательно к заполнению"),
  salePointTypeId: Yup.object()
    .shape({
      referenceId: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  categoryId: Yup.object()
    .shape({
      referenceId: Yup.string(),
      value: Yup.string(),
      groupName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  contactPerson: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  note: Yup.string().nullable(),
  guiD_FOR_ERP: Yup.string().nullable(),
};

export default function OutletModal({ open, onCloseModal, currentOutlet }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, outlets, countries, countryCities, cityDistricts } =
    useSelector((store) => store);
  const [initialValues, setInitialValues] = useState(initialValuesConstant);
  const [locationFieldValue, setLocationFieldValue] = useState("");
  const isItUpdateMode = typeof currentOutlet !== "undefined";
  const [mapPinCoords, setMapPinCoords] = useState([43.237163, 76.945627]);
  const [isLoading, setIsLoading] = useState(false);
  let isPartner = user?.role === process.env.REACT_APP_PARTNER,
    isDefaultCoords =
      mapPinCoords[0] === 43.237163 && mapPinCoords[1] === 76.945627;

  React.useEffect(() => {
    if (isItUpdateMode) {
      let isCityExist =
          Boolean(currentOutlet.countryId) &&
          typeof countryCities[currentOutlet.countryId] !== "undefined",
        isDistrictExist =
          Boolean(currentOutlet.cityId) &&
          typeof cityDistricts[currentOutlet.cityId] !== "undefined",
        getLocationText = () => {
          if (isItUpdateMode) {
            let country = countries.filter(
                (country) => country.id === currentOutlet.countryId
              )[0],
              city = countryCities[currentOutlet.countryId].filter(
                (city) => city.id === currentOutlet.cityId
              )[0],
              district = cityDistricts[currentOutlet.cityId].filter(
                (district) => district.id === currentOutlet.districtId
              )[0];

            return `${country ? country.countryName : "Не найдено"}, ${
              city ? city.cityName : "Не найдено"
            }, ${district ? district.districtName : "Не найдено"}`;
          }
        };
      if (!isCityExist) {
        dispatch(
          getCities({
            countryId: currentOutlet.countryId,
          })
        );
      }
      if (!isDistrictExist) {
        dispatch(
          getDistricts({
            cityId: currentOutlet.cityId,
          })
        );
      }
      if (isCityExist && isDistrictExist) {
        let newValues = {
          ...currentOutlet,
          countryId: {
            id: currentOutlet.countryId,
            countryName: currentOutlet.country,
          },
          cityId: {
            id: currentOutlet.cityId,
            cityName: currentOutlet.city,
          },
          districtId: {
            id: currentOutlet.districtId,
            districtName: currentOutlet.district,
          },
          categoryId: {
            referenceId: currentOutlet.categoryId,
            value: currentOutlet.category,
          },
          salePointTypeId: {
            referenceId: currentOutlet.salePointTypeId,
            value: currentOutlet.salePointType,
          },
        };
        setInitialValues(newValues);
        setLocationFieldValue(getLocationText());
        setMapPinCoords([currentOutlet.latitude, currentOutlet.longitude]);
      }
    }
  }, [
    dispatch,
    currentOutlet,
    outlets,
    countries,
    isItUpdateMode,
    countryCities,
    cityDistricts,
  ]);

  const handleSubmit = (values) => {
    const callback = (success) => {
      if (success) {
        dispatch(
          createStoreData({
            outletsList: {},
            alert: {
              severity: "success",
              message: "Успешно",
            },
          })
        );
        handleClose(true);
      }
      setIsLoading(false);
    };

    if (isDefaultCoords) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Выберите точку на карте!",
          },
        })
      );
      return;
    }

    const { salePointType, ...newValues } = values;

    const formData = {
      ...newValues,
      isHalal: String(values.isHalal) === "true",
      countryId: values.countryId.id,
      cityId: values.cityId.id,
      districtId: values.districtId.id,
      salePointTypeId: values.salePointTypeId.referenceId,
      categoryId: values.categoryId.referenceId,
      latitude: mapPinCoords[0],
      longitude: mapPinCoords[1],
    };

    setIsLoading(true);
    if (isItUpdateMode) {
      formData.id = currentOutlet.id;
      dispatch(updateOutlet(formData, callback));
    } else {
      dispatch(createOutlet(formData, callback));
    }
  };
  const deleteOutletHandler = () => {
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Удаление торговой точки",
          question: "Вы действительно хотите удалить торговую точку ?",
          btnText: "Да, удалить",
          confirm: () =>
            dispatch(
              deleteOutlet(currentOutlet.id, (success) => {
                if (success) {
                  dispatch(
                    createStoreData({
                      outletsList: {},
                    })
                  );
                  handleClose();
                }
              })
            ),
        },
      })
    );
  };
  const handleClose = () => {
    setInitialValues(initialValuesConstant);
    setLocationFieldValue("");
    setMapPinCoords([43.237163, 76.945627]);
    onCloseModal();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <div
          className="flex_box sticky_header"
          style={{
            height: 24,
            justifyContent: "space-between",
            paddingBottom: 32,
          }}
        >
          <Typography variant="h6">
            {isItUpdateMode ? currentOutlet.name : "Новая торговая точка"}
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={crossIcon} alt="cross" />
          </IconButton>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationConstant)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form
              className="flex_box"
              onSubmit={formik.handleSubmit}
              style={{
                flexWrap: "wrap",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <MapComponent
                mapPinCoords={mapPinCoords}
                setMapPinCoords={setMapPinCoords}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "calc(50% - 50px)",
                  marginLeft: 50,
                }}
              >
                <div style={{ width: "100%" }}>
                  <ValidatedFormInput
                    disabled={isPartner}
                    name="name"
                    label="Название"
                  />
                </div>
                {isPartner ? (
                  <div style={{ width: "100%" }}>
                    <Typography variant="body2" className={classes.label}>
                      Локация
                    </Typography>
                    <FormInput
                      disabled
                      fullWidth
                      name="location"
                      value={locationFieldValue}
                    />
                  </div>
                ) : (
                  <>
                    <div style={{ width: "100%" }}>
                      <CountryAutocomplete
                        value={formik.values.countryId}
                        updateValue={(country) => {
                          formik.handleChange({
                            target: {
                              name: "countryId",
                              value: country,
                            },
                          });
                        }}
                        validated
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CityAutocomplete
                        value={formik.values.cityId}
                        updateValue={(city) => {
                          formik.handleChange({
                            target: {
                              name: "cityId",
                              value: city,
                            },
                          });
                        }}
                        validated
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <DistrictAutocomplete
                        value={formik.values.districtId}
                        updateValue={(district) => {
                          formik.handleChange({
                            target: {
                              name: "districtId",
                              value: district,
                            },
                          });
                        }}
                        validated
                      />
                    </div>
                  </>
                )}
                <div style={{ width: "100%" }}>
                  <ValidatedFormInput
                    disabled={isPartner}
                    name="address"
                    label="Адрес"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <SalePointTypeAutocomplete
                    value={formik.values.salePointTypeId}
                    updateValue={(salePointType) => {
                      formik.handleChange({
                        target: {
                          name: "salePointTypeId",
                          value: salePointType,
                        },
                      });
                    }}
                    validated
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <SalePointCategoryAutocomplete
                    value={formik.values.categoryId}
                    updateValue={(category) => {
                      formik.handleChange({
                        target: {
                          name: "categoryId",
                          value: category,
                        },
                      });
                    }}
                    validated
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="body2"
                    className={classes.label}
                    style={{ marginTop: 24 }}
                  >
                    Халал
                  </Typography>
                  <RadioGroup
                    row
                    name="isHalal"
                    value={formik.values["isHalal"]}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Да"
                      disabled={isPartner}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Нет"
                      disabled={isPartner}
                    />
                  </RadioGroup>
                </div>
                <div style={{ width: "100%" }}>
                  <ValidatedFormInput
                    disabled={isPartner}
                    name="note"
                    label="Примечание"
                  />
                </div>
                <div
                  style={{
                    width: "calc(50% - 16px)",
                  }}
                >
                  <ValidatedFormInput
                    disabled={isPartner}
                    name="contactPerson"
                    label="Представитель (Должность)"
                  />
                </div>
                <div
                  style={{
                    width: "calc(50% - 16px)",
                  }}
                >
                  <ValidatedFormInput
                    disabled={isPartner}
                    name="phone"
                    label="Контактный номер телефона"
                  />
                </div>
                {user?.role === "Admin" && (
                  <div style={{ width: "100%" }}>
                    <ValidatedFormInput
                      disabled={isPartner}
                      name="guiD_FOR_ERP"
                      label="GUID_FOR_ERP"
                    />
                  </div>
                )}
              </div>

              {!isPartner ? (
                currentOutlet ? (
                  <div
                    className="flex_box"
                    style={{
                      gap: 16,
                      marginTop: 30,
                    }}
                  >
                    <ThemeButton
                      style={{
                        width: 150,
                      }}
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: "#FFFFFF",
                          }}
                        />
                      ) : (
                        "Сохранить"
                      )}
                    </ThemeButton>
                    <Button variant="delete" onClick={deleteOutletHandler}>
                      Удалить
                    </Button>
                  </div>
                ) : (
                  <div
                    className="flex_box"
                    style={{
                      gap: 16,
                      marginTop: 30,
                    }}
                  >
                    <ThemeButton
                      style={{
                        width: 150,
                      }}
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: "#FFFFFF",
                          }}
                        />
                      ) : (
                        "Создать"
                      )}
                    </ThemeButton>
                    <ThemeButton
                      variant="outlined"
                      style={{ width: 150 }}
                      onClick={handleClose}
                    >
                      Отмена
                    </ThemeButton>
                  </div>
                )
              ) : null}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
    },
  },
  paper: {
    "&.MuiPaper-root": {
      width: "77%",
      maxWidth: "100%",
      maxHeight: "100%",
      margin: 0,
    },
  },
  label: {
    marginTop: "24px !important",
    marginBottom: "10px !important",
    fontWeight: "600 !important",
  },
}));
