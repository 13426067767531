import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerListSelect } from "api";

export function FilterPartner(props) {
  const { onChange, label, width } = props;
  const dispatch = useDispatch();
  const { partnersListSelect } = useSelector((store) => store);

  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    dispatch(
      getPartnerListSelect({
        searchText: "",
      })
    );
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.partnerName}
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      options={partnersListSelect || []}
      sx={{ width: width || "100%" }}
      fetchOptions={(searchText, callback) =>
        dispatch(
          getPartnerListSelect(
            {
              searchText,
            },
            callback
          )
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{
            bgcolor: "#ffffff",
            "& .MuiOutlinedInput-input": {
              paddingTop: "9.5px",
              paddingBottom: "9.5px",
              fontWeight: 400,
              height: "26px",
            },
          }}
        />
      )}
    />
  );
}
