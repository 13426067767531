import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";

import arrowRight from "assets/icons/ArrowRight.svg";
import { NoResult } from "./NoResult";

export const PhotoCardItem = ({ mlItem, resultItem, setCurrentTask }) => {
  let maxLength = 2,
    isSkuListMoreThanMaxLength = mlItem.foundSkuList.length > maxLength;
  const [spliced, setSpliced] = useState(
    isSkuListMoreThanMaxLength
      ? mlItem.foundSkuList.slice(0, maxLength)
      : mlItem.foundSkuList
  );
  return (
    <Paper
      style={{
        minHeight: 204,
        borderRadius: 4,
        border: "1px solid #DADADA",
        width: "calc(50% - 10px)",
        overflow: "hidden",
      }}>
      <Typography
        variant="subtitle1"
        style={{
          padding: "14px 20px 0px",
        }}>
        {mlItem.skuGroupName}
      </Typography>
      <div
        className="flex_box_vertical"
        style={{
          minHeight: 151,
          justifyContent: "space-between",
          backgroundColor: "#FFF",
          padding: "10px 20px",
        }}>
        <div style={{ width: "100%", height: "fit-content" }}>
          {spliced.length ? (
            spliced.map((sku) => (
              <div
                key={sku.color}
                className="flex_box_between"
                style={{
                  height: "fit-content",
                  borderBottom: "1px solid #DBDBDB",
                  padding: "10px 0px",
                }}>
                <div
                  className="flex_box"
                  style={{
                    width: "auto",
                    justifyContent: "flex-start",
                  }}>
                  {/* <div
                    className="circle"
                    style={{
                      backgroundColor: sku.color,
                      marginRight: 8,
                    }}
                  /> */}
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 400,
                      color: "#000",
                      marginRight: 20,
                    }}>
                    {sku.skuName}
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  style={{
                    minWidth: 30,
                    fontSize: 10,
                    fontWeight: 700,
                    padding: 4,
                    borderRadius: 4,
                    color: "#2F77FB",
                    textAlign: "center",
                    border: "1px solid #2F77FB",
                    backgroundColor: "transparent",
                  }}>
                  {`${sku.count} шт`}
                </Typography>
              </div>
            ))
          ) : (
            <NoResult title="Результаты не найдены" style={{ height: 100 }} />
          )}
        </div>
        <div
          className="flex_box"
          style={{
            height: "fit-content",
            justifyContent: isSkuListMoreThanMaxLength
              ? "space-between"
              : "flex-end",
            marginBottom: 7,
            marginTop: 15,
          }}>
          {Boolean(
            isSkuListMoreThanMaxLength &&
              spliced.length !== mlItem.foundSkuList.length
          ) && (
            <Typography
              onClick={() => {
                setSpliced(mlItem.foundSkuList);
              }}
              variant="subtitle1"
              style={{
                color: "#2F77FB",
                cursor: "pointer",
                fontStyle: "intalic",
                fontWeight: 400,
              }}>{`Еще товаров: +${
              mlItem.foundSkuList.length - maxLength
            } шт`}</Typography>
          )}
          {Boolean(
            isSkuListMoreThanMaxLength &&
              spliced.length === mlItem.foundSkuList.length
          ) && (
            <Typography
              onClick={() => {
                setSpliced([...mlItem.foundSkuList].splice(0, maxLength));
              }}
              variant="subtitle1"
              style={{
                color: "#2F77FB",
                cursor: "pointer",
                fontStyle: "intalic",
                fontWeight: 400,
              }}>{`Скрыть: ${
              mlItem.foundSkuList.length - maxLength
            } шт`}</Typography>
          )}
          <div
            className="flex_box"
            onClick={() => {
              setCurrentTask(mlItem);
            }}
            style={{ width: 167, cursor: "pointer" }}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
                color: "#2F77FB",
                marginRight: 6,
              }}>
              Посмотреть на фото
            </Typography>
            <img src={arrowRight} alt="" />
          </div>
        </div>
      </div>
    </Paper>
  );
};
