import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export function FilterAutocomplete(props) {
  const { onChange, options, label, name, width } = props;
  const [value, setValue] = React.useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option[name]}
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      options={options || []}
      sx={{ width: width || "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{
            bgcolor: "#ffffff",
            "& .MuiOutlinedInput-input": {
              paddingTop: "9.5px",
              paddingBottom: "9.5px",
              fontWeight: 400,
              height: "26px",
            },
          }}
        />
      )}
    />
  );
}
