import randomColor from "randomcolor";

export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    let fileNameArr = file.name.split(".");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        `data:application/${
          fileNameArr[fileNameArr.length - 1]
        };base64,${reader.result.split(",")[1]}`
      );
    reader.onerror = (error) => reject(error);
  });
}
export function getArrFromObj(obj, withKey) {
  let arr = [];
  for (let key in obj) {
    if (withKey) {
      arr.push({ key, value: obj[key] });
    } else {
      arr.push(obj[key]);
    }
  }
  return arr;
}
export const getUrlString = (obj) => {
  let url = "";
  let keys = Object.keys(obj).filter((key) =>
    Boolean(obj[key])
  );

  keys.forEach(
    (key, index) =>
      (url += `${key}=${obj[key]}${
        keys.length === index + 1 ? "" : "&"
      }`)
  );

  return url;
};
export const deepMerge = (
  store,
  pathArray,
  data,
  index = 0
) => {
  return index === pathArray.length - 1
    ? {
        ...store,
        [pathArray[index]]: Array.isArray(data)
          ? [...store[pathArray[index]], ...data]
          : {
              ...store[pathArray[index]],
              ...data,
            },
      }
    : {
        ...store,
        [pathArray[index]]: deepMerge(
          typeof store[pathArray[index]] ===
            "undefined"
            ? Array.isArray(data)
              ? []
              : {}
            : store[pathArray[index]],
          pathArray,
          data,
          index + 1
        ),
      };
};
export const parseFuckingData = (tasks) => {
  let result = [];
  tasks.forEach((task) => {
    if (task.mlTaskResultItems.length) {
      let resultItems = [];
      task.mlTaskResultItems.forEach((object) => {
        if (object.mlFoundObjects?.length) {
          let mlTagsObject =
            object.mlFoundObjects.reduce(
              (calculator, rectangle) => {
                if (
                  typeof calculator[
                    rectangle.mlTag
                  ] === "undefined"
                ) {
                  calculator[rectangle.mlTag] = {
                    skuName: rectangle.skuName,
                    color: randomColor(),
                    count: 1,
                  };
                } else {
                  calculator[
                    rectangle.mlTag
                  ].count =
                    calculator[rectangle.mlTag]
                      .count + 1;
                }
                return calculator;
              },
              {}
            );
          object.foundSkuList =
            getArrFromObj(mlTagsObject);
          object.mlFoundObjects =
            object.mlFoundObjects.map(
              (rectangle) => {
                rectangle.color =
                  mlTagsObject[
                    rectangle.mlTag
                  ].color;
                return rectangle;
              }
            );
          resultItems.push(object);
        }
      });
      result.push({ ...task, resultItems });
    }
  });
  return result;
};

export const parseApplicationList = (list) => {
  list.map((application) => {
    let districts = [];
    if (application.salePoints.length) {
      application.salePoints.forEach((e) => {
        let districtIndex = districts.findIndex(
          (d) => d.districtName === e.district
        );
        if (districtIndex === -1) {
          districts.push({
            districtName: e.district,
            id: e.districtId,
            cityName: e.city,
            cityId: e.cityId,
            salePoints: [
              {
                id: e.id,
                name: e.name,
                address: e.address,
              },
            ],
          });
        } else {
          districts[
            districtIndex
          ].salePoints.push({
            id: e.id,
            name: e.name,
            address: e.address,
          });
        }
      });
    }
    application.districts = districts;
    return application;
  });
  return list;
};

export const idNotAll = (v) => {
  if ("id" in v && v.id !== "all") return true;
  else if (
    "skuGroupId" in v &&
    v.skuGroupId !== "all"
  )
    return true;
  else if ("skuId" in v && v.skuId !== "all")
    return true;
  else if (
    "referenceId" in v &&
    v.referenceId !== "all"
  )
    return true;
  else return false;
};

export const getId = (v) =>
  idNotAll(v)
    ? v.id ||
      v.skuGroupId ||
      v.skuId ||
      v.referenceId
    : null;

export const getOptionLabel = (
  option,
  label = "value"
) => {
  return typeof option === "string"
    ? option
    : option[label];
};

export const isOptionEqualToValue = (
  option,
  value,
  label = "value",
  id = "referenceId"
) => {
  return typeof value === "object"
    ? option[id] === value[id]
    : Boolean(value) &&
        new RegExp(`^${value}`, "gi").test(
          option[label]
        );
};

export const getNormalizedDateString = (date) => {
  let dateValue = new Date(date),
    normalizedDate = new Date(
      dateValue.getTime() +
        Math.abs(
          dateValue.getTimezoneOffset() * 60000
        )
    ).toISOString();
  return normalizedDate;
};
