import React, { useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";

import crossIcon from "assets/icons/Cross.svg";
import { NoResult, ThemeButton } from "shared";
import { getDistricts } from "api";
import InfiniteScroll from "../InfiniteScroll";
import {
  CityAutocomplete,
  CountryAutocomplete,
} from "../Autocompletes";

export default function DistrictSelectModal({
  open,
  onClose,
  arrayId,
  setArrayId,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cityDistricts } = useSelector(
    (store) => store
  );
  const [districts, setDistricts] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [selectedCity, setSelectedCity] =
    useState("");

  React.useEffect(() => {
    if (
      selectedCity &&
      cityDistricts[selectedCity.id]
    ) {
      let formattedArray = [];
      cityDistricts[selectedCity.id].forEach(
        (district) => {
          let lastRaw =
            formattedArray[
              formattedArray.length - 1
            ];
          if (
            typeof lastRaw === "undefined" ||
            lastRaw.length === 3
          ) {
            formattedArray.push([district]);
          } else {
            lastRaw.push(district);
          }
        }
      );
      setDistricts(formattedArray);
      setMaxLength(
        cityDistricts[selectedCity.id].length
      );
    }
  }, [selectedCity, cityDistricts, setDistricts]);

  const searchDistricts = (event, page = 0) => {
    const {
      target: { value },
    } = event;
    setLoading(true);
    setPageIndex(page);
    setSearchText(value);
  };
  const add = () => {
    props.setDistricts(districts);
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogContent
        style={{ paddingBottom: 130 }}
      >
        <div
          className="flex_box sticky_header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Районы
          </Typography>
          <IconButton onClick={handleClose}>
            <img
              src={crossIcon}
              alt="cross"
            />
          </IconButton>
        </div>
        <div
          className="flex_box"
          style={{ gap: 20, marginTop: 30 }}
        >
          <CountryAutocomplete />
          <CityAutocomplete
            updateValue={(value) => {
              setSelectedCity(value);
              dispatch(
                getDistricts({ cityId: value.id })
              );
            }}
          />
        </div>
        <div
          className="flex_box"
          style={{
            justifyContent: "flex-start",
            flexWrap: "wrap",
            marginBottom: 110,
          }}
        >
          {Boolean(selectedCity.id) ? (
            <InfiniteScroll
              width={window.innerWidth * 0.8 - 80}
              maxLength={districts.length}
              searchText={searchText}
              onChangeSearchText={setSearchText}
              onSearch={searchDistricts}
              RenderItem={({
                registerChild,
                index,
                style,
              }) => {
                let row = districts[index];
                return (
                  <div
                    className="flex_box"
                    ref={registerChild}
                    style={{
                      ...style,
                      justifyContent:
                        "flex-start",
                      minHeight: 50,
                      gap: 20,
                    }}
                  >
                    {Boolean(row.length) &&
                      row.map(
                        (district, itemIndex) => {
                          return (
                            <div
                              key={itemIndex}
                              className="flex_box"
                              style={{
                                width: "30%",
                                justifyContent:
                                  "flex-start",
                              }}
                            >
                              <Checkbox
                                checked={
                                  arrayId.indexOf(
                                    district.id
                                  ) !== -1
                                }
                                onChange={(
                                  event
                                ) => {
                                  setArrayId(
                                    (state) =>
                                      event.target
                                        .checked
                                        ? [
                                            ...state,
                                            district.id,
                                          ]
                                        : state.filter(
                                            (
                                              id
                                            ) =>
                                              id !==
                                              district.id
                                          )
                                  );
                                }}
                              />
                              <span>
                                <Typography
                                  variant="body1"
                                  style={{
                                    lineHeight:
                                      "19px",
                                  }}
                                >
                                  {
                                    district.districtName
                                  }
                                </Typography>
                              </span>
                            </div>
                          );
                        }
                      )}
                  </div>
                );
              }}
              RenderHeader={() => (
                <div style={{ width: "100%" }}>
                  <div
                    className="flex_box"
                    style={{
                      justifyContent:
                        "flex-start",
                      marginLeft: 12,
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#757575",
                      }}
                    >{`Найдено: ${maxLength}`}</Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#757575",
                        marginLeft: 32,
                      }}
                    >{`Выбрано: ${arrayId.length}`}</Typography>
                  </div>
                  <div
                    className="flex_box"
                    style={{
                      width:
                        "calc(33.33% - 20px)",
                      marginRight: 20,
                      marginBottom: 16,
                      justifyContent:
                        "flex-start",
                    }}
                  >
                    <Checkbox
                      checked={
                        maxLength ===
                        arrayId.length
                      }
                      onChange={(event) => {
                        if (
                          event.target.checked &&
                          maxLength
                        ) {
                          setArrayId(
                            cityDistricts[
                              selectedCity.id
                            ].map(
                              (district) =>
                                district.id
                            )
                          );
                        } else {
                          setArrayId([]);
                        }
                      }}
                    />
                    <span>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#757575",
                          lineHeight: "19px",
                        }}
                      >
                        Выбрать все
                      </Typography>
                    </span>
                  </div>
                </div>
              )}
              onEndReachedThreshold={() => {
                searchDistricts(
                  {
                    target: { value: searchText },
                  },
                  pageIndex + 1
                );
              }}
              searchPlaceholder="Поиск"
            />
          ) : (
            <NoResult
              title="Выберите город"
              style={{ marginTop: 50 }}
            />
          )}
        </div>
        {isLoading && (
          <div className="flex_box">
            <CircularProgress size={30} />
          </div>
        )}
        <div className="flex_box fixed_buttons">
          <ThemeButton
            type="submit"
            style={{ width: 150 }}
            onClick={add}
          >
            Добавить
          </ThemeButton>
          <ThemeButton
            type="button"
            variant="outlined"
            style={{ width: 150 }}
            onClick={handleClose}
          >
            Отмена
          </ThemeButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      width: "50%",
      maxWidth: "100%",
      position: "relative",
    },
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 64px)",
    },
  },
  label: {
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
}));
