import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";

import CardSKU from "../CardSKU";
import { getSkuList } from "api";
import { HocMini } from "shared";
import SearchComponent from "../SearchComponent";
import crossBlack from "assets/icons/CrossBlack.svg";

export default function SkuSelectModal({
  open,
  onClose,
  onSelectSku,
  currentPartner,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { partnerTotals, partnersSku } =
    useSelector((store) => store);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [skuListState, setSkuListState] =
    useState([]);

  React.useEffect(() => {
    if (
      typeof partnersSku[currentPartner.id][
        pageIndex
      ] === "undefined" &&
      !isLoading
    ) {
      setLoading(true);
      dispatch(
        getSkuList(
          { pageIndex, pageSize: 20 },
          { partnerId: currentPartner.id }
        )
      );
    }
    if (
      typeof partnersSku[currentPartner.id][
        pageIndex
      ] !== "undefined"
    ) {
      setLoading(false);
      setSkuListState(
        partnersSku[currentPartner.id][pageIndex]
      );
    }
  }, [
    dispatch,
    pageIndex,
    partnersSku,
    isLoading,
    currentPartner,
  ]);

  const onSearch = (value) => {
    setLoading(true);
    dispatch(
      getSkuList(
        { pageIndex, pageSize: 20 },
        {
          searchText: value,
          partnerId: currentPartner.id,
        }
      )
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <div
          className="flex_box_between sticky_header"
          style={{ marginBottom: 40 }}
        >
          <Typography variant="h6">
            Выбор товара
          </Typography>
          <IconButton onClick={onClose}>
            <img
              src={crossBlack}
              alt=""
            />
          </IconButton>
        </div>
        <SearchComponent
          searchText={searchText}
          onChangeText={setSearchText}
          onSearch={onSearch}
          placeholder={
            "Введите наименование товара или партнера"
          }
        />
        <HocMini
          haveData={Boolean(skuListState.length)}
          isLoading={isLoading}
        >
          <Grid
            container
            spacing={3}
          >
            {skuListState.map((product, id) => (
              <Grid
                item
                xs={2}
                key={id}
              >
                <CardSKU
                  product={product}
                  onClick={() => {
                    onSelectSku(product);
                    onClose();
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </HocMini>
        <div
          className="flex_box"
          style={{
            marginTop: 33,
            marginBottom: 27,
          }}
        >
          <div className="flex_box">
            {Boolean(
              partnerTotals[currentPartner.id]
            ) &&
              partnerTotals[currentPartner.id].sku
                .totalPages > 1 && (
                <Pagination
                  page={pageIndex + 1}
                  onChange={(_, page) =>
                    setPageIndex(page - 1)
                  }
                  classes={{
                    root: classes.pagination,
                  }}
                  count={
                    partnerTotals[
                      currentPartner.id
                    ].sku.totalPages
                  }
                  siblingCount={2}
                  boundaryCount={1}
                  hideNextButton={true}
                  hidePrevButton={true}
                />
              )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "90vw",
    minWidth: "90vw",
    minHeight: "90vh",
  },
}));
