import { makeRequest } from "api";
import { getAgentFromID } from "./GET_requests";

export const updateAgent = (data, callback) => (dispatch) => {
  makeRequest("/agent", dispatch, "put", data).then((response) => {
    if (response.status === 204) {
      dispatch(getAgentFromID(data.id));
      callback(true);
    } else {
      callback(false);
    }
  });
};

export const updatePartner = (data, callback) => (dispatch) => {
  makeRequest("/partner", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateOutlet = (data, callback) => (dispatch) => {
  makeRequest("/salePoint", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateSku = (data, callback) => (dispatch) => {
  makeRequest("/sku", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updatePassword = (data, callback) => (dispatch) => {
  makeRequest("/partner/changePassword", dispatch, "put", data).then(
    (response) => {
      callback(response.status === 204);
    }
  );
};

export const updateCountry = (data, callback) => (dispatch) => {
  makeRequest("/country", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateCity = (data, callback) => (dispatch) => {
  makeRequest("/city", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateDistrict = (data, callback) => (dispatch) => {
  makeRequest("/district", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateSkuGroup = (data, callback) => (dispatch) => {
  makeRequest("/sku/SkuGroup", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const updateReferenceValue = (data, callback) => (dispatch) => {
  makeRequest("/References", dispatch, "put", data).then((response) => {
    callback(response.status === 204);
  });
};

export const approveRegistration = (data, callback) => (dispatch) => {
  makeRequest("/agent/ApproveRegistration", dispatch, "put", data).then(
    (response) => {
      callback(response.status === 204);
    }
  );
};

export const refuseRegistration = (data, callback) => (dispatch) => {
  makeRequest("/agent/RefuseRegistration", dispatch, "put", data).then(
    (response) => {
      callback(response.status === 204);
    }
  );
};
export const withdrawalRequestProcessed =
  (withdrawalRequestId, callback) => (dispatch) => {
    makeRequest(
      `/agent/WithdrawalRequestProcessed?withdrawalRequestId=${withdrawalRequestId}`,
      dispatch,
      "put"
    ).then((response) => {
      callback(response.status === 204);
    });
  };
