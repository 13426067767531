import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getArrFromObj, getCitiesSelect, getCountriesSelect } from "api";
import trashIcon from "assets/icons/trash_icon.svg";

export default function FlexibleList({
  columns = 5,
  paramKey,
  editHandler,
  deleteHandler,
}) {
  const {
    skuGroupes,
    countries,
    cities,
    districts,
    brands,
    packagingFormats,
    typesOfPackaging,
  } = useSelector((store) => store);
  const [sortedList, setSortedList] = useState([]);

  const setSortedReferences = React.useCallback(
    (list) => {
      let sorted = getArrFromObj(
        list.reduce((calculator, item) => {
          let listTitle = item.value.replace(/^ +/gm, "")[0].toUpperCase(),
            isThereLetterGroupe = Boolean(calculator[listTitle]);

          if (isThereLetterGroupe) {
            calculator[listTitle].push({
              id: item.referenceId,
              [paramKey]: item.value,
            });
          } else {
            calculator[listTitle] = [
              {
                id: item.referenceId,
                [paramKey]: item.value,
              },
            ];
          }
          return calculator;
        }, {}),
        true
      ).sort((a, b) => {
        return a.key.charCodeAt(0) - b.key.charCodeAt(0);
      });
      setSortedList(sorted);
    },
    [paramKey]
  );

  React.useEffect(() => {
    if (paramKey === "groupName" && skuGroupes?.length) {
      let sorted = getArrFromObj(
        skuGroupes.reduce((calculator, skuGroup) => {
          let listTitle = skuGroup.groupName
              .replace(/^ +/gm, "")[0]
              .toUpperCase(),
            isThereLetterGroupe = Boolean(calculator[listTitle]);

          if (isThereLetterGroupe) {
            calculator[listTitle].push(skuGroup);
          } else {
            calculator[listTitle] = [skuGroup];
          }
          return calculator;
        }, {}),
        true
      ).sort((a, b) => {
        return a.key.charCodeAt(0) - b.key.charCodeAt(0);
      });
      setSortedList(sorted);
    }
    if (paramKey === "brandName" && brands?.length) {
      setSortedReferences(brands);
    }
    if (paramKey === "typeOfPackaging" && typesOfPackaging?.length) {
      setSortedReferences(typesOfPackaging);
    }
    if (paramKey === "packagingFormat" && packagingFormats?.length) {
      setSortedReferences(packagingFormats);
    }
    if (paramKey === "countryName" && countries?.length) {
      let sorted = getArrFromObj(
        countries.reduce((calculator, country) => {
          let listTitle = country.countryName
              .replace(/^ +/gm, "")[0]
              .toUpperCase(),
            isThereLetterGroupe = Boolean(calculator[listTitle]);

          if (isThereLetterGroupe) {
            calculator[listTitle].push(country);
          } else {
            calculator[listTitle] = [country];
          }
          return calculator;
        }, {}),
        true
      ).sort((a, b) => {
        return a.key.charCodeAt(0) - b.key.charCodeAt(0);
      });
      setSortedList(sorted);
    }
    if (
      Boolean(paramKey === "cityName" && countries?.length && cities?.length)
    ) {
      let sorted = getArrFromObj(
        countries.reduce((calculator, country) => {
          calculator[country.countryName] = cities.filter(
            (city) => city.countryId === country.id
          );
          return calculator;
        }, {}),
        true
      )
        .sort((a, b) => {
          return a.key.charCodeAt(0) - b.key.charCodeAt(0);
        })
        .filter((groupe) => Boolean(groupe.value.length));
      setSortedList(sorted);
    }
    if (
      Boolean(
        paramKey === "districtName" &&
          countries?.length &&
          cities?.length &&
          districts?.length
      )
    ) {
      let sorted = countries
        .map((country) => {
          let citiesInCountry = cities.filter(
              (city) => city.countryId === country.id
            ),
            districtsInCity = Boolean(citiesInCountry.length)
              ? getArrFromObj(
                  citiesInCountry.reduce((calculator, city) => {
                    calculator[city.cityName] = districts.filter(
                      (district) => district.cityId === city.id
                    );
                    return calculator;
                  }, {}),
                  true
                )
                  .sort((a, b) => {
                    return a.key.charCodeAt(0) - b.key.charCodeAt(0);
                  })
                  .filter((groupe) => Boolean(groupe.value.length))
              : [];
          return {
            countryName: country.countryName,
            list: districtsInCity,
          };
        })
        .filter((countrySection) => Boolean(countrySection.list.length));
      setSortedList(sorted);
    }
  }, [
    paramKey,
    countries,
    cities,
    districts,
    skuGroupes,
    brands,
    typesOfPackaging,
    packagingFormats,
    setSortedReferences,
  ]);

  return (
    <div
      className="flex_box"
      style={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: 20,
      }}
    >
      {Boolean(sortedList.length)
        ? paramKey === "districtName"
          ? sortedList.map((countrySection, index) => (
              <div
                style={{
                  width: "100%",
                  marginTop: 24,
                }}
                key={index}
              >
                <Typography variant="h5" style={{ marginLeft: 10 }}>
                  {countrySection.countryName}
                </Typography>
                {countrySection.list.map((groupe, index) => (
                  <ListItem
                    key={index}
                    groupe={groupe}
                    index={index}
                    options={{
                      paramKey,
                      columns,
                    }}
                    eventHandlers={{
                      editHandler,
                      deleteHandler,
                    }}
                  />
                ))}
              </div>
            ))
          : sortedList.map((groupe, index) => (
              <ListItem
                key={index}
                groupe={groupe}
                index={index}
                isSkuGroup={paramKey === "groupName"}
                options={{ paramKey, columns }}
                eventHandlers={{
                  editHandler,
                  deleteHandler,
                }}
              />
            ))
        : false}
    </div>
  );
}

const ListItem = ({ groupe, index, options, eventHandlers, isSkuGroup }) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        width: `calc(100% / ${options.columns} - 20px)`,
        marginTop: options.columns < index + 1 ? 30 : 0,
      }}
      key={groupe.key}
    >
      <Typography
        variant="body1"
        style={{
          fontWeight: 700,
          marginLeft: 10,
          marginTop: 16,
        }}
      >
        {groupe.key}
      </Typography>
      {Boolean(groupe.value?.length) &&
        groupe.value.map((item) => (
          <div
            className="letter_groupe_item"
            key={isSkuGroup ? item.skuGroupId : item.id}
            onClick={() => {
              dispatch(getCountriesSelect({ countryId: item.countryId }));
              dispatch(
                getCitiesSelect({
                  cityId: item.cityId,
                })
              );
              eventHandlers.editHandler(isSkuGroup ? item.skuGroupId : item.id);
            }}
          >
            <div className="flex_box">
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 400,
                  marginRight: 20,
                }}
              >
                {item[options.paramKey]}
              </Typography>
              <div className="controlPannel">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    eventHandlers.deleteHandler(item);
                  }}
                >
                  <img src={trashIcon} alt="" />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
