import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Typography,
} from "@mui/material";

import {
  deleteApplication,
  getApplicationsList,
  getId,
  getNormalizedDateString,
  getOutletsList,
  getSkuList,
} from "api";
import plusIcon from "assets/icons/Plus.svg";
import { ThemeButton } from "shared/Buttons";
import {
  updateStoreData,
  createStoreData,
} from "store";
import SearchComponent from "components/SearchComponent";
import chevronDown from "assets/icons/ChevronDown.svg";
import arrowRight from "assets/icons/ArrowRight.svg";
import deleteIcon from "assets/icons/CrossOctagon.svg";
import {
  FormInput,
  getStatusChip,
  NoResult,
  ThemeDatePicker,
} from "shared";
import SalepointSelectModal from "components/Dialogs/SalepointSelectModal";
import { PartnerAutocomplete } from "components/Autocompletes/PartnerAutocomplete";

export function Applications() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    totals,
    skuList,
    outletsList,
    applicationsList,
    applicationsPersisting,
  } = useSelector((store) => store);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [expanded, setExpanded] = useState("");
  const [, setApplicationDistricts] = useState(
    []
  );
  const [
    applicationDistrict,
    setApplicationDistrict,
  ] = useState({
    state: {
      country: {},
      city: {},
      district: {},
    },
    selectedOutletsList: null,
  });
  const [
    openDistrictSelect,
    setOpenDistrictSelect,
  ] = useState(false);
  let isPartner =
      user?.role ===
      process.env.REACT_APP_PARTNER,
    isAdmin =
      user?.role === process.env.REACT_APP_ADMIN,
    { pageIndex, status, date, partner } =
      applicationsPersisting;

  React.useEffect(() => {
    if (
      typeof applicationsList[pageIndex] !==
        "undefined" &&
      isLoading
    ) {
      setLoading(false);
    }
  }, [applicationsList, isLoading, pageIndex]);
  React.useEffect(() => {
    if (
      typeof skuList[pageIndex] === "undefined" &&
      !isLoading
    ) {
      dispatch(
        getSkuList({ pageIndex, pageSize: 20 })
      );
    }
    if (
      typeof outletsList[pageIndex] ===
      "undefined"
    ) {
      dispatch(
        getOutletsList({
          pageIndex,
          pageSize: 10,
        })
      );
    }
    if (
      typeof applicationsList[pageIndex] ===
        "undefined" &&
      !isLoading
    ) {
      setLoading(true);
      dispatch(
        getApplicationsList(
          { pageIndex },
          { dateOfExecution: date }
        )
      );
    }
  }, [
    date,
    skuList,
    dispatch,
    pageIndex,
    isLoading,
    isPartner,
    outletsList,
    applicationsList,
  ]);

  const onSearch = (value) => {
    getFiltered({ searchText: value });
  };
  const getFiltered = (params, pageIndex = 0) => {
    let queryParams = {
      searchText,
      partnerId: getId(partner),
      applicationStatus: status,
      dateOfExecution: date
        ? new Date(date).toISOString()
        : "all",
      ...params,
    };
    if (
      status !== "all" &&
      !queryParams.applicationStatus
    ) {
      queryParams.applicationStatus = status;
    }
    if (queryParams.applicationStatus === "all") {
      delete queryParams.applicationStatus;
    }
    if (queryParams.dateOfExecution === "all") {
      delete queryParams.dateOfExecution;
    }

    setLoading(true);
    dispatch(
      updateStoreData({
        key: "applicationsPersisting",
        data: { pageIndex },
      })
    );
    dispatch(
      getApplicationsList(
        { pageIndex },
        queryParams
      )
    );
  };

  function deleteApplicationHandler(application) {
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Удаление заявки",
          question:
            "Вы действительно хотите удалить эту заявку ?",
          confirm: () =>
            dispatch(
              deleteApplication(
                application.id,
                (success) => {
                  if (success) {
                    getFiltered({});
                    dispatch(
                      createStoreData({
                        alert: {
                          severity: "success",
                          message: "Успешно",
                        },
                      })
                    );
                  }
                }
              )
            ),
        },
      })
    );
  }

  const DetailItem = ({ district }) => {
    let isSalePointsMoreThanFore =
        district.salePoints.length > 2,
      spliced = isSalePointsMoreThanFore
        ? [...district.salePoints].splice(0, 2)
        : district.salePoints;
    return (
      <Paper
        style={{
          minHeight: 151,
          borderRadius: 4,
          border: "1px solid #DADADA",
          width: "calc(50% - 10px)",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            padding: "14px 20px",
            backgroundColor: "#F9FBFC",
          }}
        >
          {`${district.districtName} (Торговых точек: ${district.salePoints.length})`}
        </Typography>
        <div
          className="flex_box_vertical"
          style={{
            minHeight: "calc(151px - 52.5px)",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
            padding: "10px 20px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "fit-content",
            }}
          >
            {spliced.map(
              (salePoint, salePointIndex) => (
                <div
                  key={salePoint.id}
                  className="flex_box_between"
                  style={{
                    height: "fit-content",
                    borderBottom:
                      spliced.length !==
                      salePointIndex + 1
                        ? "1px solid #DBDBDB"
                        : "none",
                    padding: "10px 0px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 400,
                      color: "#000",
                    }}
                  >
                    {`${salePoint.name}, ${salePoint.address}`}
                  </Typography>
                </div>
              )
            )}
          </div>
          <div
            className="flex_box"
            style={{
              height: "fit-content",
              justifyContent: "flex-end",
              marginBottom: 7,
              marginTop: 15,
            }}
          >
            {isSalePointsMoreThanFore && (
              <div
                className="flex_box"
                onClick={() => {
                  setApplicationDistrict(
                    (state) => ({
                      state: {
                        ...state.state,
                        district: district.id
                          ? { id: district.id }
                          : {},
                      },
                      selectedOutletsList:
                        district.salePoints,
                    })
                  );
                  setOpenDistrictSelect(true);
                }}
                style={{
                  width: 167,
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 400,
                    color: "#2F77FB",
                    marginRight: 6,
                  }}
                >
                  Подробнее
                </Typography>
                <img
                  src={arrowRight}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </Paper>
    );
  };
  function updateStorePersisting(data) {
    dispatch(
      updateStoreData({
        key: "applicationsPersisting",
        data,
      })
    );
  }
  return (
    <div className="applications_component">
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">
          Заявки
        </Typography>
        {isPartner && (
          <ThemeButton
            onClick={() => {
              dispatch(
                createStoreData({
                  currentApplication: {},
                })
              );
              navigate("/application");
            }}
            startIcon={
              <img
                src={plusIcon}
                alt="btn plus icon"
              />
            }
          >
            Новая заявка
          </ThemeButton>
        )}
      </header>
      <SearchComponent
        searchText={searchText}
        onChangeText={setSearchText}
        onSearch={onSearch}
        placeholder={
          "Введите наименование партнера"
        }
      />

      <div
        className="flex_box"
        style={{
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <div
          className="flex_box"
          style={{
            width: "fit-content",
            gap: 10,
          }}
        >
          {isAdmin && (
            <PartnerAutocomplete
              value={partner}
              updateValue={(partner) => {
                updateStorePersisting({
                  partner,
                });
                getFiltered({
                  partnerId: getId(partner),
                });
              }}
            />
          )}
          <div
            style={{
              width: "250px",
            }}
          >
            <ThemeDatePicker
              value={date}
              onChange={(date) => {
                getFiltered({
                  dateOfExecution: date
                    ? getNormalizedDateString(
                        date
                      )
                    : "all",
                });
                updateStorePersisting({
                  date,
                });
              }}
              placeholder="Дата выполнения"
            />
          </div>
          <div style={{ width: "200px" }}>
            <FormInput
              value={status}
              onChange={(event) => {
                let { value } = event.target;
                getFiltered({
                  applicationStatus: value,
                });
                updateStorePersisting({
                  status: value,
                });
              }}
              fullWidth
              select
            >
              <MenuItem value="all">
                Все заявки
              </MenuItem>
              {isAdmin && [
                <MenuItem
                  value="InWork"
                  key="InWork"
                >
                  В работе
                </MenuItem>,
                <MenuItem
                  value="Refused"
                  key="Refused"
                >
                  Отклонено
                </MenuItem>,
                <MenuItem
                  value="New"
                  key="New"
                >
                  На рассмотрении
                </MenuItem>,
              ]}
              <MenuItem value="Done">
                Выполнено
              </MenuItem>
              <MenuItem value="PartialDone">
                Частично выполнено
              </MenuItem>
              <MenuItem value="NotCompleted">
                Не выполнено
              </MenuItem>
            </FormInput>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex_box">
          <CircularProgress />
        </div>
      ) : typeof applicationsList[pageIndex] !==
          "undefined" &&
        Boolean(
          applicationsList[pageIndex].length
        ) ? (
        <Box className={classes.tableContainer}>
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 146px 16px 22px",
              backgroundColor: "#FFFFFF",
            }}
          >
            {isAdmin && (
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={3}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Партнер
                </Typography>
              </Grid>
            )}
            <Grid
              item
              className={classes.head}
              style={{
                padding: 0,
              }}
              xs={3}
            >
              <Typography
                variant="subtitle2"
                style={{
                  color: "#7A8BA7",
                  fontWeight: "bold",
                }}
              >
                Наименование
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.head}
              xs={3}
            >
              <Typography
                variant="subtitle2"
                style={{
                  color: "#7A8BA7",
                  fontWeight: "bold",
                }}
              >
                Дата
              </Typography>
            </Grid>
            {isPartner && (
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={4}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Группа товаров
                </Typography>
              </Grid>
            )}
            <Grid
              item
              className={classes.head}
              style={{
                padding: 0,
              }}
              xs={2}
            >
              <Typography
                variant="subtitle2"
                style={{
                  color: "#7A8BA7",
                  fontWeight: "bold",
                }}
              >
                Статус
              </Typography>
            </Grid>
            {isAdmin && (
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={1}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Действия
                </Typography>
              </Grid>
            )}
          </Grid>
          {applicationsList[pageIndex].map(
            (application) => (
              <Accordion
                key={application.id}
                expanded={
                  expanded === application.id
                }
                onChange={() => {
                  setExpanded(
                    expanded === application.id
                      ? false
                      : application.id
                  );
                }}
                sx={{
                  borderTop: "1px solid #ECECEC",
                  "&:before": {
                    display: "none",
                  },
                  "&.Mui-expanded": {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      src={chevronDown}
                      alt=""
                    />
                  }
                >
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.row}
                    style={{
                      padding: "16px 22px",
                    }}
                  >
                    {isAdmin && (
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                        }}
                        xs={3}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: "#7A8BA7",
                            fontWeight: "400",
                          }}
                        >
                          {
                            application.partnerName
                          }
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      className={classes.head}
                      style={{
                        padding: 0,
                      }}
                      xs={3}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: "#7A8BA7",
                          fontWeight: "400",
                        }}
                      >
                        {application.applicationName ??
                          ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.head}
                      xs={3}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: "#7A8BA7",
                          fontWeight: "400",
                        }}
                      >
                        {moment(
                          application.dateOfExecution
                        ).format("DD MMMM yyyy")}
                      </Typography>
                    </Grid>
                    {isPartner && (
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                        }}
                        xs={4}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: "#7A8BA7",
                            fontWeight: "400",
                          }}
                        >
                          {application.skuGroups
                            .length ? (
                            <div
                              className="flex_box"
                              style={{
                                justifyContent:
                                  "flex-start",
                                gap: 6,
                                flexWrap: "wrap"
                              }}
                            >
                              {application.skuGroups.map(
                                (item) => (
                                  <div
                                    className="tag"
                                    key={item.id}
                                  >
                                    <Typography variant="caption">
                                      {item.name}
                                    </Typography>
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <div
                              className="tag"
                              style={{
                                width:
                                  "fit-content",
                              }}
                            >
                              <Typography variant="caption">
                                Нет группы товаров
                              </Typography>
                            </div>
                          )}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      className={classes.head}
                      style={{
                        padding: 0,
                      }}
                      xs={2}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: "#7A8BA7",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          getStatusChip[
                            application.status
                          ]
                        }
                      </Typography>
                    </Grid>
                    {isAdmin && (
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                          textAlign: "center",
                        }}
                        xs={1}
                      >
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deleteApplicationHandler(
                              application
                            );
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt=""
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {application.districts
                    .length ? (
                    <div
                      className="flex_box"
                      style={{
                        padding: "20px 24px",
                        gap: 20,
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent:
                          "flex-start",
                      }}
                    >
                      {application.districts.map(
                        (district) => (
                          <DetailItem
                            district={district}
                            key={
                              district.id ??
                              district.districtName
                            }
                          />
                        )
                      )}
                    </div>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Нет торговых точек
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
        </Box>
      ) : (
        <NoResult title="Данные не найдены" />
      )}
      {typeof totals.applications !==
        "undefined" &&
        totals.applications.totalPages > 1 && (
          <div
            className="flex_box"
            style={{
              marginTop: 33,
              marginBottom: 27,
            }}
          >
            <div className="flex_box">
              <Pagination
                page={pageIndex + 1}
                onChange={(_, page) => {
                  getFiltered({}, page - 1);
                }}
                count={
                  totals.applications.totalPages
                }
                siblingCount={2}
                boundaryCount={1}
                hideNextButton={true}
                hidePrevButton={true}
                classes={{
                  root: classes.pagination,
                }}
              />
            </div>
          </div>
        )}
      <SalepointSelectModal
        open={openDistrictSelect}
        applicationDistrict={applicationDistrict}
        onClose={() =>
          setOpenDistrictSelect(false)
        }
        setApplicationDistricts={
          setApplicationDistricts
        }
        selectedOutletsList={
          applicationDistrict.selectedOutletsList
        }
        disabled
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  partnerSelect: {
    "& .Mui-disabled": {
      opacity: 1,
      color: "#000",
      cursor: "pointer",
      WebkitTextFillColor: "#000",
      fontWeight: 400,
    },
  },
  menuPaper: {
    width: "50%",
    maxHeight: 400,
    overflow: "auto",
    borderRadius: 4,
    padding: "0px 32px 32px",
    boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.15)",
  },
}));
