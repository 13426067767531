import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Pagination,
} from "@mui/material";

import {
  getSkuListSelect,
  getSkuList,
  getTasksList,
  getOutletsList,
  getMlTaskResults,
  getId,
  getNormalizedDateString,
} from "api";
import {
  NoResult,
  getStatusChip,
  PhotoCardItem,
  TaskResultViewer,
  FormInput,
  ThemeDatePicker,
} from "shared";
import chevronDown from "assets/icons/ChevronDown.svg";
import noResult from "assets/icons/noResult.svg";
import { updateStoreData } from "store";
import SearchComponent from "components/SearchComponent";
import { PartnerAutocomplete } from "components/Autocompletes/PartnerAutocomplete";

export default function Analytics() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    user,
    totals,
    skuList,
    tasksList,
    allSkuList,
    outletsList,
    taskResults,
    analiticsPersisting,
  } = useSelector((store) => store);
  const [isLoading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  let isPartner =
      user?.role ===
      process.env.REACT_APP_PARTNER,
    { pageIndex, status, date, partner } =
      analiticsPersisting;
  const [viewer, setViewer] = useState(false);
  const [currentTask, setCurrentTask] = useState(
    {}
  );
  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTask] = useState(
    {}
  );

  React.useEffect(() => {
    dispatch(
      updateStoreData({
        key: "analiticsPersisting",
        data: { date: null },
      })
    );
  }, []);

  React.useEffect(() => {
    if (typeof allSkuList === "undefined") {
      dispatch(getSkuListSelect());
    }
  }, [allSkuList, dispatch]);
  React.useEffect(() => {
    if (
      typeof skuList[pageIndex] === "undefined" &&
      !isLoading
    ) {
      setLoading(true);
      dispatch(
        getSkuList({ pageIndex, pageSize: 20 })
      );
    }
    if (
      typeof skuList[pageIndex] !== "undefined"
    ) {
      setLoading(false);
    }
    if (
      typeof outletsList[pageIndex] ===
      "undefined"
    ) {
      dispatch(
        getOutletsList({
          pageIndex,
          pageSize: 10,
        })
      );
    }
  }, [
    skuList,
    dispatch,
    pageIndex,
    isLoading,
    isPartner,
    outletsList,
    status,
    tasksList,
  ]);
  React.useEffect(() => {
    setLoading(true);
    dispatch(
      getTasksList(
        { pageIndex },
        {
          applicationStatus:
            status === "all" ? null : status,
        }
      )
    );
  }, [dispatch, pageIndex, status]);
  React.useEffect(() => {
    if (
      typeof tasksList[pageIndex] !== "undefined"
    ) {
      setTasks(
        tasksList[pageIndex].map(
          (application) => ({
            id: application.id,
            summary: {
              date: moment(
                application.dateOfExecution
              ).format("DD MMM YYYY"),
              sku: application.skuGroups
                .length ? (
                application.skuGroups.map(
                  (group) => (
                    <div
                      className="tag"
                      key={group.id}
                    >
                      <Typography variant="caption">
                        {group.name}
                      </Typography>
                    </div>
                  )
                )
              ) : (
                <div className="tag">
                  <Typography variant="caption">
                    Нет группы товаров
                  </Typography>
                </div>
              ),
              applicationName:
                application.applicationName,
              status:
                getStatusChip[application.status],
            },
            details: [],
          })
        )
      );
    }
  }, [
    dispatch,
    isLoading,
    tasksList,
    pageIndex,
    status,
  ]);
  const getFiltered = (params, pageIndex = 0) => {
    let queryParams = {
      partnerId: getId(partner),
      applicationStatus: status,
      dateOfExecution: date,
      ...params,
    };

    if (
      status !== "all" &&
      !queryParams.applicationStatus
    ) {
      queryParams.applicationStatus = status;
    }
    if (queryParams.applicationStatus === "all") {
      delete queryParams.applicationStatus;
    }
    setLoading(true);
    dispatch(
      updateStoreData({
        key: "analiticsPersisting",
        data: { pageIndex },
      })
    );
    dispatch(
      getTasksList({ pageIndex }, queryParams)
    );
  };
  const closeModal = () => {
    setViewer(false);
  };

  const SalePoint = ({ task }) => {
    return (
      <div
        className="flex_box"
        style={{
          gap: 20,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="h5"
          style={{ width: "100%", marginTop: 30 }}
        >
          {task.salePointName}
        </Typography>
        {task.mlTaskResultItems.length ? (
          task.mlTaskResultItems.map(
            (mlItem, mlIndex) => (
              <PhotoCardItem
                mlItem={mlItem}
                key={mlItem.photoId}
                resultItem={
                  task.resultItems[mlIndex]
                }
                setCurrentTask={(task) => {
                  setCurrentTask(task);
                  setViewer(true);
                }}
              />
            )
          )
        ) : (
          <NoResult
            title="Результаты не найдены"
            style={{ height: 240 }}
          />
        )}
      </div>
    );
  };
  const Details = ({ id }) => {
    const [resultList, setResultList] = useState(
      []
    );
    const [searchText, setSearchText] =
      useState("");

    React.useEffect(() => {
      if (
        typeof taskResults[id] !== "undefined"
      ) {
        setResultList(taskResults[id]);
      }
    }, [id]);

    function searchSalePointByName(value) {
      console.log(value)
      if (value.length) {
        const result = taskResults[id].filter(item => item.salePointName.toLowerCase().includes(value.toLowerCase()));
        setResultList(result);
      } else {
        setResultList(taskResults[id]);
      }
    }
    return (
      <>
        <SearchComponent
          searchText={searchText}
          onChangeText={setSearchText}
          onSearch={searchSalePointByName}
          placeholder={
            "Введите наименование партнера"
          }
        />
        {resultList.length ? (
          <div
            style={{
              padding: "20px 24px",
            }}
          >
            {resultList.map((task) => (
              <SalePoint
                task={task}
                key={task.agentTaskId}
              />
            ))}
          </div>
        ) : Boolean(loadingTasks[id]) ? (
          <div
            className="flex_box"
            style={{ padding: 10 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="flex_box">
              <div
                style={{
                  textAlign: "center",
                  padding: "20px 0px",
                }}
              >
                <img
                  src={noResult}
                  width="164px"
                  alt=""
                />
                <Typography
                  variant="body1"
                  style={{
                    marginTop: 10,
                    color: "#757575",
                  }}
                >
                  Результаты не определены
                </Typography>
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  function updateStorePersisting(data) {
    dispatch(
      updateStoreData({
        key: "analiticsPersisting",
        data,
      })
    );
  }
  return (
    <div>
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">
          Фотоотчетность
        </Typography>
      </header>
      <div
        className="flex_box"
        style={{
          justifyContent: "space-between",
          marginBottom: 20,
          gap: 20,
        }}
      >
        <Typography variant="h6">
          Задания
        </Typography>
        <div
          className="flex_box"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <div
            className="flex_box"
            style={{
              width: "fit-content",
              gap: 10,
            }}
          >
            {!isPartner && (
              <PartnerAutocomplete
                value={partner}
                updateValue={(partner) => {
                  updateStorePersisting({
                    partner,
                  });
                  getFiltered({
                    partnerId: getId(partner),
                  });
                }}
              />
            )}
            <div
              style={{
                width: "250px",
              }}
            >
              <ThemeDatePicker
                value={date}
                onChange={(date) => {
                  getFiltered({
                    dateOfExecution: date
                      ? getNormalizedDateString(
                          date
                        )
                      : null,
                  });
                  updateStorePersisting({
                    date,
                  });
                }}
                placeholder="Дата выполнения"
              />
            </div>
            <div style={{ width: "190px" }}>
              <FormInput
                value={status}
                onChange={(event) => {
                  let { value } = event.target;
                  getFiltered({
                    applicationStatus: value,
                  });
                  updateStorePersisting({
                    status: value,
                  });
                }}
                fullWidth
                select
              >
                <MenuItem value="all">
                  Все
                </MenuItem>
                <MenuItem value="Done">
                  Выполнено
                </MenuItem>
                <MenuItem value="PartialDone">
                  Частично выполнено
                </MenuItem>
                <MenuItem value="NotCompleted">
                  Не выполнено
                </MenuItem>
              </FormInput>
            </div>
            <div style={{ width: "auto" }}></div>
          </div>
        </div>
      </div>
      <div>
        {tasks.length ? (
          <Box className={classes.tableContainer}>
            <Grid
              item
              xs={12}
              container
              className={classes.row}
              style={{
                padding: "16px 146px 16px 22px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Grid
                item
                className={classes.head}
                xs={2}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Дата
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={5}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Группа товаров
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={3}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Наименование заявки
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.head}
                style={{
                  padding: 0,
                }}
                xs={2}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#7A8BA7",
                    fontWeight: "bold",
                  }}
                >
                  Статус
                </Typography>
              </Grid>
            </Grid>
            {tasks.map((task) => {
              let { id, summary } = task;
              return (
                <Accordion
                  key={id}
                  expanded={expanded === id}
                  onChange={() => {
                    setExpanded(
                      expanded === id ? false : id
                    );
                    if (
                      typeof taskResults[id] ===
                      "undefined"
                    ) {
                      setLoadingTask((state) => ({
                        ...state,
                        [id]: true,
                      }));
                      dispatch(
                        getMlTaskResults(
                          {},
                          { applicationId: id },
                          () =>
                            setLoadingTask(
                              (state) => ({
                                ...state,
                                [id]: false,
                              })
                            )
                        )
                      );
                    }
                  }}
                  sx={{
                    borderTop:
                      "1px solid #ECECEC",
                    "&:before": {
                      display: "none",
                    },
                    "&.Mui-expanded": {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <img
                        src={chevronDown}
                        style={{
                          filter:
                            "invert(59%) sepia(12%) saturate(800%) hue-rotate(179deg) brightness(90%) contrast(84%)",
                        }}
                        alt=""
                      />
                    }
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.row}
                      style={{
                        padding: "16px 22px",
                      }}
                    >
                      <Grid
                        item
                        className={classes.head}
                        xs={2}
                      >
                        <Typography
                          className={
                            classes.cellText
                          }
                        >
                          {summary.date}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                        }}
                        xs={4}
                      >
                        <div
                          className="flex_box"
                          style={{
                            justifyContent:
                              "flex-start",
                            gap: 6,
                            flexWrap: "wrap"
                          }}
                        >
                          {summary.sku}
                        </div>
                      </Grid>
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                        }}
                        xs={4}
                      >
                        {summary.applicationName}
                      </Grid>
                      <Grid
                        item
                        className={classes.head}
                        style={{
                          padding: 0,
                        }}
                        xs={2}
                      >
                        {summary.status}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Details id={id} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        ) : (
          <NoResult title="Данные не найдены" />
        )}
        {typeof totals.tasks !== "undefined" &&
          totals.tasks.totalPages > 1 && (
            <div
              className="flex_box"
              style={{
                marginTop: 33,
                marginBottom: 27,
              }}
            >
              <div className="flex_box">
                <Pagination
                  page={pageIndex + 1}
                  onChange={(_, page) => {
                    getFiltered({}, page - 1);
                  }}
                  count={totals.tasks.totalPages}
                  siblingCount={2}
                  boundaryCount={1}
                  hideNextButton={true}
                  hidePrevButton={true}
                  classes={{
                    root: classes.pagination,
                  }}
                />
              </div>
            </div>
          )}
      </div>
      <TaskResultViewer
        viewer={viewer}
        closeModal={closeModal}
        currentTask={currentTask}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  partnerSelect: {
    "& .Mui-disabled": {
      opacity: 1,
      color: "#000",
      cursor: "pointer",
      WebkitTextFillColor: "#000",
      fontWeight: 400,
    },
  },
  menuPaper: {
    width: "50%",
    maxHeight: 400,
    overflow: "auto",
    borderRadius: 4,
    padding: "0px 32px 32px",
    boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.15)",
  },
  tableContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 6,
    overflow: "hidden",
  },
  row: {
    minHeight: 66,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  head: {},
  cell: {},
  cellText: {
    fontWeight: "400 !important",
  },
}));
