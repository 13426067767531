import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import AgentModal from "../Dialogs/AgentModal";
import plusIcon from "assets/icons/Plus.svg";
import SearchComponent from "../SearchComponent";
import CustomDataTable from "../CustomDataTable";
import {
  updateStoreData,
  createStoreData,
} from "store";
import {
  TabsComponent,
  ThemeButton,
  HocMini,
} from "shared";
import {
  deleteAgent,
  getAgentsList,
  getRegistrationReuqests,
} from "api";

const tabs = [
  "Список агентов",
  "Заявки на регистрацию",
];

export function Agents() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    totals,
    agentsList,
    registrationRequests,
    persisting,
  } = useSelector((store) => store);
  const [agentsTable, setAgentsTable] = useState(
    []
  );
  const [requestsTable, setRequestsTable] =
    useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [currentAgent, setCurrentAgent] =
    useState({});
  const [agentModal, setAgentModal] = useState({
    open: false,
    moderation: false,
  });
  let tab = persisting.agentsTab,
    isAgents = tabs[tab] === "Список агентов",
    isRequests =
      tabs[tab] === "Заявки на регистрацию",
    {
      agentsPageIndex,
      registrationRequestsPageIndex,
    } = persisting;

  React.useEffect(() => {
    if (isAgents) {
      if (
        typeof agentsList[agentsPageIndex] !==
        "undefined"
      ) {
        setAgentsTable([
          [
            {
              name: "Агент",
              props: {
                xs: 6,
              },
            },
            {
              name: "Телефон",
              props: {
                xs: 3,
              },
            },
            {
              name: "Баланс",
              props: {
                xs: 3,
              },
            },
          ],
          ...agentsList[agentsPageIndex].map(
            (agent) => [
              <div
                className="flex_box"
                style={{
                  justifyContent: "flex-start",
                }}
              >
                <Typography variant="subtitle2">
                  {`${agent.name} ${agent.surname}`}
                </Typography>
              </div>,
              agent.phone,
              agent.balance
                ? String(agent.balance).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    " "
                  )
                : "0",
            ]
          ),
        ]);
        setLoading(false);
      } else {
        dispatch(
          getAgentsList({
            pageIndex: agentsPageIndex,
          })
        );
      }
    }
  }, [
    dispatch,
    isAgents,
    agentsList,
    agentsPageIndex,
  ]);
  React.useEffect(() => {
    if (isRequests) {
      if (
        typeof registrationRequests[
          registrationRequestsPageIndex
        ] !== "undefined"
      ) {
        setRequestsTable([
          [
            {
              name: "Агент",
              props: {
                xs: 6,
              },
            },
            {
              name: "Телефон",
              props: {
                xs: 6,
              },
            },
          ],
          ...registrationRequests[
            registrationRequestsPageIndex
          ].map((request) => [
            `${request.name} ${request.surname}`,
            request.phone,
          ]),
        ]);
      } else {
        dispatch(
          getRegistrationReuqests({
            pageIndex:
              registrationRequestsPageIndex,
          })
        );
      }
    }
  }, [
    dispatch,
    isRequests,
    registrationRequests,
    registrationRequestsPageIndex,
  ]);

  const onSearch = (value) => {
    setLoading(true);
    dispatch(
      getAgentsList(
        { pageIndex: agentsPageIndex },
        { searchText: value }
      )
    );
  };
  const agentsTableProps = {
    pageIndex: agentsPageIndex,
    setPageIndex: (page) =>
      dispatch(
        updateStoreData({
          key: "persisting",
          data: { agentsPageIndex: page },
        })
      ),
    pageCount: totals.agents?.totalPages || 1,
    onView: (index) =>
      navigate(
        `/admin/agents/${agentsList[agentsPageIndex][index].id}`
      ),
    onEdit: (index) => {
      setCurrentAgent(
        agentsList[agentsPageIndex][index]
      );
      setAgentModal({
        open: true,
        moderation: true,
      });
    },
    onDelete: (index) =>
      dispatch(
        createStoreData({
          prompDialog: {
            open: true,
            title: "Удаление агента",
            question: `Вы уверены, что хотите уволить агента ${currentAgent.name} ${currentAgent.surname}?`,
            message:
              "Вся статистика и информация об агенте останется в вашей базе после увольнения агента.",
            btnText: "Да, уволить",
            confirm: () =>
              dispatch(
                deleteAgent(
                  agentsList[agentsPageIndex][
                    index
                  ].id,
                  (success) => {
                    if (success) {
                      dispatch(
                        updateStoreData({
                          key: "agentsList",
                          data: {
                            [agentsPageIndex]:
                              undefined,
                          },
                        })
                      );
                    }
                  }
                )
              ),
          },
        })
      ),
    table: agentsTable,
  };
  const agentModalProps = {
    open: agentModal.open,
    moderation: agentModal.moderation,
    currentAgent,
    onCloseModal: (updateTablePage) => {
      if (
        Boolean(Object.keys(currentAgent).length)
      ) {
        setCurrentAgent({});
      }
      if (
        typeof updateTablePage === "boolean" &&
        updateTablePage
      ) {
        dispatch(
          updateStoreData({
            key: "agentsList",
            data: {
              [agentsPageIndex]: undefined,
            },
          })
        );
      }
      setAgentModal({
        open: false,
        moderation: false,
      });
    },
  };
  const registrationRequestsTableProps = {
    pageIndex: registrationRequestsPageIndex,
    setPageIndex: (page) =>
      dispatch(
        updateStoreData({
          key: "persisting",
          data: {
            registrationRequestsPageIndex: page,
          },
        })
      ),
    pageCount:
      totals.registrationRequests?.totalPages ||
      1,
    onView: (index) => {
      setCurrentAgent(
        registrationRequests[agentsPageIndex][
          index
        ]
      );
      setAgentModal({
        open: true,
        moderation: true,
      });
    },
    onEdit: (index) => {},
    onDelete: (index) =>
      dispatch(
        createStoreData({
          prompDialog: {
            open: true,
            title:
              "Удаление заявки на регистрацию",
            question:
              "Вы действительно хотите удалить эту заявку на регистрацию ?",
            confirm: () => {},
          },
        })
      ),
    table: requestsTable,
  };
  function onChangeTab(tab) {
    dispatch(
      updateStoreData({
        key: "persisting",
        data: { agentsTab: tab },
      })
    );
    if (tabs[tab] === "Список агентов")
      dispatch(
        getAgentsList({
          pageIndex: agentsPageIndex,
        })
      );
    if (tabs[tab] === "Заявки на регистрацию")
      dispatch(
        getRegistrationReuqests({
          pageIndex:
            registrationRequestsPageIndex,
        })
      );
  }
  return (
    <div className="agents_component">
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Typography variant="h3">
          Агенты
        </Typography>
        {isAgents && (
          <ThemeButton
            onClick={() =>
              setAgentModal({
                open: true,
                moderation: false,
              })
            }
            startIcon={
              <img
                src={plusIcon}
                alt="btn plus icon"
              />
            }
          >
            Новый агент
          </ThemeButton>
        )}
      </header>
      <TabsComponent
        tab={tab}
        setTab={onChangeTab}
        buttons={tabs}
      />
      <div style={{ marginTop: 25 }}>
        <Typography variant="h6">
          {tabs[tab]}
        </Typography>
      </div>
      <SearchComponent
        searchText={searchText}
        onChangeText={setSearchText}
        onSearch={onSearch}
        placeholder={
          "Введите имя или номер телефона агента"
        }
      />

      {isAgents && (
        <HocMini
          haveData={Boolean(
            totals.agents?.totalCount
          )}
          isLoading={isLoading}
        >
          <CustomDataTable
            {...agentsTableProps}
          />
        </HocMini>
      )}
      {isRequests && (
        <HocMini
          haveData={Boolean(
            totals.registrationRequests
              ?.totalCount
          )}
          isLoading={isLoading}
        >
          <CustomDataTable
            {...registrationRequestsTableProps}
          />
        </HocMini>
      )}

      <AgentModal {...agentModalProps} />
    </div>
  );
}
