import React, { useState } from "react";
import {
  DateRangePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FormInput } from "./TextFields";
import clearIcon from "assets/icons/Cross.svg";
import calendarIcon from "assets/icons/Calendar.svg";
import moment from "moment";

export default function ThemeDateRangePicker({
  textFieldProps,
  datePickerProps,
  value,
  onChange,
  style = {},
  clearable = true,
}) {
  const classes = useStyle();
  const [textValue, setTextValue] = useState("");
  const [
    isRangePickerOpen,
    setIsRangePickerOpen,
  ] = useState(false);
  let isInputTextValue = value[0] || value[1];

  function getFormattedDate(date) {
    return typeof date === "string"
      ? moment(date).isValid()
        ? moment(date).format("DD.MM.yyyy")
        : date
      : date.format("DD.MM.yyyy");
  }

  React.useEffect(() => {
    setTextValue(
      isInputTextValue
        ? `${getFormattedDate(
            value[0]
          )} - ${getFormattedDate(value[1])}`
        : ""
    );
  }, [value, isInputTextValue]);

  return (
    <div
      className="date_range_input_wrapper"
      style={style}
    >
      <FormInput
        disabled
        fullWidth
        value={textValue}
        onChange={(event) =>
          setTextValue(event.target.value)
        }
        classes={{ root: classes.input }}
        error={false}
        InputProps={{
          endAdornment: (
            <div
              className="flex_box"
              style={{
                width: isInputTextValue ? 80 : 40,
                gap: 8,
              }}
            >
              {clearable && isInputTextValue && (
                <IconButton
                  style={{
                    width: 23,
                    height: 23,
                  }}
                  size="small"
                  onClick={() =>
                    onChange([null, null])
                  }
                >
                  <img
                    src={clearIcon}
                    alt="clear icon"
                  />
                </IconButton>
              )}
              <IconButton
                style={{ width: 23, height: 23 }}
                size="small"
                onClick={() =>
                  setIsRangePickerOpen(
                    (state) => !state
                  )
                }
              >
                <img
                  src={calendarIcon}
                  alt="calendar icon"
                />
              </IconButton>
            </div>
          ),
        }}
        style={{ marginBottom: 20 }}
        {...textFieldProps}
      />
      <LocalizationProvider
        locale="ru"
        dateAdapter={AdapterMoment}
      >
        <DateRangePicker
          {...datePickerProps}
          style={{ display: "none" }}
          open={isRangePickerOpen}
          value={value || [null, null]}
          onClose={() =>
            setIsRangePickerOpen(false)
          }
          onChange={onChange}
          inputFormat="DD.MM.yyyy"
          mask="__.__.____"
          componentsProps={{
            style: { width: "100%" },
          }}
          renderInput={(startProps, endProps) => {
            return (
              <FormInput
                {...startProps}
                error={false}
                InputProps={{
                  endAdornment: clearable ? (
                    <IconButton
                      onClick={() =>
                        onChange([null, null])
                      }
                    >
                      <img
                        src={clearIcon}
                        alt="clear icon"
                      />
                    </IconButton>
                  ) : null,
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  input: {
    "& .Mui-disabled": {
      color: "#000",
      opacity: 1,
      WebkitTextFillColor: "#000",
    },
  },
}));
