import { Typography } from "@mui/material";
import React from "react";
import { ThemeButton } from "shared";

export default function PageSizeSelector({ pageSize, onChangePageSize }) {
  let buttonStyles = { height: 30, minWidth: 30, fontWeight: 400 },
    activeBtn = {
      ...buttonStyles,
      backgroundColor: "#C5E2F4",
      color: "#034F94",
      border: "1px solid #034F94",
    },
    inactiveBtn = {
      ...buttonStyles,
      backgroundColor: "#EBEBEB",
      color: "#000000",
      border: "none",
    },
    pageSizeBtn = {
      marginLeft: 12,
      padding: 6,
      width: 30,
    };
  return (
    <div
      className="flex_box"
      style={{
        width: 420,
        padding: "15px 0px",
        justifyContent: "space-between",
        margin: "24px 0px",
      }}>
      <div className="flex_box" style={{ justifyContent: "flex-end" }}>
        <Typography variant="body2">Показывать на странице:</Typography>
        <ThemeButton
          variant="outlined"
          style={Object.assign(
            {},
            pageSize === 20 ? activeBtn : inactiveBtn,
            pageSizeBtn
          )}
          onClick={() => onChangePageSize(20)}>
          20
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          style={Object.assign(
            {},
            pageSize === 50 ? activeBtn : inactiveBtn,
            pageSizeBtn
          )}
          onClick={() => onChangePageSize(50)}>
          50
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          style={Object.assign(
            {},
            pageSize === 80 ? activeBtn : inactiveBtn,
            pageSizeBtn
          )}
          onClick={() => onChangePageSize(80)}>
          80
        </ThemeButton>
      </div>
    </div>
  );
}
