import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import crossIcon from "assets/icons/Cross.svg";
import { ThemeButton, ValidatedFormInput } from "shared";
import {
  createCity,
  createCountry,
  createDistrict,
  createReferenceValue,
  createSkuGroup,
  updateCity,
  updateCountry,
  updateDistrict,
  updateReferenceValue,
  updateSkuGroup,
} from "api";
import { createStoreData } from "store";
import { updateStoreData } from "store";
import { CityAutocomplete, CountryAutocomplete } from "../Autocompletes";

const countryConstant = {
  initialValues: { countryName: "" },
  validation: {
    countryName: Yup.string().required("Поле обязательно к заполнению"),
  },
};
const cityConstant = {
  initialValues: {
    countryId: null,
    cityName: "",
  },
  validation: {
    countryId: Yup.object()
      .shape({
        id: Yup.string(),
        countryName: Yup.string(),
      })
      .nullable()
      .required("Поле обязательно к заполнению"),
    cityName: Yup.string().required("Поле обязательно к заполнению"),
  },
};

const districtConstant = {
  initialValues: {
    cityId: null,
    districtName: "",
  },
  validation: {
    cityId: Yup.object()
      .shape({
        id: Yup.string(),
        cityName: Yup.string(),
      })
      .nullable()
      .required("Поле обязательно к заполнению"),
    districtName: Yup.string().required("Поле обязательно к заполнению"),
  },
};

const skuGroupConstant = {
  initialValues: { groupName: "" },
  validation: {
    groupName: Yup.string().required("Поле обязательно к заполнению"),
  },
};

const brandConstant = {
  initialValues: { value: "" },
  validation: {
    value: Yup.string().required("Поле обязательно к заполнению"),
  },
};

const typeOfPackagingConstant = {
  initialValues: { value: "" },
  validation: {
    value: Yup.string().required("Поле обязательно к заполнению"),
  },
};

const packagingFormatConstant = {
  initialValues: { value: "" },
  validation: {
    value: Yup.string().required("Поле обязательно к заполнению"),
  },
};

export default function DirectoryModals({
  open,
  type,
  handleClose,
  currentDirectory,
}) {
  const dispatch = useDispatch();
  const { citiesSelect } = useSelector((store) => store);
  const [initialValues, setInitialValues] = useState({});
  const [validation, setValidation] = useState({});
  const [isLoading, setLoading] = useState(false);
  let isItUpdateMode = typeof currentDirectory !== "undefined",
    isCountry = type === "country",
    isCity = type === "city",
    isDistrict = type === "district",
    isSkuGroup = type === "skuGroup",
    isBrand = type === "brand",
    isTypeOfPackaging = type === "typeOfPackaging",
    isPackagingFormat = type === "packagingFormat";

  React.useEffect(() => {
    if (isItUpdateMode) {
      setInitialValues(currentDirectory);
    } else {
      setInitialValues(
        isCountry
          ? countryConstant.initialValues
          : isCity
          ? cityConstant.initialValues
          : isDistrict
          ? districtConstant.initialValues
          : isSkuGroup
          ? skuGroupConstant.initialValues
          : isBrand
          ? brandConstant.initialValues
          : isTypeOfPackaging
          ? typeOfPackagingConstant.initialValues
          : isPackagingFormat
          ? packagingFormatConstant.initialValues
          : {}
      );
    }
    setValidation(
      isCountry
        ? countryConstant.validation
        : isCity
        ? cityConstant.validation
        : isDistrict
        ? districtConstant.validation
        : isSkuGroup
        ? skuGroupConstant.validation
        : isBrand
        ? brandConstant.validation
        : isTypeOfPackaging
        ? typeOfPackagingConstant.validation
        : isPackagingFormat
        ? packagingFormatConstant.validation
        : {}
    );
  }, [
    isCity,
    isBrand,
    isCountry,
    isDistrict,
    isSkuGroup,
    isItUpdateMode,
    currentDirectory,
    isTypeOfPackaging,
    isPackagingFormat,
  ]);

  const submitHandler = (values) => {
    const callback = (success) => {
      setLoading(false);
      if (success) {
        if (isCountry) {
          dispatch(
            createStoreData({
              countries: null,
            })
          );
        }
        if (isCity) {
          dispatch(
            createStoreData({
              cities: null,
            })
          );
          dispatch(
            updateStoreData({
              key: "countryCities",
              data: {
                [isItUpdateMode
                  ? currentDirectory.countryId
                  : values.countryId]: undefined,
              },
            })
          );
        }
        if (isDistrict) {
          dispatch(
            createStoreData({
              districts: null,
            })
          );
          dispatch(
            updateStoreData({
              key: "cityDistricts",
              data: {
                [isItUpdateMode ? currentDirectory.cityId : values.cityId]:
                  undefined,
              },
            })
          );
        }
        if (isSkuGroup) {
          dispatch(
            createStoreData({
              skuGroupes: null,
            })
          );
        }

        dispatch(
          createStoreData({
            alert: {
              severity: "success",
              message: "Успешно",
            },
          })
        );
        refreshStore();
        handleClose();
      }
    };
    setLoading(true);
    if (isItUpdateMode) {
      if (isCountry) {
        dispatch(
          updateCountry(
            {
              id: currentDirectory.id,
              countryName: values.countryName,
            },
            callback
          )
        );
      }
      if (isCity) {
        dispatch(
          updateCity(
            {
              id: currentDirectory.id,
              cityName: values.cityName,
            },
            callback
          )
        );
      }
      if (isDistrict) {
        dispatch(
          updateDistrict(
            {
              id: currentDirectory.id,
              districtName: values.districtName,
            },
            callback
          )
        );
      }
      if (isSkuGroup) {
        dispatch(
          updateSkuGroup(
            {
              skuGroupId: currentDirectory.skuGroupId,
              groupName: values.groupName,
            },
            callback
          )
        );
      }
      if (isBrand) {
        dispatch(
          updateReferenceValue(
            {
              referenceId: currentDirectory.id,
              value: values.value,
            },
            callback
          )
        );
      }
      if (isTypeOfPackaging) {
        dispatch(
          updateReferenceValue(
            {
              referenceId: currentDirectory.id,
              value: values.value,
            },
            callback
          )
        );
      }
      if (isPackagingFormat) {
        dispatch(
          updateReferenceValue(
            {
              referenceId: currentDirectory.id,
              value: values.value,
            },
            callback
          )
        );
      }
    } else {
      if (isCountry) {
        dispatch(createCountry(values, callback));
      }
      if (isCity) {
        dispatch(
          createCity(
            {
              cityName: values.cityName,
              countryId: values.countryId.id,
            },
            callback
          )
        );
      }
      if (isDistrict) {
        dispatch(
          createDistrict(
            {
              districtName: values.districtName,
              cityId: values.cityId.id,
            },
            callback
          )
        );
      }
      if (isSkuGroup) {
        dispatch(createSkuGroup(values, callback));
      }
      if (isBrand) {
        dispatch(
          createReferenceValue(
            {
              referenceType: "Brand",
              value: values.value,
            },
            callback
          )
        );
      }
      if (isTypeOfPackaging) {
        dispatch(
          createReferenceValue(
            {
              referenceType: "TypeOfPackaging",
              value: values.value,
            },
            callback
          )
        );
      }
      if (isPackagingFormat) {
        dispatch(
          createReferenceValue(
            {
              referenceType: "PackagingFormat",
              value: values.value,
            },
            callback
          )
        );
      }
    }
  };
  const refreshStore = () => {
    if (isCountry) {
      dispatch(createStoreData({ countries: null }));
    }
    if (isCity) {
      dispatch(createStoreData({ cities: null }));
    }
    if (isDistrict) {
      dispatch(createStoreData({ districts: null }));
    }
    if (isSkuGroup) {
      dispatch(createStoreData({ skuGroupes: null }));
    }
    if (isBrand) {
      dispatch(
        createStoreData({
          brands: null,
        })
      );
    }
    if (isTypeOfPackaging) {
      dispatch(
        createStoreData({
          typeOfPackaging: null,
        })
      );
    }
    if (isPackagingFormat) {
      dispatch(
        createStoreData({
          packagingFormat: null,
        })
      );
    }
  };
  let title = isCountry
    ? "Новая страна"
    : isCity
    ? "Новый город"
    : isDistrict
    ? "Новый район"
    : isSkuGroup
    ? "Новая группа товаров"
    : isBrand
    ? "Новый бренд"
    : isTypeOfPackaging
    ? "Новый тип упаковки"
    : isPackagingFormat
    ? "Новый формат упаковки"
    : "TypeError: Unrecognized type";

  const { countriesSelect } = useSelector((store) => store);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <div
          className="flex_box sticky_header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            {isItUpdateMode ? "Редактирование" : title}
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={crossIcon} alt="cross" />
          </IconButton>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validation)}
          onSubmit={submitHandler}
          enableReinitialize
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              {isCountry && (
                <ValidatedFormInput name="countryName" label="Наименование " />
              )}
              {isCity && (
                <Box pt={2}>
                  <CountryAutocomplete
                    // value={formik.values.countryId}
                    value={countriesSelect && countriesSelect[0]}
                    updateValue={(country) => {
                      formik.handleChange({
                        target: {
                          name: "countryId",
                          value: country,
                        },
                      });
                    }}
                    validated
                  />
                  <ValidatedFormInput
                    name="cityName"
                    label="Наименование города "
                  />
                </Box>
              )}
              {isDistrict && (
                <Box pt={2}>
                  <CityAutocomplete
                    // value={formik.values.cityId}
                    value={citiesSelect && citiesSelect[0]}
                    updateValue={(city) => {
                      formik.handleChange({
                        target: {
                          name: "cityId",
                          value: city,
                        },
                      });
                    }}
                    validated
                  />
                  <ValidatedFormInput
                    name="districtName"
                    label="Наименование района"
                  />
                </Box>
              )}
              {isSkuGroup && (
                <ValidatedFormInput name="groupName" label="Наименование " />
              )}
              {(isBrand || isTypeOfPackaging || isPackagingFormat) && (
                <ValidatedFormInput name="value" label="Наименование " />
              )}

              <div
                className="flex_box"
                style={{
                  gap: 16,
                  marginTop: 32,
                  minWidth: 400,
                }}
              >
                <ThemeButton
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={20} style={{ color: "#FFFFFF" }} />
                  ) : isItUpdateMode ? (
                    "Сохранить"
                  ) : (
                    "Добавить"
                  )}
                </ThemeButton>
                <ThemeButton
                  type="button"
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={handleClose}
                >
                  Отмена
                </ThemeButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
