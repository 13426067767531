import React from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { createStoreData } from "store";

export default function CardSKU({
  product,
  onClick,
  style,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store);

  const navigateToSku = () => {
    dispatch(
      createStoreData({ currentSku: product })
    );
    navigate("/sku");
  };
  const onClickHandler = (event) => {
    event.stopPropagation();
    if (Boolean(onClick)) {
      onClick();
    } else {
      navigateToSku();
    }
  };
  return (
    <Paper
      classes={{ root: classes.card }}
      elevation={0}
      onClick={onClickHandler}
      style={style}
    >
      <div
        className="flex_box"
        style={{
          height: 200,
          overflow: "hidden",
          marginBottom: 10,
        }}
      >
        <img
          src={
            product.fileId
              ? `${process.env.REACT_APP_FILE_URL}/image/${product.fileId}?token=${token}`
              : product.skuImageBase64
          }
          alt={product.skuName}
          style={{
            maxWidth: "100%",
            height: "100%",
          }}
        />
      </div>
      <Typography variant="caption">
        {product.skuGroupName}
      </Typography>
      <Typography
        variant="body1"
        className={classes.skuName}
        onClick={onClickHandler}
      >
        {product.skuName}
      </Typography>
      <Typography
        variant="caption"
        style={{
          color: "#757575",
          fontWeight: 400,
        }}
      >
        {product.partnerName}
      </Typography>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minWidth: 300,
    padding: 12,
    border: "1px solid #C4C4C4",
    borderRadius: "8px !important",
    cursor: "pointer",
    "&:hover": {
      "& .MuiTypography-body1": {
        color: "#034F94",
      },
    },
  },
  skuName: {
    fontWeight: "600 !important",
  },
}));
