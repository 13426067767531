import React from "react";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import {
  IconButton,
  Typography,
} from "@mui/material";

import { multiDatePickerRu } from "localization";
import clearIcon from "assets/icons/Cross.svg";
import calendarIcon from "assets/icons/Calendar.svg";

export function ThemeDatePicker({
  value,
  onChange,
  disabled = false,
  placeholder = "Дата",
  ...props
}) {
  const datePicker = React.useRef();
  return (
    <div
      className="multiple_date_input_wrapper"
      style={props.style}
    >
      <div
        className="flex_box input"
        style={{ backgroundColor: "#FFFFFF" }}
        onClick={() => {
          if (!disabled) {
            let input =
              datePicker.current.firstChild;
            input.focus();
          }
        }}
      >
        <div
          className="flex_box"
          style={{ justifyContent: "flex-start" }}
        >
          <Typography
            variant="caption"
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            {Boolean(value)
              ? moment(new Date(value)).format(
                  "DD MMMM yyyy"
                )
              : placeholder}
          </Typography>
        </div>
        {Boolean(value) && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onChange(null);
            }}
            style={{
              position: "relative",
              zIndex: 1000,
            }}
          >
            <img
              src={clearIcon}
              alt=""
            />
          </IconButton>
        )}
        <img
          src={calendarIcon}
          alt=""
        />
      </div>
      {!disabled && (
        <DatePicker
          ref={datePicker}
          value={value || new Date()}
          onChange={onChange}
          locale={multiDatePickerRu}
          weekStartDayIndex={1}
        />
      )}
    </div>
  );
}
