import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import chevronDown from "assets/icons/ChevronDown.svg";
import { NoResult } from "shared";
import { makeStyles } from "@mui/styles";
import {
  List,
  AutoSizer,
} from "react-virtualized";

const BySector = React.memo(
  ({ reportList, isLoading }) => {
    const classes = useStyles();
    return (
      <Box className={classes.tableContainer}>
        <Grid
          item
          xs={12}
          container
          className={classes.row}
          style={{
            padding: "16px 146px 16px 22px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid
            item
            className={classes.head}
            xs={5}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Страна\Город\Маршрут или
              Сектор\Торговая точка
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Всего точек на маршруте\секторе
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Посещенно ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              % посещенных ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              ТТ где присутствовал товар
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Покрытие товара (по выбранному
              фильтру)
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Наличие ХО в ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Покрытие товара в ХО (по выбранному
              фильтру)
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <div
            className="flex_box"
            style={{ marginTop: 20 }}
          >
            <CircularProgress />
          </div>
        ) : reportList.length ? (
          reportList.map(
            (country, countryIndex) => (
              <CountryItem
                country={country}
                key={`${countryIndex}-${country.countryId}`}
              />
            )
          )
        ) : (
          <NoResult
            title="Данные не найдены"
            style={{ marginTop: 20 }}
          />
        )}
      </Box>
    );
  }
);

export default BySector;

const CountryItem = React.memo(
  ({ country, countryIndex }) => {
    const classes = useStyles();
    const [countryExpanded, setCountryIndex] =
      useState(false);
    return (
      <Accordion
        expanded={countryExpanded}
        onChange={() => {
          setCountryIndex(!countryExpanded);
        }}
        sx={{
          borderTop: "1px solid #ECECEC",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src={chevronDown}
              alt=""
            />
          }
        >
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 22px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={5}
            >
              <Typography variant="body1">
                {country?.countryName ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.salePointCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.visitedSalePoints
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.percentVisitedSalePoints,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.numberOfSalePointsWhereProductWas
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.percentProductCoverageByFilter,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.haveFridge
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  country?.percentProductCoverageByFilterInFridge,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {countryExpanded ? (
            country.childItems.length ? (
              country.childItems.map(
                (city, cityIndex) => (
                  <CityItem
                    city={city}
                    countryIndex={countryIndex}
                    cityIndex={cityIndex}
                    key={`${countryIndex}-${cityIndex}-${city.cityId}`}
                  />
                )
              )
            ) : (
              <NoResult
                style={{ height: 100 }}
                title="Данные не найдены"
              />
            )
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const CityItem = React.memo(
  ({ city, countryIndex, cityIndex }) => {
    const classes = useStyles();
    const [cityExpanded, setCityIndex] =
      useState(false);
    return (
      <Accordion
        expanded={cityExpanded}
        onChange={() => {
          setCityIndex(!cityExpanded);
        }}
        sx={{
          borderTop: "1px solid #ECECEC",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src={chevronDown}
              alt=""
            />
          }
        >
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 22px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={5}
            >
              <Typography variant="body1">
                {city?.cityName ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.salePointCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.visitedSalePoints
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.percentVisitedSalePoints,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.numberOfSalePointsWhereProductWas
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.percentProductCoverageByFilter,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.haveFridge
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  city?.percentProductCoverageByFilterInFridge,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {cityExpanded ? (
            city.childItems?.length ? (
              city.childItems.map(
                (district, districtIndex) => (
                  <DistrictItem
                    district={district}
                    countryIndex={countryIndex}
                    cityIndex={cityIndex}
                    districtIndex={districtIndex}
                    key={`${countryIndex}-${cityIndex}-${districtIndex}-${district.districtId}`}
                  />
                )
              )
            ) : (
              <NoResult
                style={{
                  height: 100,
                }}
                title="Данные не найдены"
              />
            )
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const DistrictItem = React.memo(
  ({ district }) => {
    const classes = useStyles();
    const [districtExpanded, setDistrictIndex] =
      useState(false);
    return (
      <Accordion
        expanded={districtExpanded}
        onChange={() => {
          setDistrictIndex(!districtExpanded);
        }}
        sx={{
          borderTop: "1px solid #ECECEC",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src={chevronDown}
              alt=""
            />
          }
        >
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 22px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={5}
            >
              <Typography variant="body1">
                {district?.districtName ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.salePointCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.visitedSalePoints
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.percentVisitedSalePoints,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.numberOfSalePointsWhereProductWas
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.percentProductCoverageByFilter,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.haveFridge
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  district?.percentProductCoverageByFilterInFridge,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {districtExpanded ? (
            district.childItems.length ? (
              <AutoSizer
                style={{
                  width: "auto",
                  height: "auto",
                }}
              >
                {({ width }) => {
                  const rowCount =
                    district.childItems.length;
                  const height =
                    rowCount >= 10
                      ? 550
                      : rowCount * 55;
                  return (
                    <List
                      height={height}
                      width={width}
                      rowCount={rowCount}
                      rowHeight={55}
                      rowRenderer={({
                        index,
                        key,
                        style,
                      }) => (
                        <SalepointItem
                          salePoint={
                            district.childItems[
                              index
                            ]
                          }
                          salePointIndex={index}
                          length={rowCount}
                          style={style}
                          key={key}
                        />
                      )}
                    />
                  );
                }}
              </AutoSizer>
            ) : (
              <NoResult
                style={{
                  height: 100,
                }}
                title="Данные не найдены"
              />
            )
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const SalepointItem = React.memo(
  ({
    salePoint,
    salePointIndex,
    length,
    style,
  }) => {
    const classes = useStyles();
    return (
      <Grid
        item
        xs={12}
        container
        className={classes.row}
        style={{
          padding: "16px 146px 16px 22px",
          backgroundColor: "#FFFFFF",
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderBottomWidth:
            salePointIndex !== length - 1
              ? "thin"
              : 0,
          ...style,
        }}
      >
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={5}
        >
          <Typography variant="body1">
            {salePoint?.salePointName ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.salePointCount
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.visitedSalePoints
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.percentVisitedSalePoints,
              true
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.numberOfSalePointsWhereProductWas
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.percentProductCoverageByFilter,
              true
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.haveFridge
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              salePoint?.percentProductCoverageByFilterInFridge,
              true
            ) ?? ""}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  head: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center !important",
      fontSize: "12px !important",
    },
  },
  row: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: 400,
      fontSize: 15,
    },
  },
}));

function getValueFormatted(number, percent) {
  return typeof number === "number"
    ? `${number
        .toString()
        .match(/^\d+(?:\.\d{0,2})?/)}${
        percent ? "%" : ""
      }`
    : false;
}
