import React, { useState } from "react";
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import Compressor from "compressorjs";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import deleteIcon from "assets/icons/delete_icon.svg";
import imageSkelet from "assets/icons/image_skelet.svg";
import {
  createStoreData,
  updateStoreData,
} from "store";
import {
  ThemeButton,
  ValidatedFormInput,
} from "shared";
import {
  getArrFromObj,
  getBase64,
  createSku,
  updateSku,
  deleteSku,
  uploadFile,
} from "api";
import {
  GroupSkuAutocomplete,
  BrandAutocomplete,
  TypeOfPackagingAutocomplete,
  PackagingFormatAutocomplete,
  PartnerAutocomplete,
  SkuAutocomplete,
} from "components/Autocompletes";

const initialValuesConstant = {
  skuName: "",
  partnerId: null,
  skuGroupId: null,
  brandId: null,
  typeOfPackagingId: null,
  packagingFormatId: null,
  mlTag: "",
  size: "",
  conflictSizeSkuIds: [],
};
const validationSchemaConstant = {
  skuName: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  partnerId: Yup.object()
    .shape({
      id: Yup.string(),
      partnerName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  skuGroupId: Yup.object()
    .shape({
      skuGroupId: Yup.string(),
      groupName: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  brandId: Yup.object()
    .shape({
      referenceId: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  typeOfPackagingId: Yup.object()
    .shape({
      referenceId: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  packagingFormatId: Yup.object()
    .shape({
      referenceId: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Поле обязательно к заполнению"),
  mlTag: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  size: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  conflictSizeSkuIds: Yup.array().of(
    Yup.object().shape({
      skuName: Yup.string(),
      skuId: Yup.string(),
    })
  ),
};

// Create and Update Sku
export default function CaUSku() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileUploader = React.useRef();
  const {
    user,
    token,
    partners,
    currentSku,
    skuOutlets,
    currentPartner,
  } = useSelector((store) => store);
  const [previewImages, setPreviewImages] =
    useState([]);
  const [images, setImages] = useState([]);
  const [initialValues, setInitialValues] =
    useState(initialValuesConstant);
  const [validationSchema, setValidationSchema] =
    useState(validationSchemaConstant);
  const [isLoading, setLoading] = useState(false);
  let isItUpdateMode =
      typeof currentSku !== "undefined",
    isAdmin =
      user?.role === process.env.REACT_APP_ADMIN;

  React.useEffect(() => {
    let newValues = isItUpdateMode
      ? {
          mlTag: currentSku.mlTag ?? "",
          size: currentSku.size ?? "",
          skuName: currentSku.skuName,
          partnerId: {
            id: currentSku.partnerId,
            partnerName: currentSku.partnerName,
          },
          skuGroupId: {
            skuGroupId: currentSku.skuGroupId,
            groupName: currentSku.skuGroupName,
          },
          brandId: {
            referenceId: currentSku.brandId,
            value: currentSku.brandName,
          },
          typeOfPackagingId: {
            referenceId:
              currentSku.typeOfPackagingId,
            value: currentSku.typeOfPackagingName,
          },
          packagingFormatId: {
            referenceId:
              currentSku.packagingFormatId,
            value: currentSku.packagingFormatName,
          },
          conflictSizeSkuIds:
            currentSku.conflictSizeSkuIds,
        }
      : initialValuesConstant;
    if (!isAdmin) delete newValues.partnerId;
    setInitialValues(newValues);
    let newValidationSchema = isAdmin
      ? validationSchemaConstant
      : {
          ...validationSchemaConstant,
        };
    // Сделать поле partnerId необязательным для партнеров
    if (!isAdmin)
      delete newValidationSchema.partnerId;
    setValidationSchema(newValidationSchema);
  }, [
    dispatch,
    isItUpdateMode,
    isAdmin,
    currentSku,
    partners,
    currentPartner,
  ]);
  React.useEffect(() => {
    if (isItUpdateMode) {
      setPreviewImages([
        `${process.env.REACT_APP_FILE_URL}/image/${currentSku.fileId}?token=${token}`,
      ]);
    }
  }, [
    token,
    dispatch,
    currentSku,
    skuOutlets,
    isItUpdateMode,
  ]);

  const filesSelectHandler = (files) => {
    getArrFromObj({ ...files }).forEach(
      (img) =>
        new Compressor(img, {
          quality: 0.8,
          mimeType: "image/webp",
          success: async (compressedImg) => {
            let b64 = await getBase64(
              compressedImg
            );
            setPreviewImages([b64]);
            setImages([compressedImg]);
          },
        })
    );
    fileUploader.current.value = "";
  };
  const submitHandler = (values) => {
    if (!Boolean(previewImages[0])) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Загрузите фото!",
          },
        })
      );
      return;
    }
    let requestBody = {
        ...values,
        partnerId: isAdmin
          ? values.partnerId.id
          : null,
        skuGroupId: values.skuGroupId.skuGroupId,
        brandId: values.brandId.referenceId,
        typeOfPackagingId:
          values.typeOfPackagingId.referenceId,
        packagingFormatId:
          values.packagingFormatId.referenceId,
      },
      callback = (success) => {
        setLoading(false);
        if (success) {
          if (isAdmin) {
            dispatch(
              updateStoreData({
                key: "partnersSku",
                data: { [currentPartner.id]: {} },
              })
            );
          }
          dispatch(
            createStoreData({
              skuList: {},
              alert: {
                severity: "success",
                message: "Успешно",
              },
            })
          );
          navigate(-1);
        }
      },
      fileUploader = (file, callback) => {
        let formData = new FormData();
        formData.append("file", file);
        dispatch(uploadFile(formData, callback));
      };
    if (isItUpdateMode) {
      if (Boolean(images.length)) {
        fileUploader(images[0], (id) => {
          if (id) {
            dispatch(
              updateSku(
                {
                  ...requestBody,
                  skuId: currentSku.skuId,
                  fileId: id,
                },
                callback
              )
            );
          }
        });
      } else {
        dispatch(
          updateSku(
            {
              ...requestBody,
              skuId: currentSku.skuId,
              fileId: currentSku.fileId,
            },
            callback
          )
        );
      }
    } else {
      fileUploader(images[0], (id) => {
        if (id) {
          requestBody.fileId = id;
          if (isAdmin && currentPartner.id) {
            requestBody.partnerId =
              currentPartner.id;
          }
          setLoading(true);
          dispatch(
            createSku(requestBody, callback)
          );
        } else {
          dispatch(
            createStoreData({
              alert: {
                severity: "error",
                message:
                  "Возникла ошибка при загрузке фото.",
              },
            })
          );
        }
      });
    }
  };
  const deleteSkuHandler = () =>
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Удаление товара",
          question:
            "Вы действительно хотите удалить этот товар ?",
          confirm: () =>
            dispatch(
              deleteSku(
                currentSku.skuId,
                (success) => {
                  if (success) {
                    dispatch(
                      updateStoreData({
                        key: "partnersSku",
                        data: {
                          [currentPartner.id]: {},
                        },
                      })
                    );
                    dispatch(
                      createStoreData({
                        skuList: {},
                      })
                    );
                    dispatch(
                      createStoreData({
                        alert: {
                          severity: "success",
                          message: "Успешно",
                        },
                      })
                    );
                    navigate(
                      Boolean(currentPartner.id)
                        ? -1
                        : "/admin/directory"
                    );
                  }
                }
              )
            ),
        },
      })
    );
  let pageTitle = isItUpdateMode
    ? currentSku.skuName
    : "Новый товар";
  return (
    <Container style={{ padding: "18px 0px" }}>
      <Breadcrumbs
        separator=">"
        style={{ marginBottom: 18 }}
      >
        <NavLink
          to={
            Boolean(currentPartner.id)
              ? "/admin/partners"
              : "/admin/directory"
          }
          className="navBreadcrumb"
        >
          {Boolean(currentPartner.id)
            ? "Партнеры"
            : "Справочники"}
        </NavLink>
        {Boolean(currentPartner.id) && (
          <NavLink
            to={`/admin/partners/${currentPartner.id}`}
            className="navBreadcrumb"
          >
            {currentPartner.partnerName}
          </NavLink>
        )}
        <Typography
          variant="body2"
          style={{
            color: "#000",
            lineHeight: "150%",
            fontWeight: 600,
          }}
        >
          {pageTitle}
        </Typography>
      </Breadcrumbs>
      <div className="flex_box_between">
        <Typography variant="h3">
          {pageTitle}
        </Typography>
        {isItUpdateMode && (
          <Button
            variant="delete"
            onClick={deleteSkuHandler}
          >
            Удалить товар
          </Button>
        )}
      </div>
      <Paper
        elevation={0}
        className={classes.dragAndDropWrapper}
      >
        {Boolean(previewImages.length) ? (
          <div className="images_preview_area">
            <div
              className="flex_box"
              style={{
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">
                Фото
              </Typography>
              <ThemeButton
                variant="outlined"
                style={{ width: 150 }}
                onClick={() => {
                  fileUploader.current.click();
                }}
              >
                Обновить фото
              </ThemeButton>
            </div>
            <div className="images_wrapper">
              {previewImages.map(
                (image, imageIndex) => (
                  <div
                    key={imageIndex}
                    className="flex_box preview_item"
                  >
                    <img
                      src={image}
                      alt=""
                      className="preview_image"
                    />
                    <IconButton
                      className={
                        classes.deleteIcon
                      }
                      onClick={() => {
                        setPreviewImages(
                          (state) =>
                            state.filter(
                              (_, stateIndex) =>
                                imageIndex !==
                                stateIndex
                            )
                        );
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete icon"
                      />
                    </IconButton>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div
            className="flex_box drag_and_drop_area"
            onDragOver={(e) => {
              e.preventDefault();
            }}
            //   onDragEnd={dragHandler}
            //   onDragLeave={dragHandler}
            onDrop={(e) => {
              e.preventDefault();
              filesSelectHandler(
                e.dataTransfer.files
              );
            }}
          >
            <section
              style={{
                width: "50%",
                textAlign: "center",
              }}
            >
              <img
                src={imageSkelet}
                alt=""
              />
              <Typography
                variant="body1"
                style={{
                  marginTop: 23,
                  marginBottom: 6,
                }}
              >
                Чтобы загрузить фото, вы можете
                перетащить его сюда
              </Typography>
              <ThemeButton
                variant="outlined"
                style={{ width: 150 }}
                onClick={() => {
                  fileUploader.current.click();
                }}
              >
                Загрузить фото
              </ThemeButton>
            </section>
          </div>
        )}
      </Paper>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(
          validationSchema
        )}
        onSubmit={submitHandler}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <Paper
                elevation={0}
                style={{
                  padding: 32,
                  marginTop: 24,
                }}
              >
                <Typography variant="h6">
                  Описание
                </Typography>
                <div
                  className="flex_box"
                  style={{
                    gap: "3.33%",
                    minHeight: 114,
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <ValidatedFormInput
                      name="skuName"
                      label="Наименование товара"
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <GroupSkuAutocomplete
                      value={
                        formik.values.skuGroupId
                      }
                      updateValue={(skuGroup) => {
                        formik.handleChange({
                          target: {
                            name: "skuGroupId",
                            value: skuGroup,
                          },
                        });
                      }}
                      validated
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <ValidatedFormInput
                      name="mlTag"
                      label="AI тег"
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <BrandAutocomplete
                      value={
                        formik.values.brandId
                      }
                      updateValue={(brand) => {
                        formik.handleChange({
                          target: {
                            name: "brandId",
                            value: brand,
                          },
                        });
                      }}
                      validated
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <TypeOfPackagingAutocomplete
                      value={
                        formik.values
                          .typeOfPackagingId
                      }
                      updateValue={(
                        typeOfPackaging
                      ) => {
                        formik.handleChange({
                          target: {
                            name: "typeOfPackagingId",
                            value:
                              typeOfPackaging,
                          },
                        });
                      }}
                      validated
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <PackagingFormatAutocomplete
                      value={
                        formik.values
                          .packagingFormatId
                      }
                      updateValue={(
                        packagingFormat
                      ) => {
                        formik.handleChange({
                          target: {
                            name: "packagingFormatId",
                            value:
                              packagingFormat,
                          },
                        });
                      }}
                      validated
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <ValidatedFormInput
                      name="size"
                      label="Размер"
                      type="number"
                    />
                  </div>
                  {!isItUpdateMode &&
                    !Boolean(currentPartner.id) &&
                    isAdmin && (
                      <div
                        style={{ width: "30%" }}
                      >
                        <PartnerAutocomplete
                          value={
                            formik.values[
                              "partnerId"
                            ]
                          }
                          updateValue={(
                            partner
                          ) => {
                            formik.handleChange({
                              target: {
                                name: "partnerId",
                                value: partner,
                              },
                            });
                          }}
                          validated
                        />
                      </div>
                    )}
                  <div
                    style={{
                      minWidth: "30%",
                      width: "fit-content",
                    }}
                  >
                    <SkuAutocomplete
                      label="Похожий товар"
                      value={
                        formik.values[
                          "conflictSizeSkuIds"
                        ]
                      }
                      updateValue={(
                        conflictSizeSkuIds
                      ) => {
                        formik.handleChange({
                          target: {
                            name: "conflictSizeSkuIds",
                            value:
                              conflictSizeSkuIds,
                          },
                        });
                      }}
                      partnerId={
                        formik.values["partnerId"]
                          ? formik.values[
                              "partnerId"
                            ].id
                          : null
                      }
                      validated
                      multiple
                    />
                  </div>
                </div>
                {isItUpdateMode && (
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400 }}
                    mt={2}
                  >
                    {"Партнер: "}
                    <Typography
                      variant="body1"
                      component="span"
                    >
                      {currentSku.partnerName}
                    </Typography>
                  </Typography>
                )}
                <div
                  className="flex_box"
                  style={{
                    gap: 16,
                    marginTop: 32,
                  }}
                >
                  <ThemeButton
                    style={{
                      width: 150,
                    }}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={20}
                        style={{
                          color: "#FFFFFF",
                        }}
                      />
                    ) : isItUpdateMode ? (
                      "Сохранить"
                    ) : (
                      "Создать товар"
                    )}
                  </ThemeButton>
                  <ThemeButton
                    variant="outlined"
                    style={{ width: 150 }}
                    onClick={() => navigate(-1)}
                  >
                    Отмена
                  </ThemeButton>
                </div>
              </Paper>
            </Form>
          );
        }}
      </Formik>
      <input
        ref={fileUploader}
        onChange={(event) => {
          filesSelectHandler(event.target.files);
        }}
        type="file"
        hidden
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  dragAndDropWrapper: {
    padding: 16,
    marginTop: 40,
    height: 326,
  },
  deleteIcon: {
    position: "absolute !important",
    top: 0,
    right: 0,
  },
  menuPaper: {
    width: "50%",
    maxHeight: 400,
    overflow: "auto",
    borderRadius: 4,
    padding: "0px 32px 32px",
    boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.15)",
  },
}));
