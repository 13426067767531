import {
  Autocomplete,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import lodash from "lodash";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { FormInput } from "shared";
import { ErrorMessage, useField } from "formik";
import { makeStyles } from "@mui/styles";
import { DEBOUNCE_DELEY } from "utils/constants";

export default function AutocompleteInput({
  value,
  onChange,
  options,
  label,
  getOptionLabel,
  isOptionEqualToValue,
  fetchOptions,
  multiple = false,
  validated = false,
  name,
  disabled,
  isCity,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function getOptions(searchText = "") {
    setLoading(true);
    return fetchOptions(searchText, () =>
      setLoading(false)
    );
  }
  const getOptionDebounced = React.useRef(
    lodash.debounce(getOptions, DEBOUNCE_DELEY)
  ).current;
  function onOpen() {
    setOpen(true);
    getOptions();
  }
  function onChangeHandler(event, value, reason) {
    if (
      reason === "selectOption" ||
      reason === "removeOption"
    ) {
      onChange(value);
    }
  }
  function onSearchTextChange(
    event,
    value,
    reason
  ) {
    if (reason === "input") {
      getOptionDebounced(value);
    }
  }
  return (
    <>
      {validated && label && (
        <Typography
          variant="body2"
          className={classes.label}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        sx={{ width: validated ? "100%" : (isCity ? "100%" : 300) }}
        open={open}
        onOpen={onOpen}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={onChangeHandler}
        onInputChange={onSearchTextChange}
        isOptionEqualToValue={
          isOptionEqualToValue
        }
        getOptionLabel={getOptionLabel}
        options={options}
        noOptionsText="Данные не найдены"
        loading={loading}
        loadingText="Загрузка..."
        disableClearable
        multiple={multiple}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        renderInput={(params) => {
          const inputProps = {
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          };
          return validated
            ? RenderValidatedInput(
                params,
                name,
                inputProps
              )
            : RenderInput(
                params,
                name,
                label,
                inputProps
              );
        }}
        renderOption={(...params) =>
          renderOption(...params, getOptionLabel)
        }
        {...props}
      />
    </>
  );
}

function RenderInput(
  params,
  name,
  label,
  inputProps
) {
  return (
    <FormInput
      {...params}
      name={name}
      label={label}
      InputProps={inputProps}
    />
  );
}

function RenderValidatedInput(
  params,
  name,
  inputProps
) {
  const [field, meta] = useField({ name });
  let error = Boolean(meta.touched && meta.error);
  return (
    <FormInput
      value={field.value}
      {...field}
      error={error}
      helperText={
        error ? (
          <ErrorMessage name={field.name} />
        ) : (
          ""
        )
      }
      fullWidth
      {...params}
      name={name}
      InputProps={inputProps}
    />
  );
}

function renderOption(
  props,
  option,
  { inputValue },
  getOptionLabel
) {
  const matches = match(
    getOptionLabel(option),
    inputValue,
    {
      insideWords: true,
    }
  );
  const parts = parse(
    getOptionLabel(option),
    matches
  );
  return (
    <li
      {...props}
      key={`${props.key}/${props.id}`}
    >
      <div>
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              color: part.highlight
                ? "#034f94"
                : "#000000",
              fontWeight: part.highlight
                ? 700
                : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: "24px !important",
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
}));
