import {
  useSelector,
  useDispatch,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import PartnerModal from "../Dialogs/PartnerModal";
import plusIcon from "assets/icons/Plus.svg";
import { ThemeButton, NoResult } from "shared";
import SearchComponent from "../SearchComponent";
import CustomDataTable from "../CustomDataTable";
import {
  deletePartner,
  getPartnersList,
} from "api";
import {
  createStoreData,
  updateStoreData,
} from "store";

export function Partners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    totals,
    partnersList,
    currentPartner,
  } = useSelector((store) => store);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [
    openNewPartnerModal,
    setNewPartnerModal,
  ] = useState(false);
  let isAdmin =
    user?.role === process.env.REACT_APP_ADMIN;

  React.useEffect(() => {
    if (isAdmin) {
      if (
        typeof partnersList[pageIndex] !==
        "undefined"
      ) {
        setTableData([
          [
            {
              name: "Наименование",
              props: {
                xs: 6,
              },
            },
            {
              name: "Представитель",
              props: {
                xs: 3,
              },
            },
            {
              name: "Email",
              props: {
                xs: 3,
              },
            },
          ],
          ...partnersList[pageIndex].map(
            (partner) => [
              partner.partnerName,
              `${partner.name} ${partner.surname}`,
              partner.email,
            ]
          ),
        ]);
        setLoading(false);
      } else {
        dispatch(getPartnersList({ pageIndex }));
      }
    }
  }, [
    dispatch,
    partnersList,
    pageIndex,
    isAdmin,
  ]);

  const onSearch = (value) => {
    setLoading(true);
    dispatch(
      getPartnersList(
        { pageIndex },
        { searchText: value }
      )
    );
  };
  const dataTableProps = {
    pageIndex,
    setPageIndex,
    pageCount: totals.partners?.totalPages || 1,
    onView: (index) => {
      dispatch(
        createStoreData({
          currentPartner:
            partnersList[pageIndex][index],
        })
      );
      navigate(
        `/admin/partners/${partnersList[pageIndex][index].id}`
      );
    },
    onEdit: (index) => {
      dispatch(
        createStoreData({
          currentPartner:
            partnersList[pageIndex][index],
        })
      );
      setNewPartnerModal(true);
    },
    onDelete: (index) => {
      let partner =
        partnersList[pageIndex][index];
      dispatch(
        createStoreData({
          prompDialog: {
            open: true,
            title: "Удаление партнера",
            question: `Вы уверены, что хотите удалить партнера “${partner.partnerName}”?`,
            message:
              "Вся статистика и информация о партнере, его товары и аналитика будут полностью удалены из системы",
            confirm: () =>
              dispatch(
                deletePartner(
                  partner.id,
                  (success) => {
                    if (success) {
                      dispatch(
                        updateStoreData({
                          key: "partnersList",
                          data: {
                            [pageIndex]:
                              undefined,
                          },
                        })
                      );
                    }
                  }
                )
              ),
          },
        })
      );
    },
    table: tableData,
  };
  const partnerModalProps = {
    currentPartner,
    open: openNewPartnerModal,
    onCloseModal: (updateTablePage) => {
      if (
        Boolean(
          Object.keys(currentPartner).length
        )
      ) {
        dispatch(
          createStoreData({
            currentPartner: { id: "" },
          })
        );
      }
      if (
        typeof updateTablePage === "boolean" &&
        updateTablePage
      ) {
        dispatch(
          updateStoreData({
            key: "partnersList",
            data: { [pageIndex]: undefined },
          })
        );
      }
      setNewPartnerModal(false);
    },
  };
  return (
    <div className="partners_component">
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">
          Партнеры
        </Typography>
        <ThemeButton
          onClick={() => setNewPartnerModal(true)}
          startIcon={
            <img
              src={plusIcon}
              alt="btn plus icon"
            />
          }
        >
          Новый партнер
        </ThemeButton>
      </header>
      <SearchComponent
        searchText={searchText}
        onChangeText={setSearchText}
        onSearch={onSearch}
        placeholder={
          "Введите имя или номер телефона партнера"
        }
      />
      {isLoading ? (
        <div className="flex_box">
          <CircularProgress />
        </div>
      ) : Boolean(totals.partners?.totalCount) ? (
        <CustomDataTable {...dataTableProps} />
      ) : (
        <NoResult title="Данные не найдены" />
      )}
      <PartnerModal {...partnerModalProps} />
    </div>
  );
}
