import React from "react";
import {
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/styles";
import lodash from "lodash";

import SearchIcon from "assets/icons/Search.svg";
import crossIcon from "assets/icons/CrossBlack.svg";
import { DEBOUNCE_DELEY } from "utils/constants";

export default function SearchComponent({
  placeholder,
  searchText,
  onChangeText,
  onSearch,
}) {
  // const searchDebounced = React.useRef(
  //   lodash.debounce(onSearch, DEBOUNCE_DELEY)
  // ).current;

  const searchDebounced = lodash.debounce(function (value) {
    return onSearch(value)
  }, DEBOUNCE_DELEY);

  // React.useEffect(() => {
  //   return () => searchDebounced.cancel();
  // }, [searchDebounced]);

  function onChangeHandler(event) {
    let {
      target: { value },
    } = event;
    onChangeText(value);
    searchDebounced(value);
  }
  return (
    <Paper
      style={{
        borderRadius: 6,
        padding: 24,
        marginTop: 20,
        marginBottom: 24,
      }}
      elevation={0}
    >
      <StyledTextField
        value={searchText}
        onChange={onChangeHandler}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <img
              style={{ marginRight: 12 }}
              src={SearchIcon}
              alt="search icon"
            />
          ),
          endAdornment: Boolean(
            searchText.length
          ) ? (
            <IconButton
              onClick={() =>
                onChangeHandler({
                  target: {
                    value: "",
                  },
                })
              }
            >
              <img
                src={crossIcon}
                alt="search icon"
              />
            </IconButton>
          ) : null,
        }}
        fullWidth
      />
    </Paper>
  );
}

export const StyledTextField = styled(TextField)(
  ({ theme, props }) => ({
    backgroundColor: "#F1F2F3",
    borderRadius: 6,
    "& .MuiOutlinedInput-root": {
      height: 40,
      padding: "8px 12px",
      "& fieldset": {
        borderRadius: 6,
        border: "none",
      },
      "& input::placeholder": {
        color: "#AAAAAA",
        fontWeight: 400,
        opacity: 1,
      },
    },
  })
);
