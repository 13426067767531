import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  approveRegistration,
  createAgent,
  getBase64,
  getDistricts,
  getNormalizedDateString,
  refuseRegistration,
  updateAgent,
  uploadFile,
} from "api";
import { createStoreData } from "store";
import crossIcon from "assets/icons/Cross.svg";
import cameraIcon from "assets/icons/Camera.svg";
import plusBlueIcon from "assets/icons/PlusBlue.svg";
import DistrictSelectModal from "components/Dialogs/DistrictSelectModal";
import crossBlackIcon from "assets/icons/CrossBlack.svg";
import crossWhiteIcon from "assets/icons/cross_white.svg";
import {
  ThemeButton,
  ValidatedFormInput,
  ThemeDatePicker,
} from "shared";
import { PartnerAutocomplete } from "components/Autocompletes";

const initialValuesConstant = {
  name: "",
  surname: "",
  dateOfBirth: "",
  email: "",
  address: "",
  phone: "",
  bank: "",
  paymentAccount: "",
  companyPartnerId: null,
  isCompanyEmployee: false,
};
const validationSchemaConstant = {
  surname: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  name: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  email: Yup.string()
    .email("Введенный email не корректный")
    .required("Поле обязательно к заполнению"),
  address: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  phone: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  bank: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  paymentAccount: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
};
const photosConstant = {
  userPhotoId: null,
  passportFrontId: null,
  passportBackId: null,
  birthCertificateId: null,
};

export default function AgentModal({
  open,
  onCloseModal,
  currentAgent,
  moderation,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token, districts, partners } = useSelector(
    (store) => store
  );
  const fileUploader = React.useRef();
  const [districtsIdArray, setDistrictsIdArray] =
    useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPhoto, setCurrentPhoto] =
    useState("");
  const [photos, setPhotos] = useState(
    photosConstant
  );
  const [commentModal, setCommentModal] =
    useState(false);
  const [districtsState, setDistrictsState] =
    useState([]);
  const [previewPhotos, setPreviewPhotos] =
    useState(photosConstant);
  const [viewer, setViewer] = useState({
    open: false,
    image: null,
  });
  const [
    openDistrictSelect,
    setOpenDistrictSelect,
  ] = useState(false);
  const [olderThanSixteen, setOlderThanSixteen] =
    useState(true);
  const [validationSchema, setValidationSchema] =
    useState({
      ...validationSchemaConstant,
    });
  const [initialValues, setInitialValues] =
    useState(initialValuesConstant);
  let updateMode = currentAgent.id;

  React.useEffect(() => {
    setPreviewPhotos({
      userPhotoId: null,
      passportFrontId: null,
      passportBackId: null,
      birthCertificateId: null,
    });
    setPhotos({
      userPhotoId: null,
      passportFrontId: null,
      passportBackId: null,
      birthCertificateId: null,
    })
    setViewer({
      open: false,
      image: null,
    })
  }, [open]);

  React.useEffect(() => {
    if (!Array.isArray(districts)) {
      dispatch(getDistricts());
    } else if (updateMode) {
      let formattedArray = [];
      districts.forEach((district) => {
        let lastRaw =
          formattedArray[
            formattedArray.length - 1
          ];
        if (
          typeof lastRaw === "undefined" ||
          lastRaw.length === 3
        ) {
          formattedArray.push([district]);
        } else {
          lastRaw.push(district);
        }
      });
      setDistrictsState(formattedArray);
    }
  }, [districts, dispatch, updateMode]);
  React.useEffect(() => {
    if (updateMode) {
      let newInitialValues = { ...currentAgent };
      for (let key in newInitialValues) {
        if (newInitialValues[key] === null) {
          newInitialValues[key] = "";
        }
        if (key === "dateOfBirth") {
          newInitialValues[key] = new Date(
            currentAgent[key]
          ).toISOString();
        }
      }
      setOlderThanSixteen(
        moment().diff(
          moment(
            new Date(currentAgent.dateOfBirth)
          ),
          "years"
        ) >= 16
      );
      setDistrictsIdArray(
        Array.isArray(currentAgent.districtIds)
          ? currentAgent.districtIds
          : []
      );
      setInitialValues({
        ...newInitialValues,
        companyPartnerId: {
          id: currentAgent.companyPartnerId,
          partnerName:
            currentAgent.companyPartner,
        },
      });
      setValidationSchema((state) => {
        if (currentAgent.isCompanyEmployee) {
          delete state.bank;
          delete state.paymentAccount;
          return state;
        } else {
          return { ...validationSchemaConstant };
        }
      });
      if(token && open) {
        setTimeout(() => {
          setPreviewPhotos({
            userPhotoId:
              currentAgent.userPhotoId &&
              `${process.env.REACT_APP_FILE_URL}/image/${currentAgent.userPhotoId}?token=${token}`,
            passportFrontId:
              currentAgent.passportFrontId &&
              `${process.env.REACT_APP_FILE_URL}/image/${currentAgent.passportFrontId}?token=${token}`,
            passportBackId:
              currentAgent.passportBackId &&
              `${process.env.REACT_APP_FILE_URL}/image/${currentAgent.passportBackId}?token=${token}`,
            birthCertificateId:
              currentAgent.birthCertificateId &&
              `${process.env.REACT_APP_FILE_URL}/image/${currentAgent.birthCertificateId}?token=${token}`,
          });
        }, 500)
      }
    }
  }, [currentAgent, token, updateMode, open]);

  const handleSubmit = (values, {resetForm}) => {
    let formData = {
      ...values,
      dateOfBirth: getNormalizedDateString(
        values.dateOfBirth
      ),
    };
    if (olderThanSixteen) {
      formData.birthCertificateId = null;
      if (
        !previewPhotos["passportFrontId"] ||
        !previewPhotos["passportBackId"]
      )
        dispatch(
          createStoreData({
            alert: {
              severity: "error",
              message:
                "Пожалуйста загрузите фотографии паспорта",
            },
          })
        );
    } else {
      formData.passportFrontId = null;
      formData.passportBackId = null;
      if (!previewPhotos["birthCertificateId"])
        dispatch(
          createStoreData({
            alert: {
              severity: "error",
              message:
                "Пожалуйста загрузите фотографию свидетельства",
            },
          })
        );
    }
    setLoading(true);
    if (values.isCompanyEmployee) {
      if (districtsIdArray.length) {
        formData.districtIds = districtsIdArray;
      } else {
        dispatch(
          createStoreData({
            alert: {
              severity: "error",
              message: "Выберите районы",
            },
          })
        );
        return;
      }
      formData.bank = null;
      formData.paymentAccount = null;
      formData.companyPartnerId =
        values.companyPartnerId.id;
    } else {
      formData.districtIds = [];
      formData.companyPartnerId = null;
    }
    function successCallback() {
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "Успешно",
          },
          // Обновление всего списка агентов
          agentsList: {},
        })
      );
      handleClose(true);
    }
    if (updateMode) {
      let imageUploadRequests = [],
        requestData = {
          ...currentAgent,
          ...formData,
        };

      for (let key in photos) {
        if (photos[key]) {
          let formData = new FormData();
          formData.append("file", photos[key]);
          imageUploadRequests.push(
            dispatch(
              uploadFile(formData, (id) => {
                if (id) {
                  requestData[key] = id;
                }
              })
            )
          );
        }
      }
      if (imageUploadRequests.length) {
        Promise.all(imageUploadRequests)
          .then(() => {
            dispatch(
              updateAgent(
                requestData,
                (success) => {
                  if (success) {
                    successCallback();
                  }
                  setLoading(false);
                }
              )
            );
          })
          .catch(() => {
            dispatch(
              createStoreData({
                alert: {
                  severity: "error",
                  message:
                    "Не удалось сохранить файлы",
                },
              })
            );
            setLoading(false);
          });
      } else {
        dispatch(
          updateAgent(requestData, (success) => {
            if (success) {
              successCallback();
            }
            setLoading(false);
          })
        );
      }
    } else {
      let imageUploadRequests = [];
      for (let key in photos) {
        if (photos[key]) {
          let formData = new FormData();
          formData.append("file", photos[key]);
          imageUploadRequests.push(
            dispatch(
              uploadFile(formData, (id) => {
                if (id) {
                  formData[key] = id;
                }
              })
            )
          );
        }
      }

      if (imageUploadRequests.length) {
        Promise.all(imageUploadRequests)
          .then(() => {
            dispatch(
              createAgent(formData, (success) => {
                if (success) {
                  dispatch(
                    createStoreData({
                      alert: {
                        severity: "success",
                        message:
                          "Новый агент успешно создан!",
                      },
                      // Обновление всего списка агентов
                      agentsList: {},
                    })
                  );
                  setLoading(false);
                  resetForm();
                  handleClose();
                }
              })
            );
          })
          .catch(() => {
            dispatch(
              createStoreData({
                alert: {
                  severity: "error",
                  message:
                    "Не удалось сохранить файлы",
                },
              })
            );
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };
  const approveRegistrationHandler = () => {
    setLoading(true);
    dispatch(
      approveRegistration(
        { id: updateMode },
        (success) => {
          if (success) {
            setLoading(false);
            dispatch(
              createStoreData({
                alert: {
                  open: true,
                  severity: "success",
                  message: "Успешно одобрено",
                },
              })
            );
            handleClose();
          }
        }
      )
    );
  };
  const refuseRegistrationHandler = (values) => {
    closeCommentModal();
    setLoading(true);
    dispatch(
      refuseRegistration(
        {
          id: updateMode,
          moderationComment:
            values.moderationComment,
        },
        (success) => {
          setLoading(false);
          if (success) {
            dispatch(
              createStoreData({
                alert: {
                  open: true,
                  severity: "success",
                  message: "Успешно",
                },
              })
            );
            handleClose();
          }
        }
      )
    );
  };
  const filesUploadHandler = async (file) => {
    let b64 = await getBase64(file);
    setPreviewPhotos((state) => ({
      ...state,
      [currentPhoto]: b64,
    }));
    setPhotos((state) => ({
      ...state,
      [currentPhoto]: file,
    }));
  };
  const closeCommentModal = () =>
    setCommentModal(false);
  const closeViewerModal = () =>
    setViewer({ open: false, image: null });
  const changeAgentType = (event) => {
    let value = event.target.value;
    setInitialValues((state) => ({
      ...state,
      isCompanyEmployee: value === "true",
    }));
    setValidationSchema((state) => {
      if (value === "true") {
        delete state.bank;
        delete state.paymentAccount;
        state.companyPartnerId = Yup.object()
          .shape({
            id: Yup.string(),
            partnerName: Yup.string(),
          })
          .nullable()
          .required(
            "Поле обязательно к заполнению"
          );
        return state;
      } else {
        return validationSchemaConstant;
      }
    });
  };
  function handleClose(success = false) {
    setDistrictsIdArray([]);
    setDistrictsState([]);
    setInitialValues(initialValuesConstant);
    setValidationSchema(validationSchemaConstant);
    onCloseModal(success);
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div
            className="flex_box sticky_header"
            style={{
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Typography variant="h6">
              {updateMode
                ? `Профиль агентa: ${currentAgent.name}`
                : "Новый агент"}
            </Typography>
            <IconButton onClick={handleClose}>
              <img
                src={crossIcon}
                alt="cross"
              />
            </IconButton>
          </div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={
              initialValues.isCompanyEmployee
            }
            onChange={changeAgentType}
            row
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Наемный работник"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Сотрудник"
            />
          </RadioGroup>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(
              validationSchema
            )}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik) => {
              return (
                <Form
                  onSubmit={formik.handleSubmit}
                >
                  <div
                    className="flex_box"
                    style={{
                      flexWrap: "wrap",
                      gap: "0px 20px",
                      justifyContent:
                        "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                        minHeight: 111,
                      }}
                    >
                      <ValidatedFormInput
                        name="name"
                        label="Имя"
                        disabled={moderation}
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                        minHeight: 111,
                      }}
                    >
                      <ValidatedFormInput
                        name="surname"
                        label="Фамилия"
                        disabled={moderation}
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                        minHeight: 111,
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.label}
                        style={{ marginTop: 24 }}
                      >
                        Дата рождения
                      </Typography>
                      <ThemeDatePicker
                        value={
                          formik.values[
                            "dateOfBirth"
                          ]
                        }
                        onChange={(date) => {
                          formik.handleChange({
                            target: {
                              value: date,
                              name: "dateOfBirth",
                            },
                          });
                          setOlderThanSixteen(
                            moment().diff(
                              moment(
                                new Date(date)
                              ),
                              "years"
                            ) >= 16
                          );
                        }}
                        variant="outlined"
                        placeholder=""
                        disabled={moderation}
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                        minHeight: 111,
                      }}
                    >
                      <ValidatedFormInput
                        name="phone"
                        label="Номер телефона"
                        disabled={moderation}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        minHeight: 111,
                      }}
                    >
                      <ValidatedFormInput
                        name="email"
                        label="Email"
                        disabled={moderation}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        minHeight: 111,
                      }}
                    >
                      <ValidatedFormInput
                        name="address"
                        label="Адрес"
                        disabled={moderation}
                      />
                    </div>
                    {initialValues.isCompanyEmployee ? (
                      <>
                        <PartnerAutocomplete
                          label="Организация"
                          name="companyPartnerId"
                          // value={
                          //   formik.values[
                          //     "companyPartnerId"
                          //   ]
                          // }
                          value={partners[currentAgent?.companyPartnerId]}
                          updateValue={(
                            partner
                          ) => {
                            formik.handleChange({
                              target: {
                                name: "companyPartnerId",
                                value: partner,
                              },
                            });
                          }}
                          validated
                        />
                        <Button
                          variant="add"
                          style={{
                            width: 175,
                            marginTop: 30,
                          }}
                          onClick={() => {
                            setOpenDistrictSelect(
                              true
                            );
                          }}
                          startIcon={
                            <img
                              src={plusBlueIcon}
                              alt="btn plus icon"
                            />
                          }
                        >
                          Добавить район
                        </Button>
                        <div
                          className="flex_box"
                          style={{
                            flexWrap: "wrap",
                            justifyContent:
                              "flex-start",
                            alignItems: "stretch",
                            marginTop: 16,
                          }}
                        >
                          {Boolean(
                            districtsIdArray.length
                          ) &&
                            districtsState
                              .flat()
                              .map((district) => {
                                return (
                                  districtsIdArray.indexOf(
                                    district.id
                                  ) !== -1 && (
                                    <div
                                      className="sale_point_card"
                                      style={{
                                        width:
                                          "calc(50% - 16px)",
                                        alignItems:
                                          "center",
                                      }}
                                      key={
                                        district.id
                                      }
                                      onClick={() => {
                                        setOpenDistrictSelect(
                                          true
                                        );
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginRight: 10,
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          style={{
                                            lineHeight:
                                              "19px",
                                          }}
                                        >
                                          {
                                            district.districtName
                                          }
                                        </Typography>
                                      </span>
                                      <IconButton
                                        onClick={(
                                          event
                                        ) => {
                                          event.stopPropagation();
                                          setDistrictsState(
                                            (
                                              state
                                            ) => {
                                              let newState =
                                                state
                                                  .flat()
                                                  .filter(
                                                    (
                                                      item
                                                    ) =>
                                                      item.id !==
                                                      district.id
                                                  );
                                              let formattedArray =
                                                [];
                                              newState.forEach(
                                                (
                                                  district
                                                ) => {
                                                  let lastRaw =
                                                    formattedArray[
                                                      formattedArray.length -
                                                        1
                                                    ];
                                                  if (
                                                    typeof lastRaw ===
                                                      "undefined" ||
                                                    lastRaw.length ===
                                                      3
                                                  ) {
                                                    formattedArray.push(
                                                      [
                                                        district,
                                                      ]
                                                    );
                                                  } else {
                                                    lastRaw.push(
                                                      district
                                                    );
                                                  }
                                                }
                                              );
                                              return formattedArray;
                                            }
                                          );
                                          setDistrictsIdArray(
                                            (
                                              state
                                            ) =>
                                              state.filter(
                                                (
                                                  id
                                                ) =>
                                                  id !==
                                                  district.id
                                              )
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            crossBlackIcon
                                          }
                                          alt=""
                                        />
                                      </IconButton>
                                    </div>
                                  )
                                );
                              })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width:
                              "calc(50% - 10px)",
                            minHeight: 111,
                          }}
                        >
                          <ValidatedFormInput
                            name="bank"
                            label="Банк"
                            disabled={moderation}
                          />
                        </div>
                        <div
                          style={{
                            width:
                              "calc(50% - 10px)",
                            minHeight: 111,
                          }}
                        >
                          <ValidatedFormInput
                            name="paymentAccount"
                            label="Расчетный счет"
                            disabled={moderation}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="flex_box"
                    style={{
                      gap: 20,
                      justifyContent:
                        "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.label}
                        style={{ marginTop: 24 }}
                      >
                        Фотография лица
                      </Typography>
                      <div
                        className="imageUploader flex_box"
                        style={{
                          width: "100%",
                          cursor: !moderation
                            ? "pointer"
                            : "default",
                        }}
                        onClick={() => {
                          if (!moderation) {
                            setCurrentPhoto(
                              "userPhotoId"
                            );
                            fileUploader.current.click();
                          } else {
                            setViewer({
                              open: true,
                              image:
                                previewPhotos[
                                  "userPhotoId"
                                ],
                            });
                          }
                        }}
                      >
                        {previewPhotos[
                          "userPhotoId"
                        ] ? (
                          <img
                            src={
                              previewPhotos[
                                "userPhotoId"
                              ]
                            }
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                              objectFit:
                                "contain",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              margin: 11,
                            }}
                          >
                            <img
                              src={cameraIcon}
                              alt="camera icon"
                            />
                            <Typography
                              className={
                                classes.imagePlaceholder
                              }
                            >
                              Нажмите, чтобы
                              добавить фотографию
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Показываем свидетельство если нет паспорта */}
                  {olderThanSixteen ? (
                    <>
                      <Typography
                        variant="body2"
                        className={classes.label}
                        style={{ marginTop: 24 }}
                      >
                        Фотография паспорта
                      </Typography>
                      <div
                        className="flex_box"
                        style={{ gap: 20 }}
                      >
                        <div
                          className="imageUploader flex_box"
                          style={{
                            cursor: !moderation
                              ? "pointer"
                              : "default",
                          }}
                          onClick={() => {
                            if (!moderation) {
                              setCurrentPhoto(
                                "passportFrontId"
                              );
                              fileUploader.current.click();
                            } else {
                              setViewer({
                                open: true,
                                image:
                                  previewPhotos[
                                    "passportFrontId"
                                  ],
                              });
                            }
                          }}
                        >
                          {previewPhotos[
                            "passportFrontId"
                          ] ? (
                            <img
                              src={
                                previewPhotos[
                                  "passportFrontId"
                                ]
                              }
                              alt=""
                              width="100%"
                              height="100%"
                              style={{
                                objectFit:
                                  "contain",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                textAlign:
                                  "center",
                                margin: 11,
                              }}
                            >
                              <img
                                src={cameraIcon}
                                alt="camera icon"
                              />
                              <Typography
                                className={
                                  classes.imagePlaceholder
                                }
                              >
                                Нажмите, чтобы
                                добавить лицевую
                                сторону паспорта
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div
                          className="imageUploader flex_box"
                          style={{
                            cursor: !moderation
                              ? "pointer"
                              : "default",
                          }}
                          onClick={() => {
                            if (!moderation) {
                              setCurrentPhoto(
                                "passportBackId"
                              );
                              fileUploader.current.click();
                            } else {
                              setViewer({
                                open: true,
                                image:
                                  previewPhotos[
                                    "passportBackId"
                                  ],
                              });
                            }
                          }}
                        >
                          {previewPhotos[
                            "passportBackId"
                          ] ? (
                            <img
                              src={
                                previewPhotos[
                                  "passportBackId"
                                ]
                              }
                              alt=""
                              width="100%"
                              height="100%"
                              style={{
                                objectFit:
                                  "contain",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                textAlign:
                                  "center",
                                margin: 11,
                              }}
                            >
                              <img
                                src={cameraIcon}
                                alt="camera icon"
                              />
                              <Typography
                                className={
                                  classes.imagePlaceholder
                                }
                              >
                                Нажмите, чтобы
                                добавить оборотную
                                сторону паспорта
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "calc(50% - 10px)",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.label}
                        style={{ marginTop: 24 }}
                      >
                        Свидетельство о рождении
                      </Typography>
                      <div
                        className="imageUploader flex_box"
                        style={{
                          width: "100%",
                          cursor: !moderation
                            ? "pointer"
                            : "default",
                        }}
                        onClick={() => {
                          if (!moderation) {
                            setCurrentPhoto(
                              "birthCertificateId"
                            );
                            fileUploader.current.click();
                          } else {
                            setViewer({
                              open: true,
                              image:
                                previewPhotos[
                                  "birthCertificateId"
                                ],
                            });
                          }
                        }}
                      >
                        {previewPhotos[
                          "birthCertificateId"
                        ] ? (
                          <img
                            src={
                              previewPhotos[
                                "birthCertificateId"
                              ]
                            }
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                              objectFit:
                                "contain",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              margin: 11,
                            }}
                          >
                            <img
                              src={cameraIcon}
                              alt="camera icon"
                            />
                            <Typography
                              className={
                                classes.imagePlaceholder
                              }
                            >
                              Нажмите, чтобы
                              добавить
                              свидетельство о
                              рождении
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="flex_box"
                    style={{
                      gap: 16,
                      marginTop: 32,
                    }}
                  >
                    {moderation ? (
                      <ThemeButton
                        onClick={
                          approveRegistrationHandler
                        }
                        style={{
                          width: 150,
                          marginLeft: 16,
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={20}
                            style={{
                              color: "#FFFFFF",
                            }}
                          />
                        ) : (
                          "Одобрить"
                        )}
                      </ThemeButton>
                    ) : (
                      <ThemeButton
                        type="submit"
                        style={{
                          width: 150,
                          marginLeft: 16,
                        }}
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={20}
                            style={{
                              color: "#FFFFFF",
                            }}
                          />
                        ) : Boolean(
                            initialValues.id
                          ) ? (
                          "Сохранить"
                        ) : (
                          "Добавить"
                        )}
                      </ThemeButton>
                    )}
                    <ThemeButton
                      type="button"
                      variant={
                        moderation
                          ? "delete"
                          : "outlined"
                      }
                      style={{ width: 150 }}
                      onClick={() => {
                        if (moderation) {
                          setCommentModal(true);
                        } else {
                          handleClose();
                        }
                      }}
                    >
                      {moderation
                        ? "Отказать"
                        : "Отмена"}
                    </ThemeButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <input
            ref={fileUploader}
            onChange={(event) => {
              if (event.target.files.length) {
                filesUploadHandler(
                  event.target.files[0]
                );
              }
              event.target.value = "";
            }}
            type="file"
            hidden
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={commentModal}
        onClose={closeCommentModal}
      >
        <DialogContent>
          <div
            className="flex_box sticky_header"
            style={{
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Typography variant="h6">
              Комментарий отказа
            </Typography>
            <IconButton
              onClick={closeCommentModal}
            >
              <img
                src={crossIcon}
                alt="cross"
              />
            </IconButton>
          </div>
          <Formik
            initialValues={{
              moderationComment: "",
            }}
            validationSchema={Yup.object({
              moderationComment:
                Yup.string().required(
                  "Поле обязательно к заполнению"
                ),
            })}
            onSubmit={refuseRegistrationHandler}
          >
            {(formik) => (
              <Form
                onSubmit={formik.handleSubmit}
              >
                <ValidatedFormInput
                  name="moderationComment"
                  label="Комментарий"
                  multiline
                  rows={4}
                />
                <div
                  className="flex_box"
                  style={{
                    gap: 16,
                    marginTop: 32,
                  }}
                >
                  <ThemeButton
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    disabled={isLoading}
                  >
                    Потвердить
                  </ThemeButton>
                  <ThemeButton
                    type="button"
                    variant="outlined"
                    style={{ width: 150 }}
                    onClick={closeCommentModal}
                  >
                    Отмена
                  </ThemeButton>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewer.open}
        onClose={closeViewerModal}
        classes={{ paper: classes.paper }}
        scroll="body"
      >
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <img
            src={viewer.image}
            alt=""
            width="100%"
            height="100%"
          />
          <IconButton
            classes={{ root: classes.closeIcon }}
            onClick={closeViewerModal}
          >
            <img
              src={crossWhiteIcon}
              alt=""
            />
          </IconButton>
        </DialogContent>
      </Dialog>
      <DistrictSelectModal
        open={openDistrictSelect}
        onClose={() =>
          setOpenDistrictSelect(false)
        }
        districts={districtsState}
        setDistricts={setDistrictsState}
        arrayId={districtsIdArray}
        setArrayId={setDistrictsIdArray}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
  imagePlaceholder: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#757575",
    lineHeight: "15px",
    textAlign: "center",
  },
  paper: {
    width: "80%",
    maxWidth: "80%",
  },
  dialogContent: {
    padding: "45px !important",
    backgroundColor: "#000000",
    position: "relative",
  },
  closeIcon: {
    position: "absolute !important",
    top: 10,
    right: 10,
  },
  menuPaper: {
    width: "50%",
    maxHeight: 400,
    overflow: "auto",
    borderRadius: 4,
    padding: "0px 32px 32px",
    boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.15)",
  },
}));
