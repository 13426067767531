import moment from "moment";
import cookie_js from "cookie_js";
import { createStoreData } from "store";
import { appAxios, makeRequest } from "api";
import { updateStoreData } from "store";

export const login =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/auth",
      dispatch,
      "post",
      data
    ).then((json) => {
      if (json) {
        appAxios.defaults.headers[
          "Authorization"
        ] = json.token;
        cookie_js.set(
          "merchandising_token",
          json.token,
          {
            expires: new Date(
              moment()
                .add(1, "day")
                .toLocaleString()
            ).toUTCString(),
            path: "/",
          }
        );
        dispatch(
          createStoreData({
            user: json,
            token: json.token,
          })
        );
      }
      callback(json?.role);
    });
  };

export const createAgent =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/agent",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };
// этот ендпоинт сделан чисто для приложения, требуется модерация после регистрации
export const createAgentMobile =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/agent/Registration",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createPartner =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/partner",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createOutlet =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/salePoint",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createSku =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/sku",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createApplication =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/application",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createCountry =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/country",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createCity =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/city",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createDistrict =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/district",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createSkuGroup =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/sku/SkuGroup",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createReferenceValue =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/References",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const createSkuMsl =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Sku/CreateSkuMsl",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(Boolean(json?.id));
    });
  };

export const uploadFile =
  (data, callback) => (dispatch) => {
    return makeRequest(
      "/file",
      dispatch,
      "post",
      data
    ).then((json) => {
      callback(
        Boolean(json.id) ? json.id : undefined
      );
    });
  };

export const getReportsByApplication =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByApplications",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByApplication: json
            ? json.rootItems
            : [],
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportsBySector =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportBySector",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsBySector: json
            ? json.rootItems
            : [],
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportByGroupSku =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByGroupSku",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByGroupSku: json
            ? json.rootItems
            : [],
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportByShelfShares =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByShelfShares",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByShelfShares: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsByShelfShares: json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportByShelfSharesSalePoint =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByShelfSharesSalePoint",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByShelfSharesSalePoint: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsByShelfSharesSalePoint:
              json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportByShelfSharesPartners =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByShelfSharesPartners",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByShelfSharesPartners: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsByShelfSharesPartners:
              json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportBySegmentBrands =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportBySegmentBrands",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsBySegmentBrands: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsBySegmentBrands: json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportBySegmentTypeOfPackaging =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportBySegmentTypeOfPackaging",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsBySegmentTypeOfPackaging: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsBySegmentTypeOfPackaging:
              json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportBySegmentPackagingFormat =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportBySegmentPackagingFormat",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsBySegmentPackagingFormat: json
            ? json.rootItems
            : [],
        })
      );
      dispatch(
        updateStoreData({
          key: "reportsTotal",
          data: {
            reportsBySegmentPackagingFormat:
              json.total,
          },
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };

export const getReportByMSL =
  (data, callback) => (dispatch) => {
    makeRequest(
      "/Report/GetReportByMSL",
      dispatch,
      "post",
      data
    ).then((json) => {
      dispatch(
        createStoreData({
          reportsByMSL: json
            ? json.rootItems
            : [],
        })
      );
      callback(
        Boolean(json && json.rootItems?.length)
      );
    });
  };
