import { styled, TextField } from "@mui/material";

export const FormInput = styled(TextField)(
  ({ theme }) => ({
    backgroundColor: "#FFFFFF",
    borderRadius: 3,
    "& .MuiInputLabel-root": {
      top: -8,
    },
    "& .MuiInputLabel-root.MuiFormLabel-filled": {
      top: 0,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      top: 0,
    },
    "& .MuiSelect-select": {
      padding: 0,
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root": {
      height: "auto",
      minHeight: 40,
      padding: 10,
      "& input": {
        padding: "0px !important",
        fontWeight: 400,
      },
      "& input.Mui-disabled": {
        WebkitTextFillColor: "#333",
      },
      "& fieldset": {
        borderRadius: 3,
        borderColor: "#CCCCCC",
      },
      "& input::placeholder": {
        color: "#AAAAAA",
        fontWeight: 500,
        opacity: 1,
      },
      "& .MuiChip-root": {
        height: "100%",
        "& .MuiSvgIcon-root": {
          fontSize: 18,
        },
      },
    },
  })
);
