import React, { useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import crossIcon from "assets/icons/Cross.svg";
import {
  ValidatedFormInput,
  ThemeButton,
} from "shared";
import { createStoreData } from "store";
import {
  createPartner,
  updatePartner,
} from "api";

const initialValuesConstant = {
  name: "",
  email: "",
  phone: "",
  surname: "",
  address: "",
  password: "",
  partnerName: "",
};
const validationSchemaConstant = {
  name: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  email: Yup.string()
    .email("Введенный email не корректный")
    .required("Поле обязательно к заполнению"),
  surname: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  address: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  phone: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  password: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
  partnerName: Yup.string().required(
    "Поле обязательно к заполнению"
  ),
};

export default function PartnerModal({
  open,
  onCloseModal,
  currentPartner,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState(initialValuesConstant);

  React.useEffect(() => {
    if (
      Boolean(Object.keys(currentPartner).length)
    ) {
      setInitialValues(currentPartner);
    }
  }, [currentPartner]);

  const handleSubmit = (values) => {
    setLoading(true);
    if (Boolean(values.id)) {
      dispatch(
        updatePartner(values, (success) => {
          if (success) {
            setInitialValues(
              initialValuesConstant
            );
            onCloseModal(true);
          }
          setLoading(false);
        })
      );
    } else {
      dispatch(
        createPartner(values, (success) => {
          if (success) {
            dispatch(
              createStoreData({
                alert: {
                  severity: "success",
                  message:
                    "Новый партнер успешно создан!",
                },
                // Обновление всего списка партнеров
                partnersList: {},
              })
            );
            onCloseModal();
          }
          setLoading(false);
        })
      );
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
    >
      <DialogContent>
        <div
          className="flex_box sticky_header"
          style={{
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Typography variant="h6">
            Новый партнер
          </Typography>
          <IconButton onClick={onCloseModal}>
            <img
              src={crossIcon}
              alt="cross"
            />
          </IconButton>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(
            validationSchemaConstant
          )}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <ValidatedFormInput
                name="partnerName"
                label="Наименование партнера"
              />
              <ValidatedFormInput
                name="name"
                label="Имя представителя"
              />
              <ValidatedFormInput
                name="surname"
                label="Фамилия представителя"
              />
              <ValidatedFormInput
                name="phone"
                label="Номер телефона"
              />
              <ValidatedFormInput
                name="email"
                label="Email"
                autoComplete="new-password"
              />
              <ValidatedFormInput
                name="address"
                label="Адрес"
              />
              <ValidatedFormInput
                name="password"
                label="Пароль"
                type="password"
                autoComplete="new-password"
              />
              <div
                className="flex_box"
                style={{ gap: 16, marginTop: 32 }}
              >
                <ThemeButton
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={20}
                      style={{ color: "#FFFFFF" }}
                    />
                  ) : Boolean(
                      initialValues.id
                    ) ? (
                    "Сохранить"
                  ) : (
                    "Добавить"
                  )}
                </ThemeButton>
                <ThemeButton
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={onCloseModal}
                >
                  Отмена
                </ThemeButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: "32px !important",
    marginBottom: "10px !important",
    fontWeight: "600 !important",
  },
}));
