import React, { useState } from "react";
import {
  ThemeProvider,
  CssBaseline,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import "react-infinite-calendar/styles.css"; // only needs to be imported once
import "react-virtualized/styles.css";
import moment from "moment";

import "./App.css";
import Admin from "pages/Admin";
import { getUserInfo } from "api";
import CaUSku from "pages/CaUSku";
import { appTheme } from "./theme";
import Promp from "components/Promp";
import { createStoreData } from "store";
import { moment_ru } from "localization";
import CaUApplication from "pages/CaUApplication";
import AuthorizationPage from "pages/AuthorizationPage";
import ApplicationModeration from "pages/ApplicationModeration";

const CheckIsAuthorized = ({ children }) => {
  const { token } = useSelector((store) => store);

  return !!token ? children : <Navigate to="/" />;
};

function App() {
  const dispatch = useDispatch();
  const { alert, token, user } = useSelector(
    (store) => store
  );
  const [isAlertOpen, setIsAlertOpen] =
    useState(false);

  React.useEffect(() => {
    if (
      token &&
      !Boolean(Object.keys(user).length)
    ) {
      dispatch(getUserInfo());
    }
  }, [dispatch, token, user]);
  React.useEffect(() => {
    if (Boolean(alert.message)) {
      setIsAlertOpen(true);
    }
  }, [alert]);
  React.useEffect(() => {
    moment.updateLocale("ru", moment_ru);
  }, []);

  const closeSnackbar = () => {
    setIsAlertOpen(false);
    setTimeout(() => {
      dispatch(() =>
        createStoreData({
          alert: {
            message: "",
            severity: "success",
          },
        })
      );
    }, 1000);
  };

  return (
    <div className="merchandising">
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Promp />
          <Snackbar
            open={isAlertOpen}
            autoHideDuration={2000}
            onClose={closeSnackbar}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ minWidth: 300 }}
          >
            <Alert
              variant="filled"
              severity={alert.severity}
              onClose={closeSnackbar}
            >
              {alert.message}
            </Alert>
          </Snackbar>

          <Routes>
            <Route
              path="/"
              element={
                token ? (
                  <Navigate
                    to={`/admin/${
                      user.role ===
                      process.env.REACT_APP_ADMIN
                        ? "agents"
                        : "directory"
                    }`}
                  />
                ) : (
                  <AuthorizationPage />
                )
              }
            />
            <Route
              path="/admin/*"
              element={
                <CheckIsAuthorized>
                  <Admin />
                </CheckIsAuthorized>
              }
            />
            <Route
              path="/applications/:applicationId"
              element={
                <CheckIsAuthorized>
                  <ApplicationModeration />
                </CheckIsAuthorized>
              }
            />
            <Route
              path="/sku"
              element={
                <CheckIsAuthorized>
                  <CaUSku />
                </CheckIsAuthorized>
              }
            />
            <Route
              path="/application"
              element={
                <CheckIsAuthorized>
                  <CaUApplication />
                </CheckIsAuthorized>
              }
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
