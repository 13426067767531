import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { ThemeButton } from "shared";

import { updateStoreData } from "store";
import crossIcon from "assets/icons/CrossBlack.svg";
import attentionIcon from "assets/icons/Attention.svg";
import { makeStyles } from "@mui/styles";

export default function Promp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    prompDialog: {
      open,
      confirm,
      reject,
      title,
      question,
      message,
      btnText,
      positive,
    },
  } = useSelector((store) => store);

  const onCloseModal = () => {
    dispatch(
      updateStoreData({
        key: "prompDialog",
        data: {
          open: false,
          btnText: "",
          message: "",
        },
      })
    );
  };
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogContent
        style={{
          padding: "0px 56px 32px 32px",
          position: "relative",
        }}
      >
        <div
          className="flex_box_between sticky_header"
          style={{ marginBottom: 32 }}
        >
          <div
            className="flex_box"
            style={{ width: "auto" }}
          >
            <img
              src={attentionIcon}
              alt=""
            />
            <Typography
              variant="h6"
              style={{ marginLeft: 12 }}
            >
              {title}
            </Typography>
          </div>
        </div>
        <IconButton
          onClick={onCloseModal}
          className={classes.closeBtn}
        >
          <img
            src={crossIcon}
            alt=""
          />
        </IconButton>
        {question && (
          <Typography
            variant="body1"
            style={{
              fontWeight: 400,
              marginBottom: 32,
            }}
          >
            {question}
          </Typography>
        )}
        {message && (
          <Typography
            variant="body1"
            style={{
              fontWeight: 400,
              color: "#757575",
              marginBottom: 32,
            }}
          >
            {message}
          </Typography>
        )}
        <div
          className="flex_box"
          style={{
            gap: 20,
            justifyContent: "flex-start",
          }}
        >
          <ThemeButton
            type="submit"
            variant="contained"
            className={
              classes[
                title === "Выход из аккаунта"
                  ? "positiveBtn"
                  : "negativeBtn"
              ]
            }
            style={{
              backgroundColor: positive
                ? "#50BF34"
                : "#FF0000",
            }}
            onClick={() => {
              confirm();
              onCloseModal();
            }}
          >
            {btnText || "Да, удалить"}
          </ThemeButton>
          <ThemeButton
            type="button"
            variant="outlined"
            onClick={() => {
              if (typeof reject === "function") {
                reject();
              }
              onCloseModal();
            }}
          >
            Отмена
          </ThemeButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  negativeBtn: {
    "&.MuiButton-root": {
      height: 40,
      color: "#FFFFFF",
      backgroundColor: "#FF0000",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#db0000",
    },
  },
  closeBtn: {
    "&.MuiIconButton-root": {
      position: "absolute",
      top: 12,
      right: 12,
      zIndex: 1000000,
    },
  },
  paper: {
    width: "auto !important",
  },
}));
