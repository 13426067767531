import React, { useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import cookie_js from "cookie_js";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  Agents,
  ViewAgent,
  Partners,
  Directory,
  Applications,
  Withdrawal,
  Reports,
} from "components/Admin";
import { createStoreData } from "store";
import Users from "assets/icons/Users.svg";
import BoxIcon from "assets/icons/Box.svg";
import appLogo from "assets/icons/Logo.svg";
import avatar from "assets/images/avatar.png";
import ChartPie from "assets/icons/Chart_Pie.svg";
import { initialState } from "store/initialState";
import Analytics from "components/Admin/Analytics";
import ShoppingCart from "assets/icons/ShoppingCart.svg";
import { ViewPartner } from "components/Admin/ViewPartner";
import NotificationIndicator from "assets/icons/NotificationIndicator.svg";
import PhotoReport from "assets/icons/foto_otchet_active.svg";

const partnerTabsConstant = [
  {
    name: "Справочники",
    icon: BoxIcon,
    path: "directory",
    element: <Directory />,
  },
  {
    name: "Заявки",
    icon: NotificationIndicator,
    path: "applications",
    element: <Applications />,
  },
  {
    name: "Фотоотчетность",
    icon: PhotoReport,
    path: "analytics",
    element: <Analytics />,
  },
  {
    name: "Отчеты",
    icon: ChartPie,
    path: "reports",
    element: <Reports />,
  },
];
const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration:
      theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create(
      "margin",
      {
        easing: theme.transitions.easing.easeOut,
        duration:
          theme.transitions.duration
            .enteringScreen,
      }
    ),
    marginLeft: 0,
  }),
  backgroundColor: "#F7FBFE",
  padding: "40px",
  minHeight: "100vh",
}));

export default function Admin() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);
  const [sidebarTabs, setSidebarTabs] = useState(
    partnerTabsConstant
  );
  let isAdmin =
    user?.role === process.env.REACT_APP_ADMIN;

  React.useEffect(() => {
    if (isAdmin && sidebarTabs.length === 4) {
      setSidebarTabs([
        {
          name: "Агенты",
          icon: Users,
          path: "agents",
          element: <Agents />,
        },
        {
          name: "Заявки агентов на вывод средств",
          icon: NotificationIndicator,
          path: "withdrawal",
          element: <Withdrawal />,
        },
        {
          name: "Партнеры",
          icon: ShoppingCart,
          path: "partners",
          element: <Partners />,
        },
        ...partnerTabsConstant,
      ]);
    }
    if (!isAdmin && sidebarTabs.length === 6) {
      setSidebarTabs(partnerTabsConstant);
    }
  }, [isAdmin, sidebarTabs]);

  const logout = () => {
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Выход из аккаунта",
          question:
            "Вы уверены что хотите выйти из своей учетной записи?",
          btnText: "Да, выйти",
          confirm: () => {
            cookie_js.removeSpecific(
              "merchandising_token",
              { path: "/" }
            );
            dispatch(
              createStoreData({
                ...initialState,
                token: undefined,
              })
            );
          },
        },
      })
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <img
          src={appLogo}
          //   onClick={() => toggleSidebar((open) => !open)}
          className="logo"
          alt="logo"
        />
        <List className={classes.List}>
          {sidebarTabs.map((tab) => {
            let tabPathname = `/${
                location.pathname.split("/")[1]
              }/${tab.path}`,
              isActive =
                tab.path ===
                location.pathname.split("/")[2];
            return (
              <ListItemButton
                key={tab.name}
                onClick={() =>
                  navigate(tabPathname, {
                    replace: false,
                  })
                }
                style={{
                  borderRadius: 6,
                  backgroundColor: isActive
                    ? "#E8EFF4"
                    : "transparent",
                }}
              >
                <ListItemIcon>
                  <img
                    className={
                      isActive
                        ? "filter_active"
                        : "filter_inactive"
                    }
                    src={tab.icon}
                    alt={tab.name}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary:
                      classes[
                        isActive
                          ? "textActive"
                          : "textInactive"
                      ],
                  }}
                >
                  {tab.name}
                </ListItemText>
                {/* {isApplications && (
                  <ListItemIcon>
                    <span className="notifications_count">3</span>
                  </ListItemIcon>
                )} */}
              </ListItemButton>
            );
          })}
        </List>
        <div
          className="flex_box"
          style={{ alignItems: "flex-end" }}
        >
          <div
            className="flex_box"
            style={{
              height: "auto",
              justifyContent: "flex-start",
              padding: 25,
            }}
          >
            <img
              src={avatar}
              width="32px"
              height="32px"
              alt="user avatar"
            />
            <span style={{ marginLeft: 12 }}>
              <Typography variant="body2">
                {user.name}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#034F94",
                  cursor: "pointer",
                }}
                onClick={logout}
              >
                Выйти
              </Typography>
            </span>
          </div>
        </div>
      </Drawer>

      <Main
        open={true}
        style={{
          backgroundColor: "transparent",
          maxWidth: "calc(100vw - 240px)",
        }}
      >
        <Routes>
          {sidebarTabs.map((tab) => (
            <Route
              path={tab.path}
              element={tab.element}
              key={tab.path}
            />
          ))}
          <Route
            path="agents/:id"
            element={<ViewAgent />}
          />
          <Route
            path="partners/:id"
            element={<ViewPartner />}
          />
        </Routes>
      </Main>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  List: {
    "& .MuiListItemButton-root": {
      padding: "12px 16px",
      margin: "3px 20px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 36,
    },
  },
  textActive: {
    color: "#034f94 !important",
    fontWeight: "bold !important",
    fontSize: "14px !important",
  },
  textInactive: {
    color: "#000000",
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
}));
