import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Container, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import CustomDataTable from "components/CustomDataTable";
import { ThemeButton } from "shared";

export default function ApplicationModeration() {
  const { currentApplication } = useSelector((store) => store);
  const [tableData, setTableData] = useState([]);

  React.useEffect(() => {
    if (Boolean(currentApplication.salePoints?.length)) {
      setTableData([
        [
          {
            name: "Магазин",
            props: {
              xs: 3,
            },
          },
          {
            name: "Товар",
            props: {
              xs: 6,
            },
          },
          {
            name: "Время",
            props: {
              xs: 3,
            },
          },
        ],
        ...currentApplication.salePoints.map((item) => {
          return [
            item.name,
            <div
              className="flex_box"
              style={{ justifyContent: "flex-start", gap: 6 }}>
              {currentApplication.skuList.map((item) => (
                <div className="tag" key={item.id}>
                  <Typography variant="caption">{item.name}</Typography>
                </div>
              ))}
            </div>,
            moment(currentApplication.dateOfExecution).format("DD MMMM yyyy"),
          ];
        }),
      ]);
    }
  }, [currentApplication]);

  const dataTableProps = {
    pageIndex: 0,
    setPageIndex: () => {},
    pageCount: 1,
    onEdit: () => {},
    onDelete: () => {},
    table: tableData,
  };
  return (
    <Container style={{ padding: "18px 0px", minHeight: "100vh" }}>
      <NavLink to="/admin/applications" style={{ color: "#034F94" }}>
        В главное меню
      </NavLink>
      <Typography variant="h3" style={{ marginTop: 20 }}>
        {currentApplication.partnerName}
      </Typography>
      <Typography
        variant="caption"
        component="p"
        style={{ color: "#757575", marginBottom: 40 }}>{`от ${moment(
        currentApplication.creationTime
      ).format("DD.MM.YYYY")}`}</Typography>
      <CustomDataTable {...dataTableProps} />
      <div className="flex_box">
        <div className="flex_box" style={{ width: 330 }}>
          <Button type="submit" variant="delete" onClick={() => {}}>
            Отклонить
          </Button>
          <ThemeButton
            type="button"
            onClick={() => {}}
            style={{ marginLeft: 20 }}>
            Принять в работу
          </ThemeButton>
        </div>
      </div>
    </Container>
  );
}
