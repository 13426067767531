import React, { useState } from "react";
import {
  createSkuMsl,
  deleteSkuMsl,
  getReferences,
  getSkuMslList,
} from "api";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import { NoResult } from "shared";
import plusBlueIcon from "assets/icons/PlusBlue.svg";
import crossBlackIcon from "assets/icons/CrossBlack.svg";
import SkuSelectModal from "./Dialogs/SkuSelectModal";
import { createStoreData } from "store";

export default function MSL({ currentPartner }) {
  const dispatch = useDispatch();
  const { user, msl, categories, category } =
    useSelector((store) => store);
  const [isLoading, setLoading] = useState(false);
  const [partnerMsl, setPartnerMsl] = useState(
    []
  );
  const [openSkuSelect, setSkuSelect] =
    useState(false);
  const [categoryId, setCategoryId] =
    useState(null);

  React.useEffect(() => {
    if (
      typeof msl[currentPartner.id] ===
        "undefined" &&
      !isLoading
    ) {
      setLoading(true);
      dispatch(getSkuMslList(currentPartner.id));
    }
    if (
      typeof msl[currentPartner.id] !==
        "undefined" &&
      msl[currentPartner.id].length
    ) {
      setPartnerMsl(
        msl[currentPartner.id].reduce(
          (calculator, mslItem) => {
            if (calculator[mslItem.categoryId]) {
              calculator[mslItem.categoryId].push(
                mslItem
              );
            } else {
              calculator[mslItem.categoryId] = [
                mslItem,
              ];
            }
            return calculator;
          },
          {}
        )
      );
    }
  }, [dispatch, currentPartner, msl, isLoading]);
  React.useEffect(() => {
    if (!categories.length) {
      dispatch(getReferences("Category"));
    }
  }, [dispatch, categories]);

  function addSkuMsl(skuId) {
    dispatch(
      createSkuMsl(
        { skuId, categoryId },
        (success) => {
          if (success) {
            dispatch(
              createStoreData({
                alert: {
                  severity: "success",
                  message: "Успешно",
                },
              })
            );
            dispatch(
              getSkuMslList(currentPartner.id)
            );
          }
        }
      )
    );
  }
  function deleteMsl(mslItem) {
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: `Удаление SKU из категории`,
          question: `Вы уверены, что хотите удалить SKU?`,
          confirm: () => {
            dispatch(
              deleteSkuMsl(
                mslItem.skuMslId,
                (success) => {
                  if (success) {
                    dispatch(
                      createStoreData({
                        alert: {
                          severity: "success",
                          message: "Успешно",
                        },
                      })
                    );
                    dispatch(
                      getSkuMslList(
                        currentPartner.id
                      )
                    );
                  }
                }
              )
            );
          },
        },
      })
    );
  }

  let isAdmin =
    user?.role === process.env.REACT_APP_ADMIN;
  return (
    <Paper
      elevation={0}
      style={{ padding: 32, marginTop: 40 }}
    >
      <Typography
        variant="h6"
        style={{ marginBottom: 20 }}
      >
        Категории торговых точек
      </Typography>
      {categories.length ? (
        categories.map(
          (item) =>
            category[item.referenceId]?.length &&
            category[item.referenceId].map(
              (childItem) => (
                <div
                  key={childItem.referenceId}
                  className="flex_box"
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 20,
                    marginTop: 12,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      width: "60px",
                      wordWrap: "break-word",
                      marginTop: 8,
                    }}
                  >
                    {childItem.value}
                  </Typography>
                  <div
                    style={{
                      width: "calc(100% - 80px)",
                    }}
                  >
                    <div
                      className="flex_box"
                      style={{
                        alignItems: "flex-start",
                        justifyContent:
                          "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: "#555555",
                          lineHeight: "40px",
                        }}
                      >{`Всего: ${
                        partnerMsl[
                          childItem.referenceId
                        ]?.length ?? 0
                      }`}</Typography>
                      {isAdmin && (
                        <Button
                          variant="add"
                          style={{
                            width: 175,
                            marginLeft: 30,
                          }}
                          onClick={() => {
                            setCategoryId(
                              childItem.referenceId
                            );
                            setSkuSelect(true);
                          }}
                          startIcon={
                            <img
                              src={plusBlueIcon}
                              alt="btn plus icon"
                            />
                          }
                        >
                          Добавить SKU
                        </Button>
                      )}
                    </div>
                    <div
                      className="flex_box"
                      style={{
                        flexWrap: "wrap",
                        justifyContent:
                          "flex-start",
                        alignItems: "stretch",
                        marginTop: 16,
                      }}
                    >
                      {Boolean(
                        partnerMsl[
                          childItem.referenceId
                        ]?.length
                      ) &&
                        partnerMsl[
                          childItem.referenceId
                        ].map((mslItem) => {
                          return (
                            <div
                              className="sale_point_card"
                              key={
                                mslItem.skuMslId
                              }
                              style={{
                                width: "30%",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  lineHeight:
                                    "19px",
                                  marginRight: 10,
                                }}
                              >
                                {mslItem.skuName}
                              </Typography>
                              {isAdmin && (
                                <IconButton
                                  onClick={() => {
                                    deleteMsl(
                                      mslItem
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      crossBlackIcon
                                    }
                                    alt=""
                                  />
                                </IconButton>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )
            )
        )
      ) : (
        <NoResult title="Данные не найдены" />
      )}
      <SkuSelectModal
        open={openSkuSelect}
        onClose={() => {
          setSkuSelect(false);
        }}
        onSelectSku={(sku) => {
          addSkuMsl(sku.skuId);
        }}
        currentPartner={currentPartner}
      />
    </Paper>
  );
}
