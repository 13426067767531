import React, { useState } from "react";
import {
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import {
  getCities,
  deleteCity,
  getSkuList,
  getCountries,
  getDistricts,
  getSkuGroupes,
  deleteCountry,
  deleteDistrict,
  getOutletsList,
  deleteSkuGroup,
  getReferences,
  deleteReferenceValue,
} from "api";
import CardSKU from "components/CardSKU";
import plusIcon from "assets/icons/Plus.svg";
import { ThemeButton } from "shared/Buttons";
import { HocMini, TabsComponent } from "shared";
import FlexibleList from "components/FlexibleList";
import DirectoryModals from "components/Dialogs/DirectoryModals";
// import SearchComponent from "components/SearchComponent";
import CustomDataTable from "components/CustomDataTable";
import OutletModal from "components/Dialogs/OutletModal";
import PageSizeSelector from "components/PageSizeSelector";
import {
  createStoreData,
  updateStoreData,
} from "store";
import { createStore } from "redux";
import SearchComponent from "components/SearchComponent";

const adminTabs = [
  "Группы товаров",
  "Товары",
  "Бренд",
  "Упаковка",
  "Формат упаковки",
  "Торговые точки",
  "Страны",
  "Города",
  "Районы",
];
const partnerTabs = ["Товары", "Торговые точки"];

export function Directory() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    totals,
    cities,
    brands,
    skuList,
    countries,
    districts,
    persisting,
    skuGroupes,
    outletsList,
    packagingFormats,
    typesOfPackaging,
    directorySearch,
  } = useSelector((store) => store);
  const [currentDirectory, setCurrentDirectory] =
    useState(undefined);
  const [directoryModal, setDirectoryModal] =
    useState({
      open: false,
      type: "",
    });
  const [openNewOutletModal, setNewOutletModal] =
    useState(false);
  const [currentOutlet, setCurrentOutlet] =
    useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [skuGrid, setSkuGrid] = useState([]);
  const [list, setList] = useState([]);
  let isAdmin =
      user?.role === process.env.REACT_APP_ADMIN,
    tabs = isAdmin ? adminTabs : partnerTabs,
    isSkuGroup =
      tabs[persisting.directoryTab] ===
      "Группы товаров",
    isSku =
      tabs[persisting.directoryTab] === "Товары",
    isBrand =
      tabs[persisting.directoryTab] === "Бренд",
    isTypeOfPackaging =
      tabs[persisting.directoryTab] ===
      "Упаковка",
    isPackagingFormat =
      tabs[persisting.directoryTab] ===
      "Формат упаковки",
    isOutlets =
      tabs[persisting.directoryTab] ===
      "Торговые точки",
    isCountry =
      tabs[persisting.directoryTab] === "Страны",
    isCity =
      tabs[persisting.directoryTab] === "Города",
    isDistrict =
      tabs[persisting.directoryTab] === "Районы",
    skuPageIndex = persisting["Товары"],
    outletsPageIndex =
      persisting["Торговые точки"];

  React.useEffect(() => {
    setLoading(true);
    if (isAdmin) {
      dispatch(getSkuGroupes());
    } else {
      dispatch(
        getSkuList(
          {
            pageIndex: 0,
            pageSize: 20,
          },
          {}
        )
      );
    }
  }, [dispatch, isAdmin]);
  React.useEffect(() => {
    if (isSkuGroup) {
      if (typeof skuGroupes === "undefined") {
        setLoading(true);
        dispatch(getSkuGroupes());
      }
      if (typeof skuGroupes !== "undefined") {
        setLoading(false);
        setList(skuGroupes);
      }
    }
    if (isSku) {
      if (
        typeof skuList[skuPageIndex] ===
        "undefined"
      ) {
        setLoading(true);
        dispatch(
          getSkuList(
            {
              pageIndex: skuPageIndex,
              pageSize: persisting.skuPageSize,
              searchText:
                directorySearch[
                  tabs[persisting.directoryTab]
                ],
            },
            isAdmin ? undefined : {}
          )
        );
      }
      if (
        typeof skuList[skuPageIndex] !==
          "undefined" &&
        isSku
      ) {
        setLoading(false);
        setSkuGrid(skuList[skuPageIndex]);
      }
    }
    if (isBrand) {
      if (brands === null) {
        setLoading(true);
        dispatch(getReferences("Brand"));
      }
      if (Array.isArray(brands)) {
        setLoading(false);
        setList(
          brands.map((item) => ({
            id: item.referenceId,
            value: item.value,
          }))
        );
      }
    }
    if (isTypeOfPackaging) {
      if (typesOfPackaging === null) {
        setLoading(true);
        dispatch(
          getReferences("TypeOfPackaging")
        );
      }
      if (Array.isArray(typesOfPackaging)) {
        setLoading(false);
        setList(
          typesOfPackaging.map((item) => ({
            id: item.referenceId,
            value: item.value,
          }))
        );
      }
    }
    if (isPackagingFormat) {
      if (packagingFormats === null) {
        setLoading(true);
        dispatch(
          getReferences("PackagingFormat")
        );
      }
      if (Array.isArray(packagingFormats)) {
        setLoading(false);
        setList(
          packagingFormats.map((item) => ({
            id: item.referenceId,
            value: item.value,
          }))
        );
      }
    }
    if (isOutlets) {
      if (
        typeof outletsList[outletsPageIndex] ===
        "undefined"
      ) {
        setLoading(true);
        dispatch(
          getOutletsList({
            pageIndex: outletsPageIndex,
            pageSize: persisting.outletPageSize,
            searchText:
              directorySearch[
                tabs[persisting.directoryTab]
              ],
          })
        );
      }
      if (
        typeof outletsList[outletsPageIndex] !==
        "undefined"
      ) {
        setTableData([
          [
            {
              name: "Торговая точка",
              props: {
                xs: 3,
              },
            },
            {
              name: "Адрес",
              props: {
                xs: 5,
              },
            },
            {
              name: "Контактный номер",
              props: {
                xs: 2,
              },
            },
            {
              name: "Представитель",
              props: {
                xs: 2,
              },
            },
          ],
          ...outletsList[outletsPageIndex].map(
            (outlet) => [
              outlet.name,
              outlet.address,
              outlet.phone ? outlet.phone : "",
              outlet.contactPerson
                ? outlet.contactPerson
                : "",
            ]
          ),
        ]);
        setLoading(false);
      }
    }
    if (countries === null) {
      setLoading(true);
      dispatch(getCountries());
    }
    if (cities === null) {
      setLoading(true);
      dispatch(getCities());
    }
    if (isCountry) {
      if (Array.isArray(countries)) {
        setLoading(false);
        setList(countries);
      }
    }
    if (isCity) {
      if (Array.isArray(cities)) {
        setLoading(false);
        setList(cities);
      }
    }
    if (isDistrict) {
      if (districts === null) {
        setLoading(true);
        dispatch(getDistricts());
      }
      if (Array.isArray(districts)) {
        setLoading(false);
        setList(districts);
      }
    }
  }, [
    tabs,
    isSku,
    cities,
    isCity,
    isBrand,
    brands,
    isAdmin,
    skuList,
    dispatch,
    countries,
    districts,
    isLoading,
    isOutlets,
    isCountry,
    isDistrict,
    persisting,
    skuGroupes,
    isSkuGroup,
    outletsList,
    skuPageIndex,
    directorySearch,
    typesOfPackaging,
    packagingFormats,
    outletsPageIndex,
    isTypeOfPackaging,
    isPackagingFormat,
    persisting.directoryTab,
  ]);

  const editHandler = (type) => (id) => {
    setCurrentDirectory(
      list.filter((item) =>
        isSkuGroup
          ? item.skuGroupId === id
          : item.id === id
      )[0]
    );
    setDirectoryModal({ open: true, type });
  };
  const deleteHandler = (type) => (item) => {
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: `Удаление ${
            isCountry
              ? "страны"
              : isCity
              ? "города"
              : isDistrict
              ? "района"
              : isSkuGroup
              ? "группы"
              : isBrand
              ? "бренда"
              : isTypeOfPackaging
              ? "типа упаковки"
              : isPackagingFormat
              ? "формата упаковки"
              : '"Type not found"'
          }`,
          question: `Вы уверены, что хотите удалить ${
            isCountry
              ? `страну ${item.countryName}`
              : isCity
              ? `город ${item.cityName}`
              : isDistrict
              ? `район ${item.districtName}`
              : isSkuGroup
              ? `группу товаров ${item.groupName}`
              : isBrand
              ? "бренд"
              : isTypeOfPackaging
              ? "тип упаковки"
              : isPackagingFormat
              ? "формат упаковки"
              : '"Type not found"'
          }?`,
          message: isCountry
            ? "Все города страны и районы относящиеся к этим городам будут полностью удалены из системы"
            : isCity
            ? "Все районы города будут полностью удалены из системы"
            : "",
          confirm: () =>
            dispatch(
              isCountry
                ? deleteCountry(
                    item.id,
                    (success) => {
                      if (success) {
                        cities.filter(city => city.countryId === item.id).map(city => {
                          return dispatch(
                            deleteCity(
                              city.id,
                              (success) => {
                                if (success) {
                                  districts.filter(district => district.cityId === city.id).map(district => {
                                    return dispatch(
                                      deleteDistrict(
                                        district.id,
                                        (success) => {
                                          if (success) {
                                            dispatch(
                                              createStoreData({
                                                districts: null,
                                              })
                                            );
                                            setCurrentDirectory(
                                              undefined
                                            );
                                          }
                                        }
                                      )
                                    )
                                  })
                                }
                              }
                            )
                          )
                        })
                        dispatch(
                          createStoreData({
                            countries: null,
                          })
                        );
                        setCurrentDirectory(
                          undefined
                        );
                        
                      }
                    }
                  )
                : isCity
                ? deleteCity(
                    item.id,
                    (success) => {
                      if (success) {
                        districts.filter(district => district.cityId === item.id).map(district => {
                          return dispatch(
                            deleteDistrict(
                              district.id,
                              (success) => {
                                if (success) {
                                  dispatch(
                                    createStoreData({
                                      districts: null,
                                    })
                                  );
                                  setCurrentDirectory(
                                    undefined
                                  );
                                }
                              }
                            )
                          )
                        })
                        dispatch(
                          createStoreData({
                            cities: null,
                          })
                        );
                        setCurrentDirectory(
                          undefined
                        );
                      }
                    }
                  )
                : isDistrict
                ? deleteDistrict(
                    item.id,
                    (success) => {
                      if (success) {
                        dispatch(
                          createStoreData({
                            districts: null,
                          })
                        );
                        setCurrentDirectory(
                          undefined
                        );
                      }
                    }
                  )
                : isSkuGroup
                ? deleteSkuGroup(
                    item.skuGroupId,
                    (success) => {
                      if (success) {
                        dispatch(
                          createStoreData({
                            skuGroupes: null,
                          })
                        );
                      }
                    }
                  )
                : isBrand ||
                  isTypeOfPackaging ||
                  isPackagingFormat
                ? deleteReferenceValue(
                    item.id,
                    (success) => {
                      if (success) {
                        dispatch(
                          getReferences(
                            isBrand
                              ? "Brand"
                              : isTypeOfPackaging
                              ? "TypeOfPackaging"
                              : "PackagingFormat"
                          )
                        );
                      }
                    }
                  )
                : createStore({
                    alert: {
                      severity: "error",
                      message:
                        "Тип справочника для удаления не найден",
                    },
                  })
            ),
        },
      })
    );
  };
  const onSearch = (value) => {
    setLoading(true);
    if (isSkuGroup) {
      dispatch(
        getSkuGroupes({ searchText: value })
      );
    } else if (isSku) {
      setPersisting({ Товары: 0 });
      dispatch(
        getSkuList(
          {
            pageIndex: 0,
            pageSize: persisting.skuPageSize,
          },
          { searchText: value }
        )
      );
    } else if (isBrand) {
      dispatch(
        getReferences("Brand", {
          searchText: value,
        })
      );
    } else if (isTypeOfPackaging) {
      dispatch(
        getReferences("TypeOfPackaging", {
          searchText: value,
        })
      );
    } else if (isPackagingFormat) {
      dispatch(
        getReferences("PackagingFormat", {
          searchText: value,
        })
      );
    } else if (isOutlets) {
      setPersisting({ "Торговые точки": 0 });
      dispatch(
        getOutletsList({
          pageIndex: 0,
          pageSize: persisting.outletPageSize,
          searchText: value,
        })
      );
    } else if (isCountry) {
      dispatch(
        getCountries({ searchText: value })
      );
    } else if (isCity) {
      dispatch(getCities({ searchText: value }));
    } else if (isDistrict) {
      dispatch(
        getDistricts({ searchText: value })
      );
    } else {
      setLoading(false);
      return;
    }
  };
  const changePageSize = (size) => {
    setLoading(true);
    dispatch(
      updateStoreData({
        key: "directoryPageIndex",
        data: {
          [isSku
            ? "Товары"
            : "Торговые точки"]: 0,
        },
      })
    );
    if (isSku) {
      setPersisting({ skuPageSize: size });
      dispatch(
        getSkuList({
          pageIndex: 0,
          pageSize: size,
        })
      );
    }
    if (isOutlets) {
      setPersisting({ outletPageSize: size });
      dispatch(
        getOutletsList({
          pageIndex: 0,
          pageSize: size,
        })
      );
    }
  };
  const dataTableProps = {
    pageSize: persisting.outletPageSize,
    changePageSize,
    pageIndex: outletsPageIndex,
    setPageIndex: (page) => {
      setPersisting({ "Торговые точки": page });
    },
    pageCount: totals.outlets?.totalPages || 1,
    onView: (index) => {
      let outlet =
        outletsList[outletsPageIndex][index];
      setCurrentOutlet(outlet);
      setNewOutletModal(true);
    },
    table: tableData,
  };
  function setPersisting(data) {
    dispatch(
      updateStoreData({
        key: "persisting",
        data,
      })
    );
  }
  function onTabChange(tab) {
    setPersisting({ directoryTab: tab });
    switch (tabs[tab]) {
      case "Группы товаров": {
        setLoading(true);
        dispatch(getSkuGroupes());
        break;
      }
      case "Товары": {
        setLoading(true);
        dispatch(
          getSkuList(
            {
              pageIndex: skuPageIndex,
              pageSize: persisting.skuPageSize,
              searchText:
                directorySearch[
                  tabs[persisting.directoryTab]
                ],
            },
            isAdmin ? undefined : {}
          )
        );
        break;
      }
      case "Бренд": {
        setLoading(true);
        dispatch(getReferences("Brand"));
        break;
      }
      case "Упаковка": {
        setLoading(true);
        dispatch(
          getReferences("TypeOfPackaging")
        );
        break;
      }
      case "Формат упаковки": {
        setLoading(true);
        dispatch(
          getReferences("PackagingFormat")
        );
        break;
      }
      case "Торговые точки": {
        setLoading(true);
        dispatch(
          getOutletsList({
            pageIndex: outletsPageIndex,
            pageSize: persisting.outletPageSize,
            searchText:
              directorySearch[
                tabs[persisting.directoryTab]
              ],
          })
        );
        break;
      }
      case "Страны": {
        setLoading(true);
        dispatch(getCountries());
        break;
      }
      case "Города": {
        setLoading(true);
        dispatch(getCities());
        break;
      }
      case "Районы": {
        setLoading(true);
        dispatch(getDistricts());
        break;
      }
      default:
        alert("Неизвестное значение таба!");
    }
  }
  const AddOutletProps = {
    currentOutlet,
    open: openNewOutletModal,
    onCloseModal: (updateTablePage) => {
      if (typeof currentOutlet !== "undefined") {
        setCurrentOutlet(undefined);
      }
      if (
        typeof updateTablePage === "boolean" &&
        updateTablePage
      ) {
        dispatch(
          updateStoreData({
            key: "outletsList",
            data: {
              [outletsPageIndex]: undefined,
            },
          })
        );
      }
      setNewOutletModal(false);
    },
  };
  const theme = useTheme();
  const xl = useMediaQuery(
    theme.breakpoints.down("xl")
  );
  return (
    <div className="sku_component">
      <Typography
        variant="h3"
        style={{ marginBottom: 10 }}
      >
        Справочники
      </Typography>
      <TabsComponent
        tab={persisting.directoryTab}
        setTab={onTabChange}
        buttons={tabs}
        variant="scrollable"
        scrollButtons={xl}
      />
      <div
        className="flex_box"
        style={{
          justifyContent: "space-between",
          marginTop: 25,
          minHeight: 40,
        }}
      >
        <Typography variant="h6">
          {tabs[persisting.directoryTab]}
        </Typography>
        {isSku && (
          <ThemeButton
            startIcon={
              <img
                src={plusIcon}
                alt="btn plus icon"
              />
            }
            onClick={() => {
              dispatch(
                createStoreData({
                  currentSku: undefined,
                  currentPartner: { id: "" },
                })
              );
              navigate("/sku");
            }}
          >
            Новый товар
          </ThemeButton>
        )}
        {isAdmin && !isSku && (
          <ThemeButton
            onClick={() => {
              if (isOutlets) {
                setNewOutletModal(true);
              } else {
                setDirectoryModal({
                  open: true,
                  type: isCountry
                    ? "country"
                    : isCity
                    ? "city"
                    : isDistrict
                    ? "district"
                    : isSkuGroup
                    ? "skuGroup"
                    : isBrand
                    ? "brand"
                    : isTypeOfPackaging
                    ? "typeOfPackaging"
                    : isPackagingFormat
                    ? "packagingFormat"
                    : undefined,
                });
              }
            }}
            startIcon={
              <img
                src={plusIcon}
                alt="btn plus icon"
              />
            }
          >
            {isSkuGroup
              ? "Новая группа товаров"
              : isBrand
              ? "Добавить бренд"
              : isTypeOfPackaging
              ? "Добавить тип упаковки"
              : isPackagingFormat
              ? "Добавить формат упаковки"
              : isOutlets
              ? "Новая торговая точка"
              : isCountry
              ? "Добавить страну"
              : isCity
              ? "Добавить город"
              : "Добавить район"}
          </ThemeButton>
        )}
      </div>
      <SearchComponent
        searchText={
          directorySearch[
            tabs[persisting.directoryTab]
          ]
        }
        onChangeText={(value) =>
          dispatch(
            updateStoreData({
              key: "directorySearch",
              data: {
                [tabs[persisting.directoryTab]]:
                  value,
              },
            })
          )
        }
        onSearch={(value) => onSearch(value)}
        placeholder={`Введите наименование ${
          isSku ? "товара или партнера" : ""
        }`}
      />
      <br />
      {isSku && (
        <HocMini
          haveData={Boolean(skuGrid.length)}
          isLoading={isLoading}
        >
          <div
            style={{
              display: "grid",
              gap: 16,
              gridTemplateColumns:
                "repeat(auto-fill,minmax(300px, 1fr))",
            }}
          >
            {skuGrid.map((product, id) => (
              <CardSKU
                product={product}
                key={id}
              />
            ))}
          </div>
        </HocMini>
      )}
      {isAdmin ? (
        <>
          {isSkuGroup && (
            <HocMini
              haveData={Boolean(
                skuGroupes && skuGroupes.length
              )}
              isLoading={isLoading}
            >
              <FlexibleList
                paramKey="groupName"
                editHandler={editHandler(
                  "skuGroup"
                )}
                deleteHandler={deleteHandler()}
              />
            </HocMini>
          )}
          {isOutlets && (
            <HocMini
              haveData={Boolean(
                totals.outlets?.totalCount
              )}
              isLoading={isLoading}
            >
              <CustomDataTable
                {...dataTableProps}
              />
            </HocMini>
          )}
          {isCountry && (
            <HocMini
              haveData={Boolean(
                countries && countries.length
              )}
              isLoading={isLoading}
            >
              <FlexibleList
                paramKey="countryName"
                editHandler={editHandler(
                  "country"
                )}
                deleteHandler={deleteHandler()}
              />
            </HocMini>
          )}
          {isCity && (
            <HocMini
              haveData={Boolean(
                cities && cities.length
              )}
              isLoading={isLoading}
            >
              <FlexibleList
                paramKey="cityName"
                editHandler={editHandler("city")}
                deleteHandler={deleteHandler()}
              />
            </HocMini>
          )}
          {isDistrict && (
            <HocMini
              haveData={Boolean(
                districts && districts.length
              )}
              isLoading={isLoading}
            >
              <FlexibleList
                paramKey="districtName"
                editHandler={editHandler(
                  "district"
                )}
                deleteHandler={deleteHandler()}
              />
            </HocMini>
          )}
        </>
      ) : isOutlets ? (
        <HocMini
          haveData={Boolean(
            totals.outlets?.totalCount
          )}
          isLoading={isLoading}
        >
          <CustomDataTable {...dataTableProps} />
        </HocMini>
      ) : null}

      {isBrand && (
        <HocMini
          haveData={Boolean(
            Array.isArray(brands)
          )}
          isLoading={isLoading}
        >
          <FlexibleList
            paramKey="brandName"
            editHandler={editHandler("brand")}
            deleteHandler={deleteHandler()}
          />
        </HocMini>
      )}

      {isTypeOfPackaging && (
        <HocMini
          haveData={Boolean(
            Array.isArray(typesOfPackaging)
          )}
          isLoading={isLoading}
        >
          <FlexibleList
            paramKey="typeOfPackaging"
            editHandler={editHandler(
              "typeOfPackaging"
            )}
            deleteHandler={deleteHandler()}
          />
        </HocMini>
      )}

      {isPackagingFormat && (
        <HocMini
          haveData={Boolean(
            Array.isArray(packagingFormats)
          )}
          isLoading={isLoading}
        >
          <FlexibleList
            paramKey="packagingFormat"
            editHandler={editHandler(
              "packagingFormat"
            )}
            deleteHandler={deleteHandler()}
          />
        </HocMini>
      )}

      {isSku && (
        <div
          className="flex_box"
          style={{
            marginTop: 33,
            marginBottom: 27,
          }}
        >
          <div className="flex_box">
            {Boolean(totals.sku) &&
              totals.sku.totalPages > 1 && (
                <Pagination
                  page={skuPageIndex + 1}
                  onChange={(_, page) =>
                    setPersisting({
                      Товары: page - 1,
                    })
                  }
                  classes={{
                    root: classes.pagination,
                  }}
                  count={totals.sku.totalPages}
                  siblingCount={2}
                  boundaryCount={1}
                  hideNextButton={true}
                  hidePrevButton={true}
                />
              )}
          </div>
          <PageSizeSelector
            pageSize={persisting.skuPageSize}
            onChangePageSize={changePageSize}
          />
        </div>
      )}

      <DirectoryModals
        type={directoryModal.type}
        open={directoryModal.open}
        currentDirectory={currentDirectory}
        handleClose={() => {
          setDirectoryModal((state) => ({
            ...state,
            open: false,
          }));
          setCurrentDirectory(undefined);
        }}
      />
      <OutletModal {...AddOutletProps} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
    padding: 12,
    borderRadius: "8px !important",
  },
  pagination: {
    marginRight: 30,
    "& .MuiPaginationItem-root": {
      height: 25,
      width: 25,
      minWidth: 25,
      color: "#757575",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#034F94",
    },
  },
}));
