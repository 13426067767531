import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { NoResult } from "shared";

export default function ByShelfShares({
  reportList,
  isLoading,
}) {
  const classes = useStyles();
  const { reportsByShelfShares } = useSelector(
    (store) => store.reportsTotal
  );
  function getValueFormatted(number, percent) {
    return typeof number === "number"
      ? `${number
          .toString()
          .match(/^\d+(?:\.\d{0,2})?/)}${
          percent ? "%" : ""
        }`
      : false;
  }
  return (
    <Box className={classes.tableContainer}>
      <Grid
        item
        xs={12}
        container
        className={classes.row}
        style={{
          padding: "16px 22px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid
          item
          className={classes.head}
          xs={4}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Район
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={1}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Всего точек на районе
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={1}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Посещенно ТТ
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={1}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            % посещенных ТТ
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={2}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            ТТ где присутствовал товар партнера на
            теплой полке
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={1}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Покрытие товара
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={2}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Доля полки в %, относящийся к товарам
            партенра
          </Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <div
          className="flex_box"
          style={{ marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : reportList.length ? (
        <>
          {reportList.map(
            (report, reportIndex) => (
              <Grid
                item
                xs={12}
                container
                className={classes.row}
                style={{
                  padding: "16px 22px",
                  backgroundColor: "#FFFFFF",
                  borderTop: "1px solid #ECECEC",
                }}
                key={`${report.districtName}-${reportIndex}`}
              >
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={4}
                >
                  <Typography variant="body1">
                    {report.districtName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={1}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.salePointCount
                    ) ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={1}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.visitedSalePoints
                    ) ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={1}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.percentVisitedSalePoints,
                      true
                    ) ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={2}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.numberOfSalePointsWhereProductWasWithoutFridge
                    ) ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={1}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.percentProductCoverageByFilter,
                      true
                    ) ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.row}
                  style={{
                    padding: 0,
                  }}
                  xs={2}
                >
                  <Typography variant="body1">
                    {getValueFormatted(
                      report.shelfSharesInPercent,
                      true
                    ) ?? ""}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 22px",
              backgroundColor: "#FFFFFF",
              borderTop: "1px solid #ECECEC",
            }}
          >
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={4}
            >
              <Typography variant="body1">
                {
                  reportsByShelfShares.districtName
                }
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.salePointCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.visitedSalePoints
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.percentVisitedSalePoints,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={2}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.numberOfSalePointsWhereProductWasWithoutFridge
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.percentProductCoverageByFilter,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={2}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  reportsByShelfShares.shelfSharesInPercent,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <NoResult
          title="Данные не найдены"
          style={{ marginTop: 20 }}
        />
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center !important",
      fontSize: "12px !important",
    },
  },
  row: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: 400,
      fontSize: 15,
    },
  },
}));
