import { createTheme } from "@mui/material";

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Inter",
    h3: {
      fontWeight: 700,
      fontSize: 34,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
    },
    h6: {
      fontWeight: 600,
      fontSize: 20,
    },
    body1: {
      fontSize: 16,
      fontWeight: 600,
      color: "#000",
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 600,
      color: "#757575",
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      color: "#000",
    },
    caption: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: "125%",
    },
    button: {
      textTransform: "none",
      color: "#FFFFFF",
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            color: "#FF0000",
            fontSize: 24,
            textAlign: "center",
            margin: "30px 0px",
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: 15,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 150,
          padding: "12px 16px",
          borderRadius: 6,
        },
      },
      variants: [
        {
          props: { variant: "delete" },
          style: {
            height: "40px !important",
            color: "red !important",
            border: "1px solid red !important",
            backgroundColor: "#ffffff !important",
          },
        },
        {
          props: { variant: "add" },
          style: {
            height: "40px !important",
            color: "#034F94 !important",
            border: "none !important",
            backgroundColor: "#ffffff !important",
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "50%",
          position: "relative",
          borderRadius: 6,
          boxShadow: "none",
          "& .MuiDialogContent-root": {
            padding: "0px 32px 32px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: "#FFFFFF",
          borderBottom: "1px solid #ECECEC",
          paddingRight: 0,
          paddingLeft: 0,
          "&.Mui-expanded": {
            backgroundColor: "#F9FBFC",
            borderBottom: "none",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
            backgroundColor: "#F9FBFC",
          },
          "& .MuiAccordionSummary-content.Mui-expanded .MuiGrid-container": {
            backgroundColor: "#F9FBFC",
          },
        },
        content: {
          margin: 0,
        },
        contentGutters: {
          margin: 0,
        },
        expandIconWrapper: {
          width: 124,
          padding: "0px 50px",
          textAlign: "center",
          "&.Mui-expanded": { transform: "rotate(180deg)" },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#EEF2F4",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 400,
          fontSize: 15,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 400,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {},
      },
    },
  },
});
