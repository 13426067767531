import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  IconButton,
  DialogContent,
  Dialog,
  Typography,
  CircularProgress,
} from "@mui/material";

import { NoResult } from "./NoResult";
import crossGreyIcon from "assets/icons/Cross.svg";
import downloadIcon from "assets/icons/download.svg";
import { createStoreData } from "store";

export const TaskResultViewer = ({ currentTask, viewer, closeModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store);
  const linkToDownload = React.useRef(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [isLoading, setLoading] = useState(false);

  function downloadHandler() {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_FILE_URL}/image/${currentTask.photoId}/?token=${token}`
    )
      .then((res) => {
        res
          .blob()
          .then((blob) => {
            setLoading(false);
            setDownloadLink(window.URL.createObjectURL(blob));
            linkToDownload.current.click();
          })
          .catch(errorHandler);
      })
      .catch(errorHandler);
    function errorHandler(err) {
      console.error(err);
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Не удалось скачать фото",
          },
        })
      );
    }
  }
  return (
    <Dialog
      scroll="paper"
      open={viewer}
      onClose={closeModal}
      classes={{ paper: classes.paper }}>
      <DialogContent style={{ padding: 40 }}>
        <div
          className="flex_box"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "flex-start",
          }}>
          <div
            style={{
              width: "calc(100% - 600px)",
              height: "calc(100% - 24px)",
            }}>
            <div
              className="flex_box"
              style={{
                position: "relative",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#333333",
                width: "100%",
                height: "100%",
              }}>
              <div
                style={{
                  position: "relative",
                  width: "fit-content",
                  height: "fit-content",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}>
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "calc(100vh - 220px)",
                    objectFit: "contain",
                  }}
                  src={`${process.env.REACT_APP_FILE_URL}/image/${currentTask.photoId}/?token=${token}`}
                  alt=""
                />

                {currentTask.mlFoundObjects && currentTask.mlFoundObjects.length
                  ? currentTask.mlFoundObjects.map((rectangle) => (
                      <div
                        style={{
                          bottom: `${rectangle.percentY * 100}%`,
                          left: `${rectangle.percentX * 100}%`,
                          width: `${rectangle.percentWidth * 100}%`,
                          height: `${rectangle.percentHeight * 100}%`,
                          border: `3px solid ${rectangle.color}`,
                        }}
                        key={`${rectangle.color}-${rectangle.x}, ${rectangle.y}`}
                        className="rectangle"
                      />
                    ))
                  : null}
              </div>
            </div>
            <div
              className="flex_box"
              style={{ height: "fit-content", margin: "12px 0px" }}>
              {isLoading ? (
                <CircularProgress size={32} />
              ) : (
                <div
                  className="flex_box"
                  style={{
                    gap: 20,
                    height: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={downloadHandler}>
                  <Typography variant="body1">Скачать фотографию</Typography>
                  <img
                    src={downloadIcon}
                    alt="download icon"
                    width={32}
                    height={32}
                  />
                </div>
              )}
            </div>
            <a
              ref={linkToDownload}
              href={downloadLink}
              download={`image-${currentTask.photoId}.jpg`}
              hidden>
              link to download
            </a>
          </div>
          <div style={{ width: 600 }}>
            <div
              style={{
                margin: "0px 50px",
                minWidth: "fit-content",
              }}>
              <Paper
                style={{
                  borderRadius: 4,
                  overflow: "hidden",
                }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    backgroundColor: "#F9FBFC",
                    padding: "14px 20px",
                  }}>
                  {currentTask.skuGroupName}
                </Typography>
                <div
                  style={{
                    backgroundColor: "#FFF",
                    padding: "10px 20px",
                  }}>
                  {currentTask.foundSkuList &&
                  currentTask.foundSkuList.length ? (
                    currentTask.foundSkuList.map((sku, skuIndex, array) => (
                      <div
                        key={sku.color}
                        className="flex_box_between"
                        style={{
                          borderBottom:
                            array.length === skuIndex + 1
                              ? "none"
                              : "1px solid #DBDBDB",
                          padding: "10px 0px",
                        }}>
                        <div
                          className="flex_box"
                          style={{
                            width: "auto",
                            justifyContent: "flex-start",
                          }}>
                          <div
                            className="circle"
                            style={{
                              backgroundColor: sku.color,
                              marginRight: 8,
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#000", marginRight: 20 }}>
                            {sku.skuName}
                          </Typography>
                        </div>
                        <Typography
                          variant="body1"
                          style={{
                            minWidth: 30,
                            fontSize: 10,
                            fontWeight: 600,
                            padding: 4,
                            borderRadius: 4,
                            color: "#4262FF",
                            textAlign: "center",
                            border: "1px solid #4262FF",
                            backgroundColor: "transparent",
                          }}>
                          {`${sku.count} шт`}
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <NoResult
                      title="Результаты не найдены"
                      style={{ height: 100 }}
                    />
                  )}
                </div>
              </Paper>
            </div>
          </div>
        </div>
        <IconButton classes={{ root: classes.closeIcon }} onClick={closeModal}>
          <img src={crossGreyIcon} alt="" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    width: "90vw",
    height: "90vh",
    minWidth: "90vw",
    minHeight: "90vh",
    maxWidth: "90vw",
    maxHeight: "90vh",
    "&.MuiPaper-root": {
      margin: 32,
      backgroundColor: "#EEF2F4",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  closeIcon: {
    "&.MuiIconButton-root": { position: "absolute", top: 10, right: 10 },
  },
  downloadIconButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      top: 20,
      right: 20,
      backgroundColor: "#EB6201",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#F28B00",
    },
  },
}));
