import { Chip } from "@mui/material";

export const BlueChip = ({ label }) => (
  <Chip
    label={label}
    sx={{
      backgroundColor: "#E9F0FF",
      color: "#2F77FB",
    }}
  />
);

export const OrangeChip = ({ label }) => (
  <Chip
    label={label}
    sx={{
      backgroundColor: "#FFEDDD",
      color: "#E67812",
    }}
  />
);

export const RedChip = ({ label }) => (
  <Chip
    label={label}
    sx={{
      backgroundColor: "#FCDDEC",
      color: "#E61912",
    }}
  />
);

export const GreenChip = ({ label }) => (
  <Chip
    label={label}
    sx={{
      backgroundColor: "#CBECC3",
      color: "#50BF34",
    }}
  />
);

export const Done = (
  <Chip
    label="Выполнено"
    sx={{
      backgroundColor: "#CBECC3",
      color: "#50BF34",
    }}
  />
);

export const NotDone = (
  <Chip
    label="Не выполнено"
    sx={{
      backgroundColor: "#FCDDEC",
      color: "#E61912",
    }}
  />
);

export const Error = ({ count }) => (
  <Chip
    label={`Ошибок: ${count}`}
    sx={{
      backgroundColor: "#FFE8E8",
      color: "#FF0000",
    }}
  />
);

export const Checked = (
  <Chip
    label="Проверено"
    sx={{
      backgroundColor: "#DADADA",
      color: "#757575",
    }}
  />
);

export const Fine = (
  <Chip
    label="В норме"
    sx={{
      backgroundColor: "#E8FEE2",
      color: "#50BF34",
    }}
  />
);

export const Missing = (
  <Chip
    label="Недостающие"
    sx={{
      backgroundColor: "#FFE8E8",
      color: "#FF0000",
    }}
  />
);

export const Normal = (
  <Chip
    label="Норма"
    sx={{
      backgroundColor: "#E8FEE2",
      color: "#50BF34",
    }}
  />
);

export const Superfluous = (
  <Chip
    label="Лишнее"
    sx={{
      backgroundColor: "#FFEDDD",
      color: "#E67812",
    }}
  />
);

export const getStatusChip = {
  NotCompleted: <RedChip label="Не выполнено" />,
  New: <BlueChip label="На рассмотрении" />,
  Verified: <GreenChip label="Verified" />,
  Refused: <RedChip label="Отклонено" />,
  InWork: <BlueChip label="В работе" />,
  Done: <GreenChip label="Выполнено" />,
  PartialDone: <OrangeChip label="Частично выполнено" />,
};
