import React, { useState } from "react";
import {
  Typography,
  Breadcrumbs,
  Grid,
  Paper,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  Pagination,
} from "@mui/material";
import {
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";

import CardSKU from "components/CardSKU";
import plusIcon from "assets/icons/Plus.svg";
import crossIcon from "assets/icons/Cross.svg";
import PartnerModal from "components/Dialogs/PartnerModal";
import {
  createStoreData,
  updateStoreData,
} from "store";
import {
  ThemeButton,
  TabsComponent,
  NoResult,
  ValidatedFormInput,
} from "shared";
import {
  deletePartner,
  getPartnerFromID,
  getSkuList,
  updatePassword,
} from "api";
import { Form, Formik } from "formik";
import SearchComponent from "components/SearchComponent";
import PageSizeSelector from "components/PageSizeSelector";
import MSL from "components/MSL";

export function ViewPartner() {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    persisting,
    partnerTotals,
    partners,
    partnersSku,
  } = useSelector((store) => store);
  const [
    openUpdatePasswordModal,
    setUpdatePasswordModal,
  ] = useState(false);
  const [
    openUpdatePartnerModal,
    setUpdatePartnerModal,
  ] = useState(false);
  const [currentPartner, setCurrentPartner] =
    useState({ id: "" });
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSku, setPageSku] = useState([]);
  const [tab, setTab] = useState(0);

  React.useEffect(() => {
    if (typeof partners[id] !== "undefined") {
      setCurrentPartner(partners[id]);
    } else if (id !== currentPartner.id) {
      dispatch(getPartnerFromID(id));
    }
    if (
      (typeof partnersSku[id] === "undefined" ||
        typeof partnersSku[id][pageIndex] ===
          "undefined") &&
      !isLoading
    ) {
      setLoading(true);
      dispatch(
        getSkuList(
          {
            pageIndex,
            pageSize:
              persisting.partnerSkuPageSize,
          },
          { partnerId: id }
        )
      );
    }
  }, [
    id,
    dispatch,
    partners,
    isLoading,
    pageIndex,
    persisting,
    partnersSku,
    currentPartner,
  ]);
  React.useEffect(() => {
    if (
      typeof partnersSku[id] !== "undefined" &&
      typeof partnersSku[id][pageIndex] !==
        "undefined"
    ) {
      setLoading(false);
      setPageSku(partnersSku[id][pageIndex]);
    }
  }, [id, pageIndex, partnersSku]);

  const deletePartnerHandler = () =>
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Удаление партнера",
          question: `Вы уверены, что хотите удалить партнера “${currentPartner.partnerName}”?`,
          message:
            "Вся статистика и информация о партнере, его товары и аналитика будут полностью удалены из системы",
          confirm: () =>
            dispatch(
              deletePartner(id, (success) => {
                if (success) {
                  dispatch(
                    createStoreData({
                      partnersList: {},
                    })
                  );
                  navigate(-1);
                }
              })
            ),
        },
      })
    );
  const partnerModalProps = {
    currentPartner,
    open: openUpdatePartnerModal,
    onCloseModal: (updateTablePage) => {
      if (
        Boolean(
          Object.keys(currentPartner).length
        )
      ) {
        setCurrentPartner({ id: "" });
      }
      if (
        typeof updateTablePage === "boolean" &&
        updateTablePage
      ) {
        dispatch(getPartnerFromID(id));
        dispatch(
          updateStoreData({
            key: "partnersList",
            data: {},
          })
        );
      }
      setUpdatePartnerModal(false);
    },
  };
  const onSearch = (value) => {
    setLoading(true);
    dispatch(
      getSkuList(
        {
          pageIndex,
          pageSize: persisting.partnerSkuPageSize,
        },
        { searchText: value, partnerId: id }
      )
    );
  };
  const changePageSize = (size) => {
    setLoading(true);
    setPageIndex(0);
    dispatch(
      updateStoreData({
        key: "persisting",
        data: {
          partnerSkuPageSize: size,
        },
      })
    );
    dispatch(
      getSkuList(
        { pageIndex: 0, pageSize: size },
        { searchText, partnerId: id }
      )
    );
  };
  let partnerName =
    id === currentPartner.id
      ? currentPartner.partnerName
      : "...";
  return (
    <div className="view_partner">
      <Breadcrumbs
        separator=">"
        style={{ marginBottom: 18 }}
      >
        <NavLink
          to="/admin/partners"
          className="navBreadcrumb"
        >
          Партнеры
        </NavLink>
        <Typography
          variant="body2"
          style={{
            color: "#000",
            lineHeight: "150%",
          }}
        >
          {partnerName}
        </Typography>
      </Breadcrumbs>
      <header className="flex_box_between">
        <Typography
          variant="h3"
          style={{ marginBottom: 15 }}
        >
          {partnerName}
        </Typography>
        {tab === 0 && (
          <ThemeButton
            onClick={() => {
              dispatch(
                createStoreData({
                  currentPartner,
                  currentSku: undefined,
                })
              );
              navigate("/sku");
            }}
            startIcon={
              <img
                src={plusIcon}
                alt="btn plus icon"
              />
            }
          >
            Добавить товар
          </ThemeButton>
        )}
      </header>
      <TabsComponent
        tab={tab}
        setTab={setTab}
        buttons={[
          "Товар партнера",
          "Информация о партнере",
          "MSL",
        ]}
      />

      {tab === 0 ? (
        <div style={{ marginTop: 40 }}>
          {typeof partnersSku[id] !==
            "undefined" && (
            <SearchComponent
              searchText={searchText}
              onChangeText={setSearchText}
              onSearch={onSearch}
              placeholder={
                "Введите наименование товара"
              }
            />
          )}
          <Grid
            container
            spacing={3}
          >
            {Boolean(pageSku.length) ? (
              pageSku.map((product, id) => (
                <Grid
                  item
                  xs={3}
                  key={id}
                  onClick={() =>
                    dispatch(
                      createStoreData({
                        currentPartner,
                      })
                    )
                  }
                >
                  <CardSKU product={product} />
                </Grid>
              ))
            ) : (
              <NoResult
                title="Нет товаров"
                style={{ marginTop: 50 }}
              />
            )}
          </Grid>
          <div
            className="flex_box"
            style={{
              marginTop: 33,
              marginBottom: 27,
            }}
          >
            <div className="flex_box">
              {Boolean(
                partnerTotals[currentPartner.id]
              ) &&
                partnerTotals[currentPartner.id]
                  .sku.totalPages > 1 && (
                  <Pagination
                    page={pageIndex + 1}
                    onChange={(_, page) =>
                      setPageIndex(page - 1)
                    }
                    classes={{
                      root: classes.pagination,
                    }}
                    count={
                      partnerTotals[
                        currentPartner.id
                      ].sku.totalPages
                    }
                    siblingCount={2}
                    boundaryCount={1}
                    hideNextButton={true}
                    hidePrevButton={true}
                  />
                )}
            </div>
            <PageSizeSelector
              pageSize={
                persisting.partnerSkuPageSize
              }
              onChangePageSize={changePageSize}
            />
          </div>
        </div>
      ) : null}
      {tab === 1 && (
        <>
          <Paper
            elevation={0}
            style={{ padding: 32, marginTop: 40 }}
          >
            <div
              className="flex_box"
              style={{
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <Typography variant="h6">
                Информация о партнере
              </Typography>
              <div
                className="flex_box"
                style={{ width: 370 }}
              >
                <ThemeButton
                  onClick={deletePartnerHandler}
                  style={{
                    width: 200,
                    color: "red",
                    backgroundColor:
                      "transparent",
                  }}
                >
                  Удалить партнера
                </ThemeButton>
                <ThemeButton
                  variant="outlined"
                  onClick={() =>
                    setUpdatePartnerModal(true)
                  }
                  style={{
                    width: 150,
                    marginLeft: 20,
                  }}
                >
                  Редактировать
                </ThemeButton>
              </div>
            </div>
            <section className="flex_box">
              <div style={{ width: "50%" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Наименование
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {partnerName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Адрес
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {Boolean(currentPartner.address)
                    ? currentPartner.address
                    : "..."}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Представитель
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {Boolean(currentPartner.name)
                    ? `${currentPartner.name} ${currentPartner.surname}`
                    : "..."}
                </Typography>
              </div>
              <div style={{ width: "50%" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Телефон
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {Boolean(currentPartner.phone)
                    ? currentPartner.phone
                    : "..."}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {Boolean(currentPartner.email)
                    ? currentPartner.email
                    : "..."}
                </Typography>
              </div>
            </section>
          </Paper>
          <Paper
            elevation={0}
            style={{ padding: 32, marginTop: 20 }}
          >
            <div
              className="flex_box"
              style={{
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <Typography variant="h6">
                Вход в систему
              </Typography>
              <ThemeButton
                variant="outlined"
                onClick={() =>
                  setUpdatePasswordModal(true)
                }
                style={{ width: 200 }}
              >
                Изменить пароль
              </ThemeButton>
            </div>
            <section className="flex_box">
              <div style={{ width: "50%" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: 8 }}
                >
                  Логин для входа в систему
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 26 }}
                >
                  {Boolean(currentPartner.email)
                    ? currentPartner.email
                    : "..."}
                </Typography>
              </div>
              <div style={{ width: "50%" }}></div>
            </section>
          </Paper>
        </>
      )}
      {tab === 2 && (
        <MSL currentPartner={currentPartner} />
      )}
      <PartnerModal {...partnerModalProps} />
      <Dialog
        open={openUpdatePasswordModal}
        onClose={() =>
          setUpdatePasswordModal(false)
        }
      >
        <DialogContent>
          <div
            className="flex_box sticky_header"
            style={{
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <Typography variant="h6">
              Новый пароль
            </Typography>
            <IconButton
              onClick={() =>
                setUpdatePasswordModal(false)
              }
            >
              <img
                src={crossIcon}
                alt="cross"
              />
            </IconButton>
          </div>
          <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object({
              password: Yup.string().required(
                "Поле обязательно к заполнению"
              ),
            })}
            onSubmit={(values) => {
              setLoading(true);
              dispatch(
                updatePassword(
                  {
                    password: values.password,
                    id,
                  },
                  (success) => {
                    if (success) {
                      setLoading(false);
                      setUpdatePasswordModal(
                        false
                      );
                      dispatch(
                        createStoreData({
                          alert: {
                            message: "Успешно",
                            severity: "success",
                          },
                        })
                      );
                    }
                  }
                )
              );
            }}
          >
            {(formik) => (
              <Form
                onSubmit={formik.handleSubmit}
              >
                <ValidatedFormInput
                  type="password"
                  name="password"
                  label="Новый пароль"
                />
                <div
                  className="flex_box"
                  style={{
                    gap: 16,
                    marginTop: 32,
                  }}
                >
                  <ThemeButton
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={20}
                        style={{
                          color: "#FFFFFF",
                        }}
                      />
                    ) : (
                      "Сохранить"
                    )}
                  </ThemeButton>
                  <ThemeButton
                    type="button"
                    variant="outlined"
                    style={{ width: 150 }}
                    onClick={() =>
                      setUpdatePasswordModal(
                        false
                      )
                    }
                  >
                    Отмена
                  </ThemeButton>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paperCard: {
    width: "fit-content",
    height: "100%",
    padding: 12,
    borderRadius: "12px !important",
  },
  infoAboutPartner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pagination: {
    marginRight: 30,
    "& .MuiPaginationItem-root": {
      height: 25,
      width: 25,
      minWidth: 25,
      color: "#757575",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#034F94",
    },
  },
}));
