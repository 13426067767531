import {
  getOptionLabel,
  getReferences,
  isOptionEqualToValue,
} from "api";
import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import lodash from "lodash";

import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function TypeOfPackagingAutocomplete({
  value,
  updateValue,
  validated = false,
  name = "typeOfPackagingId",
}) {
  const dispatch = useDispatch();
  const { typesOfPackaging } = useSelector(
    (store) => store
  );
  const [stateValue, setStateValue] =
    useState(value);

  React.useEffect(() => {
    setStateValue((state) =>
      lodash.isEqual(value, state) ? state : value
    );
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function")
      updateValue(option);
  }

  const options = (
    validated || !value
      ? []
      : [autocompletesInitialValues.salePointType]
  ).concat(typesOfPackaging ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label="Тип упаковки"
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      fetchOptions={(searchText, callback) =>
        dispatch(
          getReferences(
            "TypeOfPackaging",
            { searchText },
            callback
          )
        )
      }
      validated={validated}
      name={name}
    />
  );
}
