import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";

import { getCitiesSelect, getCountriesSelect, idNotAll } from "api";
import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function CountryAutocomplete({
  value,
  updateValue,
  validated = false,
  name = "countryId",
}) {
  const dispatch = useDispatch();
  const { countriesSelect } = useSelector((store) => store);
  const [stateValue, setStateValue] = useState(value);

  React.useEffect(() => {
    setStateValue((state) => (lodash.isEqual(value, state) ? state : value));
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function") {
      updateValue(option);
    }
    if (idNotAll(option)) {
      dispatch(
        getCitiesSelect({
          countryId: option.id,
        })
      );
    }
  }

  const options = (
    validated || !value ? [] : [autocompletesInitialValues.country]
  ).concat(countriesSelect ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label="Страна"
      options={options}
      getOptionLabel={(country) => country.countryName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fetchOptions={(searchText, callback) =>
        dispatch(getCountriesSelect({ searchText }, callback))
      }
      validated={validated}
      name={name}
    />
  );
}
