import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { NoResult } from "shared";
import chevronDown from "assets/icons/ChevronDown.svg";

export default function ByMSL({
  reportList,
  isLoading,
}) {
  const classes = useStyles();
  const [districtExpanded, setDistrictIndex] =
    useState(null);
  const [salepointExpanded, setSalepointIndex] =
    useState(null);

  function getValueFormatted(number, percent) {
    return typeof number === "number"
      ? `${number
          .toString()
          .match(/^\d+(?:\.\d{0,2})?/)}${
          percent ? "%" : ""
        }`
      : false;
  }
  return (
    <Box className={classes.tableContainer}>
      <Grid
        item
        xs={12}
        container
        className={classes.row}
        style={{
          padding: "16px 146px 16px 22px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid
          item
          className={classes.head}
          xs={10}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Район\ТТ\SKU
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.head}
          xs={2}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#7A8BA7",
              fontWeight: "bold",
            }}
          >
            Процент
          </Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <div
          className="flex_box"
          style={{ marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : reportList.length ? (
        reportList.map(
          (district, districtIndex) => (
            <Accordion
              key={`${districtIndex}-${district.districtName}`}
              expanded={
                districtExpanded === districtIndex
              }
              onChange={() => {
                setDistrictIndex(
                  districtExpanded ===
                    districtIndex
                    ? false
                    : districtIndex
                );
              }}
              sx={{
                borderTop: "1px solid #ECECEC",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <img
                    src={chevronDown}
                    alt=""
                  />
                }
              >
                <Grid
                  item
                  xs={12}
                  container
                  className={classes.row}
                  style={{
                    padding: "16px 22px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Grid
                    item
                    className={classes.row}
                    style={{
                      padding: 0,
                    }}
                    xs={10}
                  >
                    <Typography variant="body1">
                      {district?.districtName ??
                        ""}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.row}
                    style={{
                      padding: 0,
                    }}
                    xs={2}
                  >
                    <Typography variant="body1">
                      {getValueFormatted(
                        district?.percent,
                        true
                      ) ?? ""}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {district.childItems.length ? (
                  district.childItems.map(
                    (
                      salepoint,
                      salepointIndex
                    ) => (
                      <Accordion
                        key={`${districtIndex}-${salepointIndex}-${salepoint.salePointName}`}
                        expanded={
                          districtExpanded ===
                            districtIndex &&
                          salepointExpanded ===
                            salepointIndex
                        }
                        onChange={() => {
                          setSalepointIndex(
                            districtExpanded ===
                              districtIndex &&
                              salepointExpanded ===
                                salepointIndex
                              ? false
                              : salepointIndex
                          );
                        }}
                        sx={{
                          borderTop:
                            "1px solid #ECECEC",
                          "&:before": {
                            display: "none",
                          },
                          "&.Mui-expanded": {
                            margin: 0,
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <img
                              src={chevronDown}
                              alt=""
                            />
                          }
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            className={
                              classes.row
                            }
                            style={{
                              padding:
                                "16px 22px",
                              backgroundColor:
                                "#FFFFFF",
                            }}
                          >
                            <Grid
                              item
                              className={
                                classes.row
                              }
                              style={{
                                padding: 0,
                              }}
                              xs={10}
                            >
                              <Typography variant="body1">
                                {salepoint?.salePointName ??
                                  ""}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className={
                                classes.row
                              }
                              style={{
                                padding: 0,
                              }}
                              xs={2}
                            >
                              <Typography variant="body1">
                                {getValueFormatted(
                                  salepoint?.percent,
                                  true
                                ) ?? ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          {salepoint.childItems
                            .length ? (
                            salepoint.childItems.map(
                              (
                                sku,
                                skuIndex,
                                array
                              ) => {
                                return (
                                  <React.Fragment
                                    key={`${districtIndex}-${salepointIndex}-${skuIndex}-${sku.skuName}`}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      container
                                      className={
                                        classes.row
                                      }
                                      style={{
                                        padding:
                                          "16px 146px 16px 22px",
                                        backgroundColor:
                                          "#FFFFFF",
                                      }}
                                    >
                                      <Grid
                                        item
                                        className={
                                          classes.row
                                        }
                                        style={{
                                          padding: 0,
                                        }}
                                        xs={10}
                                      >
                                        <Typography variant="body1">
                                          {sku?.skuName ??
                                            ""}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        className={
                                          classes.row
                                        }
                                        style={{
                                          padding: 0,
                                        }}
                                        xs={2}
                                      >
                                        <Typography variant="body1">
                                          {getValueFormatted(
                                            sku?.percent,
                                            true
                                          ) ?? ""}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {array.length >
                                      1 &&
                                      skuIndex !==
                                        array.length -
                                          1 && (
                                        <Divider />
                                      )}
                                  </React.Fragment>
                                );
                              }
                            )
                          ) : (
                            <NoResult
                              style={{
                                height: 100,
                              }}
                              title="Данные не найдены"
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )
                  )
                ) : (
                  <NoResult
                    style={{ height: 100 }}
                    title="Данные не найдены"
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )
        )
      ) : (
        <NoResult
          title="Данные не найдены"
          style={{ marginTop: 20, height: 100 }}
        />
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "start !important",
      fontSize: "12px !important",
    },
  },
  row: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "start",
      fontWeight: 400,
      fontSize: 15,
    },
  },
}));
