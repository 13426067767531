import React from "react";
import { Button, Tab, Tabs } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const ThemeButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: "#034F94",
    padding: "12px 16px",
    color: "#ffffff",
    height: 40,
    borderRadius: 6,
  },
  "&.MuiButton-outlinedPrimary": {
    color: "#034F94",
    borderColor: "#034F94",
    backgroundColor: "#ffffff",
  },
  "&.MuiButton-root:hover": {
    backgroundColor: "#034F94",
  },
  "&.MuiButton-outlinedPrimary:hover": {
    backgroundColor: "transparent",
  },
  "&.MuiButton-root.Mui-disabled": {
    backgroundColor: "#cccccc"
  }
}));

export function TabsComponent({ tab, setTab, buttons = [], ...props }) {
  const classes = useStyles();

  return (
    <Tabs
      value={tab}
      {...props}
      onChange={(_, newState) => setTab(newState)}
      aria-label="basic tabs example"
      classes={{ root: classes.tabs }}>
      {Boolean(buttons.length) &&
        buttons.map((btn) => <Tab key={btn} label={btn} />)}
    </Tabs>
  );
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    maxHeight: 40,
    borderBottom: "1px solid #C8D2E5",
    "& .MuiTab-root": {
      padding: "2px 0px",
      marginRight: 32,
      color: "#788090",
      fontWeight: 400,
      textAlign: "start",
    },
    "& .Mui-selected": {
      color: "#034F94 !important",
      fontWeight: "700 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#034F94",
      height: 3,
    },
  },
}));
