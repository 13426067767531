import React, { useState } from "react";
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";

import { nanoid } from "nanoid";
import { createStoreData } from "store";
import {
  FormInput,
  ThemeButton,
  ThemeMultipleDatesPicker,
} from "shared";
import {
  createApplication,
  getSkuGroupes,
  getSkuList,
} from "api";
import plusBlueIcon from "assets/icons/PlusBlue.svg";
import crossBlackIcon from "assets/icons/CrossBlack.svg";
import SalepointSelectModal from "components/Dialogs/SalepointSelectModal";

export default function CaUApplication() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    skuList,
    skuGroupes,
    currentApplication,
  } = useSelector((store) => store);
  const [applicationName, setApplicationName] =
    useState("");
  const [dates, setDates] = useState([]);
  const [
    openDistrictSelect,
    setOpenDistrictSelect,
  ] = useState(false);
  const [
    applicationDistricts,
    setApplicationDistricts,
  ] = useState([]);
  const [skuGroupIds, setSkuGroupIds] = useState(
    []
  );
  const [
    selectedSkuGroups,
    setSelectedSkuGroups,
  ] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let isItUpdateMode = Boolean(
    currentApplication.id
  );

  React.useEffect(() => {
    if (typeof skuList[0] === "undefined") {
      dispatch(
        getSkuList({ pageIndex: 0, pageSize: 20 })
      );
    }
    if (typeof skuGroupes === "undefined") {
      dispatch(getSkuGroupes());
    }
  }, [dispatch, skuList, skuGroupes]);
  React.useEffect(() => {
    if (isItUpdateMode) {
      let selectedSkuVariable = [],
        skuIdsVariable = [],
        skuDescriptionVariable = {};

      setApplicationName(
        currentApplication.applicationName ?? ""
      );
      setDates([
        moment(
          currentApplication.dateOfExecution
        ),
      ]);
      setApplicationDistricts(
        currentApplication.salePoints
      );
      currentApplication.skuList.forEach(
        (sku) => {
          let id = nanoid();
          skuIdsVariable.push(sku.id);
          skuDescriptionVariable[id] =
            sku.description;
          selectedSkuVariable.push({
            id,
            skuId: sku.id,
            skuName: sku.name,
          });
        }
      );
      setSelectedSkuGroups(selectedSkuVariable);
    }
  }, [isItUpdateMode, currentApplication]);

  const submitHandler = () => {
    if (!applicationName) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message:
              "Введите наименование заявки",
          },
        })
      );
      return;
    }
    if (!dates[0]) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message:
              "Выберите даты исполнения заявки",
          },
        })
      );
      return;
    }
    if (!skuGroupIds.length) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Выберите группы товаров",
          },
        })
      );
      return;
    }
    if (!applicationDistricts.length) {
      dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: "Выберите торговые точки",
          },
        })
      );
      return;
    }
    setLoading(true);
    dispatch(
      createApplication(
        {
          applicationName,
          dateOfExecutions: dates.map((unix) =>
            new Date(unix).toISOString()
          ),
          salePointIds: applicationDistricts
            .map((item) => item.selectedOutlets)
            .reduce(
              (calculator, item) =>
                calculator.concat(item),
              []
            ),
          skuGroupIds,
          description: "",
        },
        (success) => {
          if (success) {
            dispatch(
              createStoreData({
                alert: {
                  severity: "success",
                  message:
                    "Заявка успешно создана ",
                },
                applicationsList: {},
              })
            );
            navigate(-1);
          }
          setLoading(false);
        }
      )
    );
  };
  return (
    <Container style={{ padding: "18px 0px" }}>
      <Breadcrumbs
        separator=">"
        style={{ marginBottom: 18 }}
      >
        <NavLink
          to={`/admin/${
            isItUpdateMode
              ? "analytics"
              : "applications"
          }`}
          className="navBreadcrumb"
        >
          Заявки
        </NavLink>
        <Typography
          variant="body2"
          style={{
            color: "#000",
            lineHeight: "150%",
            fontWeight: 600,
          }}
        >
          {isItUpdateMode
            ? "Просмотр заявки"
            : "Новая заявка"}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h3">
        {isItUpdateMode
          ? "Просмотр заявки"
          : "Новая заявка"}
      </Typography>

      <Paper
        elevation={0}
        style={{ padding: 32, marginTop: 24 }}
      >
        <div
          style={{
            width: "100%",
            minHeight: 111,
          }}
        >
          <Typography
            variant="body2"
            className={classes.label}
          >
            Наименование
          </Typography>
          <FormInput
            fullWidth
            name="applicationName"
            value={applicationName}
            onChange={(event) =>
              setApplicationName(
                event.target.value
              )
            }
          />
        </div>
        <Typography
          variant="body2"
          className={classes.label}
        >
          Даты исполнения заявки
        </Typography>
        <ThemeMultipleDatesPicker
          values={dates}
          onChange={(newDates) => {
            setDates(newDates);
          }}
          disabled={isItUpdateMode}
          style={{ width: "100%" }}
          minDate={new Date()}
        />
        <Typography
          variant="body2"
          className={classes.label}
          style={{ marginTop: 24 }}
        >
          Группы товаров
        </Typography>
        <FormInput
          name="skuGroups"
          value={selectedSkuGroups}
          onChange={(event) => {
            setSelectedSkuGroups(
              event.target.value
            );
          }}
          SelectProps={{
            multiple: true,
            renderValue: (selected) => {
              return (
                <div
                  className="flex_box"
                  style={{
                    height: 23,
                    justifyContent: "flex-start",
                    gap: 8,
                  }}
                >
                  {selected.map((group) => (
                    <div
                      key={group.skuGroupId}
                      className="flex_box tag"
                      style={{
                        width: "fit-content",
                      }}
                    >
                      <Typography variant="caption">
                        {group.groupName}
                      </Typography>
                    </div>
                  ))}
                </div>
              );
            },
          }}
          style={{ width: "100%" }}
          select
        >
          <MenuItem
            value=""
            style={{
              position: "relative",
              top: -1000,
            }}
          ></MenuItem>
          {Boolean(skuGroupes?.length)
            ? skuGroupes.map((group) => (
                <MenuItem
                  key={group.skuGroupId}
                  value={group}
                  onClick={() => {
                    let newState =
                      skuGroupIds.indexOf(
                        group.skuGroupId
                      ) === -1
                        ? [
                            ...skuGroupIds,
                            group.skuGroupId,
                          ]
                        : skuGroupIds.filter(
                            (skuGroupId) =>
                              skuGroupId !==
                              group.skuGroupId
                          );
                    setSkuGroupIds(newState);
                  }}
                >
                  {group.groupName}
                </MenuItem>
              ))
            : null}
        </FormInput>
      </Paper>
      <Paper
        elevation={0}
        style={{ padding: 32, marginTop: 24 }}
      >
        <Typography variant="h6">
          Торговые точки
        </Typography>
        {!isItUpdateMode && (
          <Button
            variant="add"
            style={{
              width: 175,
              marginTop: 30,
            }}
            onClick={() => {
              setOpenDistrictSelect(true);
            }}
            startIcon={
              <img
                src={plusBlueIcon}
                alt="btn plus icon"
              />
            }
          >
            Добавить район
          </Button>
        )}
        <div
          className="flex_box"
          style={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "stretch",
            marginTop: 16,
          }}
        >
          {Boolean(applicationDistricts.length) &&
            applicationDistricts.map((item) => {
              let district = item.state.district;
              return (
                <div
                  className="sale_point_card"
                  key={district.id}
                >
                  <span
                    style={{ marginRight: 10 }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: "19px",
                      }}
                    >
                      {district.districtName}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ color: "#757575" }}
                    >
                      {`Торговых точек: ${item.selectedOutlets.length}`}
                    </Typography>
                  </span>
                  {!isItUpdateMode && (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        setApplicationDistricts(
                          (state) =>
                            state.filter(
                              (item) =>
                                item.state
                                  .district.id !==
                                district.id
                            )
                        );
                      }}
                    >
                      <img
                        src={crossBlackIcon}
                        alt=""
                      />
                    </IconButton>
                  )}
                </div>
              );
            })}
        </div>
        {!isItUpdateMode && (
          <div
            className="flex_box"
            style={{ gap: 16, marginTop: 32 }}
          >
            <ThemeButton
              style={{ width: 200 }}
              disabled={isLoading}
              onClick={submitHandler}
            >
              {isLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: "#FFFFFF" }}
                />
              ) : (
                "Создать заявку"
              )}
            </ThemeButton>
            <ThemeButton
              variant="outlined"
              style={{ width: 150 }}
              onClick={() => navigate(-1)}
            >
              Отмена
            </ThemeButton>
          </div>
        )}
      </Paper>

      <SalepointSelectModal
        open={openDistrictSelect}
        onClose={() =>
          setOpenDistrictSelect(false)
        }
        setApplicationDistricts={
          setApplicationDistricts
        }
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: "10px !important",
    fontWeight: "600 !important",
  },
  multiSelect: {
    height: "20px !important",
    padding: 10,
    minHeight: "20px !important",
  },
}));
