import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import chevronDown from "assets/icons/ChevronDown.svg";
import { NoResult } from "shared";
import { makeStyles } from "@mui/styles";
import {
  List,
  AutoSizer,
} from "react-virtualized";

const ByApplication = React.memo(
  ({ reportList, isLoading }) => {
    const classes = useStyles();
    return (
      <Box className={classes.tableContainer}>
        <Grid
          item
          xs={12}
          container
          className={classes.row}
          style={{
            padding: "16px 146px 16px 22px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid
            item
            className={classes.head}
            xs={3}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Партнер/Дата/Заявка
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Всего заявок
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Заявок закрыто
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Заявок закрыто %
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Заявки частично выполненные
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Заявки закрытые частично %
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Заявки не отработанные %
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Всего ТТ по Заявкам
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              ТТ обработано
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              ТТ обработано %
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <div
            className="flex_box"
            style={{ marginTop: 20 }}
          >
            <CircularProgress />
          </div>
        ) : reportList.length ? (
          reportList.map(
            (report, reportIndex) => (
              <ReportItem
                report={report}
                reportIndex={reportIndex}
                key={`${reportIndex}-${report.partnerId}`}
              />
            )
          )
        ) : (
          <NoResult
            title="Данные не найдены"
            style={{ marginTop: 20 }}
          />
        )}
      </Box>
    );
  }
);

export default ByApplication;

const ReportItem = React.memo(
  ({ report, reportIndex }) => {
    const classes = useStyles();
    const [reportExpanded, setReportIndex] =
      useState(false);
    return (
      <Accordion
        expanded={reportExpanded}
        onChange={() => {
          setReportIndex(!reportExpanded);
        }}
        sx={{
          borderTop: "1px solid #ECECEC",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src={chevronDown}
              alt=""
            />
          }
        >
          <Grid
            item
            xs={12}
            container
            className={classes.row}
            style={{
              padding: "16px 22px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={3}
            >
              <Typography variant="body1">
                {report?.partnerName ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.applicationTotalCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.doneApplicationCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.doneApplicationPercent,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.partialDoneApplicationCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.partialDoneApplicationPercent,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.refusedApplicationPercent,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.salePointsTotalCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.doneSalePointsCount
                ) ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.row}
              style={{
                padding: 0,
              }}
              xs={1}
            >
              <Typography variant="body1">
                {getValueFormatted(
                  report?.doneSalePointsPercent,
                  true
                ) ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {reportExpanded ? (
            report.childItems.length ? (
              report.childItems.map(
                (dateItem, dateIndex) => (
                  <DateItem
                    dateItem={dateItem}
                    dateIndex={dateIndex}
                    reportIndex={reportIndex}
                    key={`${dateIndex}-${dateItem.date}`}
                  />
                )
              )
            ) : (
              <NoResult
                style={{ height: 100 }}
                title="Данные не найдены"
              />
            )
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const DateItem = React.memo(({ dateItem }) => {
  const classes = useStyles();
  const [dateExpanded, setDateIndex] =
    useState(false);
  return (
    <Accordion
      expanded={dateExpanded}
      onChange={() => {
        setDateIndex(!dateExpanded);
      }}
      sx={{
        borderTop: "1px solid #ECECEC",
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <img
            src={chevronDown}
            alt=""
          />
        }
      >
        <Grid
          item
          xs={12}
          container
          className={classes.row}
          style={{
            padding: "16px 22px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={3}
          >
            <Typography variant="body1">
              {dateItem?.date ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.applicationTotalCount
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.doneApplicationCount
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.doneApplicationPercent,
                true
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.partialDoneApplicationCount
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.partialDoneApplicationPercent,
                true
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.refusedApplicationPercent,
                true
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.salePointsTotalCount
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.doneSalePointsCount
              ) ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.row}
            style={{
              padding: 0,
            }}
            xs={1}
          >
            <Typography variant="body1">
              {getValueFormatted(
                dateItem?.doneSalePointsPercent,
                true
              ) ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {dateExpanded ? (
          dateItem.childItems.length ? (
            <AutoSizer
              style={{
                width: "auto",
                height: "auto",
              }}
            >
              {({ width }) => {
                const rowCount =
                  dateItem.childItems.length;
                const height =
                  rowCount >= 10
                    ? 550
                    : rowCount * 55;
                return (
                  <List
                    height={height}
                    width={width}
                    rowCount={rowCount}
                    rowHeight={55}
                    rowRenderer={({
                      index,
                      key,
                      style,
                    }) => (
                      <ApplicationItem
                        application={
                          dateItem.childItems[
                            index
                          ]
                        }
                        applicationIndex={index}
                        length={rowCount}
                        style={style}
                        key={key}
                      />
                    )}
                  />
                );
              }}
            </AutoSizer>
          ) : (
            <NoResult
              style={{
                height: 100,
              }}
              title="Данные не найдены"
            />
          )
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
});

const ApplicationItem = React.memo(
  ({
    application,
    applicationIndex,
    length,
    style,
  }) => {
    console.log(application);
    const classes = useStyles();
    return (
      <Grid
        item
        xs={12}
        container
        className={classes.row}
        style={{
          padding: "16px 146px 16px 22px",
          backgroundColor: "#FFFFFF",
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderBottomWidth:
            applicationIndex !== length - 1
              ? "thin"
              : 0,
          ...style,
        }}
      >
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={3}
        >
          <Typography variant="body1">
            {application?.applicationName ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {1}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.doneApplicationCount
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.doneApplicationPercent,
              true
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.partialDoneApplicationCount
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.partialDoneApplicationPercent,
              true
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.refusedApplicationPercent,
              true
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.salePointsTotalCount
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.doneSalePointsCount
            ) ?? ""}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{
            padding: 0,
          }}
          xs={1}
        >
          <Typography variant="body1">
            {getValueFormatted(
              application?.doneSalePointsPercent,
              true
            ) ?? ""}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  head: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center !important",
      fontSize: "12px !important",
    },
  },
  row: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: 400,
      fontSize: 15,
    },
  },
}));

function getValueFormatted(number, percent) {
  return typeof number === "number"
    ? `${number
        .toString()
        .match(/^\d+(?:\.\d{0,2})?/)}${
        percent ? "%" : ""
      }`
    : "";
}
