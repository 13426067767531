import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationListSelect } from "api";

export function ApplicationsReports(props) {
  const { onChange, label, width } = props;
  const dispatch = useDispatch();
  const { applicationsListSelect } = useSelector((store) => store);
  const [value, setValue] = React.useState([]);

  const handleChange = (newValues) => {
    setValue(newValues);
    onChange(newValues);
  };

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      options={applicationsListSelect || []}
      getOptionLabel={(option) => option.applicationName}
      sx={{ width: width || "100%" }}
      fetchOptions={(searchText, callback) =>
        dispatch(
          getApplicationListSelect(
            {
              searchText,
            },
            callback
          )
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{
            bgcolor: "#ffffff",
            "& .MuiOutlinedInput-input": {
              paddingTop: "9.5px",
              paddingBottom: "9.5px",
              fontWeight: 400,
              height: "26px",
            },
          }}
        />
      )}
    />
  );
}
