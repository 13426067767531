import React, { useState } from "react";
import {
  Typography,
  Breadcrumbs,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import {
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";

import phoneIcon from "assets/icons/Phone.svg";
import emailIcon from "assets/icons/Email.svg";
import editIcon from "assets/icons/EditWhite.svg";
import {
  deleteAgent,
  getAgentFromID,
  getDistricts,
  getPartnerFromID,
} from "api";
import AgentModal from "components/Dialogs/AgentModal";
import {
  createStoreData,
  updateStoreData,
} from "store";
import userAvatar from "assets/icons/user.png";
import {
  TabsComponent,
  ThemeButton,
} from "shared";
import moment from "moment";

const tabs = ["Профиль"];

export function ViewAgent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    token,
    agents,
    persisting,
    partnersListSelect,
    districts,
    partners
  } = useSelector((store) => store);
  const [currentAgent, setCurrentAgent] =
    useState({ id: "" });
  const [tab, setTab] = useState(0);
  const [
    openUpdateAgentModal,
    setUpdateAgentModal,
  ] = useState(false);
  const [districtsState, setDistrictsState] =
    useState([]);

  React.useEffect(() => {
    if(currentAgent && currentAgent.companyPartnerId) {
      dispatch(getPartnerFromID(currentAgent.companyPartnerId));
    }
  }, [currentAgent]);

  React.useEffect(() => {
    if (typeof agents[id] !== "undefined") {
      let agent = agents[id];
      setCurrentAgent(agent);
    } else if (id !== currentAgent.id) {
      dispatch(getAgentFromID(id));
    }
  }, [
    dispatch,
    id,
    currentAgent,
    agents,
    partnersListSelect,
  ]);
  React.useEffect(() => {
    if (Array.isArray(districts)) {
      let formattedArray = [];
      districts.forEach((district) => {
        let lastRaw =
          formattedArray[
            formattedArray.length - 1
          ];
        if (
          typeof lastRaw === "undefined" ||
          lastRaw.length === 3
        ) {
          formattedArray.push([district]);
        } else {
          lastRaw.push(district);
        }
      });
      setDistrictsState(formattedArray);
    } else {
      dispatch(getDistricts());
    }
  }, [districts, dispatch]);

  const deleteAgentHandler = () =>
    dispatch(
      createStoreData({
        prompDialog: {
          open: true,
          title: "Удаление агента",
          question: `Вы уверены, что хотите уволить агента ${currentAgent.name} ${currentAgent.surname}?`,
          message:
            "Вся статистика и информация об агенте останется в вашей базе после увольнения агента.",
          btnText: "Да, уволить",
          confirm: () =>
            dispatch(
              deleteAgent(id, (success) => {
                if (success) {
                  dispatch(
                    updateStoreData({
                      key: "agentsList",
                      data: {
                        [persisting.agentsPageIndex]:
                          undefined,
                      },
                    })
                  );
                  navigate(-1);
                }
              })
            ),
        },
      })
    );

  const updateAgentModalProps = {
    open: openUpdateAgentModal,
    currentAgent,
    onCloseModal: (updateTablePage) => {
      if (
        Boolean(Object.keys(currentAgent).length)
      ) {
        setCurrentAgent({ id: "" });
      }
      if (
        typeof updateTablePage === "boolean" &&
        updateTablePage
      ) {
        dispatch(
          updateStoreData({
            key: "agentsList",
            data: {
              [persisting.agentsPageIndex]:
                undefined,
            },
          })
        );
      }
      setUpdateAgentModal(false);
    },
  };

  let agentFullName =
      id === currentAgent.id
        ? `${currentAgent.name} ${currentAgent.surname}`
        : "...",
    olderThanSixteen =
      currentAgent.dateOfBirth &&
      moment().diff(
        moment(
          new Date(currentAgent.dateOfBirth)
        ),
        "years"
      ) >= 16;
  return (
    <div className="view_agent">
      <Breadcrumbs
        separator=">"
        style={{ marginBottom: 18 }}
      >
        <NavLink
          to="/admin/agents"
          className="navBreadcrumb"
        >
          Агенты
        </NavLink>
        ,
        <Typography
          variant="body2"
          style={{
            color: "#000",
            lineHeight: "150%",
          }}
        >
          {agentFullName}
        </Typography>
        ,
      </Breadcrumbs>
      <div
        className="flex_box"
        style={{
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Typography variant="h3">
          {agentFullName}
        </Typography>
        <Button
          variant="delete"
          onClick={deleteAgentHandler}
          style={{
            width: 200,
          }}
        >
          Уволить агента
        </Button>
      </div>
      <TabsComponent
        tab={tab}
        setTab={setTab}
        buttons={tabs}
      />
      <div
        className="flex_box"
        style={{
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: 32,
            marginBottom: 21,
          }}
        >
          {tab === 0 && "Личные данные"}
          {tab === 1 && "Вывод средств"}
          {tab === 2 && "Задания"}
        </Typography>
        {tab === 0 && (
          <ThemeButton
            startIcon={
              <img
                src={editIcon}
                alt="btn edit icon"
              />
            }
            onClick={() => {
              setCurrentAgent(currentAgent);
              setUpdateAgentModal(true);
            }}
          >
            Редактировать профиль
          </ThemeButton>
        )}
      </div>

      {tab === 0 && (
        <>
          <div
            className="flex_box"
            style={{
              justifyContent: "flex-start",
              paddingTop: 12,
              paddingBottom: 46,
              borderBottom: "1px solid #C8D2E5",
            }}
          >
            <div
              className="agent_photo_icon"
              style={{
                backgroundImage: `url(${
                  Boolean(
                    currentAgent.userPhotoId
                  )
                    ? `${process.env.REACT_APP_FILE_URL}/image/${currentAgent.userPhotoId}?token=${token}`
                    : userAvatar
                })`,
                marginRight: 40,
              }}
            />
            <span>
              <Typography
                variant="h6"
                style={{ fontWeight: 700 }}
              >
                {agentFullName}
              </Typography>
              <div
                className="flex_box"
                style={{
                  justifyContent: "flex-start",
                  margin: "12px 0px",
                }}
              >
                <img
                  src={phoneIcon}
                  alt="phone icon"
                  style={{ marginRight: 12 }}
                />
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#788090",
                    fontWeight: 400,
                  }}
                >
                  {currentAgent.phone}
                </Typography>
              </div>
              <div
                className="flex_box"
                style={{
                  justifyContent: "flex-start",
                }}
              >
                <img
                  src={emailIcon}
                  alt="email icon"
                  style={{ marginRight: 12 }}
                />
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#788090",
                    fontWeight: 400,
                  }}
                >
                  {currentAgent.email}
                </Typography>
              </div>
            </span>
          </div>
          <div
            className="flex_box"
            style={{
              marginTop: 32,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: "calc(50% - 50px)",
              }}
            >
              <Typography
                variant="h5"
                style={{ marginBottom: 21 }}
              >
                Реквизиты
              </Typography>
              {currentAgent.isCompanyEmployee ? (
                <Paper
                  style={{
                    border: "1px solid #C4C4C4",
                    borderRadius:
                      "8px !important",
                  }}
                >
                  <div
                    className="flex_box_between"
                    style={{
                      padding: 24,
                      borderBottom:
                        "1px solid #C4C4C4",
                    }}
                  >
                    <Typography variant="subtitle2">
                      Организация
                    </Typography>
                    <Typography variant="subtitle2">
                      {partners[currentAgent?.companyPartnerId]?.partnerName}
                    </Typography>
                  </div>
                  <div
                    className="flex_box_between"
                    style={{
                      padding: 24,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="subtitle2">
                      Районы
                    </Typography>
                    <div
                      className="flex_box"
                      style={{
                        gap: 6,
                        flexWrap: "wrap",
                        maxWidth:
                          "calc(100% - 80px)",
                        justifyContent:
                          "flex-end",
                      }}
                    >
                      {currentAgent.districtIds &&
                      currentAgent.districtIds
                        .length ? (
                        districtsState.flat().map(
                          (district) =>
                            currentAgent.districtIds.indexOf(
                              district.id
                            ) !== -1 && (
                              <div
                                className="tag"
                                key={district.id}
                              >
                                <Typography variant="caption">
                                  {
                                    district.districtName
                                  }
                                </Typography>
                              </div>
                            )
                        )
                      ) : (
                        <Typography variant="subtitle2">
                          Районы не выбраны
                        </Typography>
                      )}
                    </div>
                  </div>
                </Paper>
              ) : (
                <Paper
                  style={{
                    border: "1px solid #C4C4C4",
                    borderRadius:
                      "8px !important",
                  }}
                >
                  <div
                    className="flex_box_between"
                    style={{
                      padding: 24,
                      borderBottom:
                        "1px solid #C4C4C4",
                    }}
                  >
                    <Typography variant="subtitle2">
                      Банк
                    </Typography>
                    <Typography variant="subtitle2">
                      {currentAgent.bank}
                    </Typography>
                  </div>
                  <div
                    className="flex_box_between"
                    style={{ padding: 24 }}
                  >
                    <Typography variant="subtitle2">
                      Расчетный счет
                    </Typography>
                    <Typography variant="subtitle2">
                      {
                        currentAgent.paymentAccount
                      }
                    </Typography>
                  </div>
                </Paper>
              )}
            </div>
            <Divider
              flexItem
              orientation="vertical"
              style={{
                backgroundColor: "#C8D2E5",
                margin: "53px 50px 0px",
              }}
            />
            <div
              style={{
                width: "calc(50% + 50px)",
              }}
            >
              <Typography
                variant="h5"
                style={{ marginBottom: 21 }}
              >
                {olderThanSixteen
                  ? "Паспорт"
                  : "Свидетельство о рождении"}
              </Typography>
              {olderThanSixteen ? (
                <div
                  className="flex_box"
                  style={{
                    justifyContent: "flex-start",
                    maxWidth: "100%",
                    gap: 24,
                  }}
                >
                  {Boolean(
                    currentAgent.passportFrontId
                  ) && (
                    <img
                      className="passport_screen"
                      src={`${process.env.REACT_APP_FILE_URL}/image/${currentAgent.passportFrontId}?token=${token}`}
                      alt=""
                      style={{
                        maxWidth:
                          "calc(50% - 12px)",
                      }}
                    />
                  )}
                  {Boolean(
                    currentAgent.passportBackId
                  ) && (
                    <img
                      className="passport_screen"
                      src={`${process.env.REACT_APP_FILE_URL}/image/${currentAgent.passportBackId}?token=${token}`}
                      alt=""
                      style={{
                        maxWidth:
                          "calc(50% - 12px)",
                      }}
                    />
                  )}
                </div>
              ) : (
                Boolean(
                  currentAgent.birthCertificateId
                ) && (
                  <img
                    className="passport_screen"
                    src={`${process.env.REACT_APP_FILE_URL}/image/${currentAgent.birthCertificateId}?token=${token}`}
                    alt=""
                    style={{
                      maxWidth:
                        "calc(50% - 12px)",
                    }}
                  />
                )
              )}
            </div>
          </div>
        </>
      )}

      <AgentModal {...updateAgentModalProps} />
    </div>
  );
}
