import { Typography } from "@mui/material";
import React from "react";
import DatePicker from "react-multi-date-picker";

import { multiDatePickerRu } from "localization";
import calendarIcon from "assets/icons/Calendar.svg";
import moment from "moment";

export function ThemeMultipleDatesPicker({
  values,
  onChange,
  disabled = false,
  style,
  minDate,
}) {
  const datePicker = React.useRef();
  return (
    <div
      className="multiple_date_input_wrapper"
      style={style}
    >
      <div
        className="flex_box input"
        onClick={() => {
          if (!disabled) {
            let input =
              datePicker.current.firstChild;
            input.focus();
          }
        }}
      >
        <div
          className="flex_box"
          style={{ justifyContent: "flex-start" }}
        >
          {Boolean(values.length) &&
            values.map((date, index) => {
              return (
                <div
                  className="flex_box tag"
                  key={index}
                >
                  <Typography
                    variant="caption"
                    style={{
                      color:
                        "rgba(0, 0, 0, 0.87)",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    {moment(
                      new Date(date)
                    ).format("DD MMMM yyyy")}
                  </Typography>
                </div>
              );
            })}
        </div>
        <img
          src={calendarIcon}
          alt=""
        />
      </div>
      {!disabled && (
        <DatePicker
          ref={datePicker}
          value={values}
          onChange={(dates) => {
            onChange(dates);
          }}
          minDate={minDate}
          locale={multiDatePickerRu}
          weekStartDayIndex={1}
          multiple
        />
      )}
    </div>
  );
}
