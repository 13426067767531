export const moment_ru = {
  months: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split("_"),
  monthsParseExact: true,
  weekdays:
    "Суббота_Воскресенье_Среда_Четверг_Пятница_Понедельник_Вторник".split("_"),
  weekdaysShort: "Пнд_Втр_Срд_Чтв_Птн_Сбт_Вск".split("_"),
  weekdaysMin: "Пн_Вт_Ср_Чт_Пт_Сб_Вс".split("_"),
  weekdaysParseExact: true,
};
export const multiDatePickerRu = {
  name: "gregorian_ru",
  months: [
    ["Январь", "Янв"],
    ["Февраль", "Фев"],
    ["Март", "Мар"],
    ["Апрель", "Апр"],
    ["Май", "Май"],
    ["Июнь", "Июн"],
    ["Июль", "Июл"],
    ["Август", "Авг"],
    ["Сентябрь", "Сен"],
    ["Октябрь", "Окт"],
    ["Ноябрь", "Ноя"],
    ["Декабрь", "Дек"],
  ],
  weekDays: [
    ["Суббота", "Сб"],
    ["Воскресенье", "Вс"],
    ["Понедельник", "Пн"],
    ["Вторник", "Вт"],
    ["Среда", "Ср"],
    ["Четверг", "Чт"],
    ["Пятница", "Пт"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
};
