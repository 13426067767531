import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";

import { getCitiesSelect, getDistrictsSelect, idNotAll } from "api";
import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function CityAutocomplete({
  value,
  updateValue,
  countryId,
  validated = false,
  name = "cityId",
}) {
  const dispatch = useDispatch();
  const { citiesSelect } = useSelector((store) => store);
  const [stateValue, setStateValue] = useState(value);

  React.useEffect(() => {
    setStateValue((state) => (lodash.isEqual(value, state) ? state : value));
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function") {
      updateValue(option);
    }
    if (idNotAll(option)) {
      dispatch(
        getDistrictsSelect({
          cityId: option.id,
        })
      );
    }
  }

  const options = (
    validated || !value ? [] : [autocompletesInitialValues.city]
  ).concat(citiesSelect ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label="Город"
      options={options}
      getOptionLabel={(city) => city.cityName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fetchOptions={(searchText, callback) =>
        dispatch(
          getCitiesSelect(
            {
              countryId,
              searchText,
            },
            callback
          )
        )
      }
      validated={validated}
      name={name}
    />
  );
}
