import React, { useState } from "react";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Checkbox,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import { createStoreData } from "store";
import InfiniteScroll from "../InfiniteScroll";
import crossIcon from "assets/icons/Cross.svg";
import {
  ThemeButton,
  NoResult,
  FormInput,
} from "shared";
import {
  getOutletsList,
  getCountries,
  getDistricts,
  getCities,
} from "api";

export default function SalepointSelectModal({
  open,
  onClose,
  applicationDistrict,
  setApplicationDistricts,
  disabled = false,
  selectedOutletsList = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    countries,
    outletsList,
    countryCities,
    cityDistricts,
    outletsListSelect,
  } = useSelector((store) => store);
  const [arrayId, setArrayId] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] =
    useState("");
  const [selectedCity, setSelectedCity] =
    useState("");
  const [selectedCountry, setSelectedCountry] =
    useState("");
  const [selectedDistrict, setSelectedDistrict] =
    useState("");
  const [isFiltersDisabled, setFiltersDisabled] =
    useState(false);
  const [
    selectDataLoading,
    setSelectDataLoading,
  ] = useState({
    cities: false,
    districts: false,
  });
  let pageSize = 300;

  React.useEffect(() => {
    function updateComponentState(list) {
      let formattedArray = [];
      list.forEach((item) => {
        let lastRaw =
          formattedArray[
            formattedArray.length - 1
          ];
        if (
          typeof lastRaw === "undefined" ||
          lastRaw.length === 3
        ) {
          formattedArray.push([item]);
        } else {
          lastRaw.push(item);
        }
      });
      setLoading(false);
      setOutlets(formattedArray);
      setMaxLength(list.length);
      if (selectedOutletsList) {
        setArrayId(
          list.map((outlet) => outlet.id)
        );
      }
    }
    if (
      typeof outletsList[pageIndex] !==
        "undefined" &&
      !selectedOutletsList
    ) {
      updateComponentState(
        outletsList[pageIndex]
      );
    } else if (selectedOutletsList) {
      updateComponentState(selectedOutletsList);
    }
  }, [
    outletsList,
    outletsListSelect,
    pageIndex,
    selectedOutletsList,
    applicationDistrict,
  ]);
  React.useEffect(() => {
    if (typeof countries === "undefined") {
      dispatch(getCountries());
    }
  }, [dispatch, countries]);
  React.useEffect(() => {
    dispatch(
      getOutletsList({ pageIndex, pageSize })
    );
  }, [dispatch, pageIndex, pageSize]);
  React.useEffect(() => {
    if (Boolean(applicationDistrict?.state)) {
      const { country, city, district } =
        applicationDistrict.state;
      setSelectedCountry(
        Boolean(Object.keys(country).length)
          ? country
          : ""
      );
      setSelectedCity(
        Boolean(Object.keys(city).length)
          ? city
          : ""
      );
      if (Boolean(Object.keys(district).length)) {
        setSelectedDistrict(district);
        setFiltersDisabled(true);
      } else {
        setSelectedDistrict("");
        setFiltersDisabled(false);
      }
      setArrayId(
        applicationDistrict?.selectedOutlets ?? []
      );
    } else {
      setFiltersDisabled(false);
    }
  }, [applicationDistrict]);

  const getOutletsRequest = (params) => {
    let requestBody = {
      pageIndex: 0,
      pageSize,
      searchText,
      countryIds: [selectedCountry.id],
      cityIds: [selectedCity.id],
      districtIds: [selectedDistrict.id],
      ...params,
    };
    setLoading(true);
    dispatch(getOutletsList(requestBody));
  };
  const searchOutlets = (event, page = 0) => {
    const {
      target: { value },
    } = event;
    setLoading(true);
    setPageIndex(page);
    setSearchText(value);
    if (selectedOutletsList) {
      setOutlets(() => {
        let formattedArray = [],
          list = value.length
            ? selectedOutletsList.filter((e) =>
                e.name.match(
                  new RegExp(`^${value}`, "gi")
                )
              )
            : selectedOutletsList;
        list.forEach((item) => {
          let lastRaw =
            formattedArray[
              formattedArray.length - 1
            ];
          if (
            typeof lastRaw === "undefined" ||
            lastRaw.length === 3
          ) {
            formattedArray.push([item]);
          } else {
            lastRaw.push(item);
          }
        });
        setMaxLength(list.length);
        setLoading(false);
        return formattedArray;
      });
    } else {
      dispatch(
        getOutletsList({
          pageIndex: page,
          pageSize,
          searchText: value,
        })
      );
    }
  };
  const onAdd = () => {
    if (
      Boolean(arrayId.length) &&
      Boolean(selectedDistrict.id)
    ) {
      setApplicationDistricts((state) => {
        let index = state.length
          ? state.findIndex((item) => {
              return (
                item.state.district.id ===
                selectedDistrict.id
              );
            })
          : -1;
        return index === -1
          ? [
              ...state,
              {
                state: {
                  district: selectedDistrict,
                  city: selectedCity,
                  country: selectedCountry,
                },
                selectedOutlets: arrayId,
              },
            ]
          : state;
      });
    }

    handleClose();
  };
  const handleClose = () => {
    setArrayId([]);
    setSelectedDistrict([]);
    setFiltersDisabled(false);
    searchOutlets({
      target: { value: "" },
    });
    dispatch(
      createStoreData({ outletsList: {} })
    );
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <div
          className="flex_box sticky_header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Торговые точки
          </Typography>
          <IconButton onClick={handleClose}>
            <img
              src={crossIcon}
              alt="cross"
            />
          </IconButton>
        </div>
        {!disabled && (
          <div
            className="flex_box"
            style={{ gap: 20, marginTop: 30 }}
          >
            <div style={{ width: "33.33%" }}>
              <Typography
                variant="body2"
                className={classes.label}
              >
                Страна
              </Typography>
              <FormInput
                name="country"
                value={selectedCountry}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedCountry(value);
                  setSelectDataLoading(
                    (state) => ({
                      ...state,
                      cities: true,
                    })
                  );
                  dispatch(
                    getCities(
                      { countryId: value.id },
                      () =>
                        setSelectDataLoading(
                          (state) => ({
                            ...state,
                            cities: false,
                          })
                        )
                    )
                  );
                }}
                disabled={
                  disabled || isFiltersDisabled
                }
                fullWidth
                select
              >
                <MenuItem
                  value=""
                  style={{
                    position: "relative",
                    top: -1000,
                  }}
                ></MenuItem>
                {selectDataLoading.countries ? (
                  <div className="flex_box">
                    <CircularProgress size={20} />
                  </div>
                ) : Boolean(countries?.length) ? (
                  countries.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item}
                    >
                      {item.countryName}
                    </MenuItem>
                  ))
                ) : null}
              </FormInput>
            </div>
            <div style={{ width: "33.33%" }}>
              <Typography
                variant="body2"
                className={classes.label}
              >
                Город
              </Typography>
              <FormInput
                name="city"
                value={selectedCity}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedCity(value);
                  setSelectDataLoading(
                    (state) => ({
                      ...state,
                      districts: true,
                    })
                  );
                  dispatch(
                    getDistricts(
                      { cityId: value.id },
                      () =>
                        setSelectDataLoading(
                          (state) => ({
                            ...state,
                            districts: false,
                          })
                        )
                    )
                  );
                }}
                disabled={
                  disabled || isFiltersDisabled
                }
                fullWidth
                multiple
                select
              >
                <MenuItem
                  value=""
                  style={{
                    position: "relative",
                    top: -1000,
                  }}
                ></MenuItem>
                {selectDataLoading.cities ? (
                  <div className="flex_box">
                    <CircularProgress size={20} />
                  </div>
                ) : Boolean(
                    countryCities[
                      selectedCountry.id
                    ]?.length
                  ) ? (
                  countryCities[
                    selectedCountry.id
                  ].map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item}
                    >
                      {item.cityName}
                    </MenuItem>
                  ))
                ) : null}
              </FormInput>
            </div>
            <div style={{ width: "33.33%" }}>
              <Typography
                variant="body2"
                className={classes.label}
              >
                Район
              </Typography>
              <FormInput
                name="district"
                value={selectedDistrict}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedDistrict(value);
                  getOutletsRequest({
                    districtIds: [value.id],
                  });
                }}
                disabled={
                  disabled || isFiltersDisabled
                }
                fullWidth
                multiple
                select
              >
                <MenuItem
                  value=""
                  style={{
                    position: "relative",
                    top: -1000,
                  }}
                ></MenuItem>
                {selectDataLoading.districts ? (
                  <div className="flex_box">
                    <CircularProgress size={20} />
                  </div>
                ) : Boolean(
                    cityDistricts[selectedCity.id]
                      ?.length
                  ) ? (
                  cityDistricts[
                    selectedCity.id
                  ].map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item}
                    >
                      {item.districtName}
                    </MenuItem>
                  ))
                ) : null}
              </FormInput>
            </div>
          </div>
        )}
        <div
          className="flex_box"
          style={{
            justifyContent: "flex-start",
            flexWrap: "wrap",
            marginBottom: 110,
          }}
        >
          {Boolean(selectedDistrict.id) ||
          selectedOutletsList ? (
            <InfiniteScroll
              width={window.innerWidth * 0.8 - 80}
              height={window.innerHeight}
              maxLength={outlets.length}
              searchText={searchText}
              onChangeSearchText={setSearchText}
              onSearch={searchOutlets}
              RenderItem={({
                registerChild,
                index,
                style,
              }) => {
                let row = outlets[index];
                return (
                  <div
                    className="flex_box"
                    ref={registerChild}
                    style={{
                      ...style,
                      justifyContent:
                        "flex-start",
                      minHeight: 66,
                      gap: 20,
                      paddingBottom: 16,
                    }}
                  >
                    {Boolean(row.length) &&
                      row.map(
                        (item, itemIndex) => {
                          return (
                            <div
                              key={itemIndex}
                              className="flex_box"
                              style={{
                                width: "30%",
                                alignItems:
                                  "flex-start",
                                justifyContent:
                                  "flex-start",
                              }}
                            >
                              {!disabled && (
                                <Checkbox
                                  checked={
                                    arrayId.indexOf(
                                      item.id
                                    ) !== -1
                                  }
                                  onChange={(
                                    event
                                  ) => {
                                    setArrayId(
                                      (state) =>
                                        event
                                          .target
                                          .checked
                                          ? [
                                              ...state,
                                              item.id,
                                            ]
                                          : state.filter(
                                              (
                                                id
                                              ) =>
                                                id !==
                                                item.id
                                            )
                                    );
                                  }}
                                />
                              )}
                              <span>
                                <Typography
                                  variant="body1"
                                  style={{
                                    lineHeight:
                                      "19px",
                                  }}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  style={{
                                    display:
                                      "-webkit-box",
                                    WebkitBoxOrient:
                                      "vertical",
                                    WebkitLineClamp: 2,
                                    wordBreak:
                                      "break-word",
                                    color:
                                      "#757575",
                                    lineHeight:
                                      "14px",
                                  }}
                                  mt={1}
                                >
                                  {item.address}
                                </Typography>
                              </span>
                            </div>
                          );
                        }
                      )}
                  </div>
                );
              }}
              RenderHeader={() => (
                <div style={{ width: "100%" }}>
                  <div
                    className="flex_box"
                    style={{
                      gap: 32,
                      justifyContent:
                        "flex-start",
                      marginLeft: disabled
                        ? 0
                        : 12,
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    {!disabled && (
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "#757575",
                        }}
                      >{`Найдено: ${maxLength}`}</Typography>
                    )}
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#757575",
                      }}
                    >{`Выбрано: ${
                      disabled
                        ? maxLength
                        : arrayId.length
                    }`}</Typography>
                  </div>
                  {!disabled && (
                    <div
                      className="flex_box"
                      style={{
                        width:
                          "calc(33.33% - 20px)",
                        marginRight: 20,
                        marginBottom: 16,
                        justifyContent:
                          "flex-start",
                      }}
                    >
                      <Checkbox
                        checked={
                          maxLength ===
                            arrayId.length &&
                          !searchText
                        }
                        onChange={(event) => {
                          if (
                            event.target
                              .checked &&
                            Boolean(
                              outletsList[
                                pageIndex
                              ].length
                            )
                          ) {
                            let outlets =
                              outletsList[
                                pageIndex
                              ];
                            setArrayId(
                              outlets.map(
                                (outlet) =>
                                  outlet.id
                              )
                            );
                          } else {
                            setArrayId([]);
                          }
                        }}
                        disabled={disabled}
                      />
                      <span>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#757575",
                            lineHeight: "19px",
                          }}
                        >
                          Выбрать все
                        </Typography>
                      </span>
                    </div>
                  )}
                </div>
              )}
              onEndReachedThreshold={() => {
                searchOutlets(
                  {
                    target: { value: searchText },
                  },
                  pageIndex + 1
                );
              }}
              searchPlaceholder="Поиск"
            />
          ) : (
            <NoResult
              title="Выберите район"
              style={{ marginTop: 50 }}
            />
          )}
        </div>
        {isLoading ? (
          <div className="flex_box">
            <CircularProgress size={30} />
          </div>
        ) : !Boolean(outlets.length) ? (
          <NoResult title="Введите название магазина" />
        ) : null}
        {!disabled && (
          <div className="flex_box fixed_buttons">
            <ThemeButton
              type="button"
              variant="outlined"
              style={{ width: 150 }}
              onClick={handleClose}
            >
              Отмена
            </ThemeButton>
            <ThemeButton
              style={{
                width: 150,
              }}
              onClick={onAdd}
            >
              Добавить
            </ThemeButton>
            <ThemeButton
              type="button"
              variant="outlined"
              style={{ width: 150 }}
              onClick={handleClose}
            >
              Отмена
            </ThemeButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      width: "80%",
      maxWidth: "100%",
      position: "relative",
    },
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 64px)",
      paddingBottom: 130,
      overflowY: "hidden",
    },
  },
  label: {
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
}));
