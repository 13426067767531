import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import lodash from "lodash";

import {
  getOptionLabel,
  getPartnerListSelect,
  getSkuListSelect,
  idNotAll,
  isOptionEqualToValue,
} from "api";
import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function PartnerAutocomplete({
  value,
  updateValue,
  validated = false,
  name = "partnerId",
  label = "Партнер",
}) {
  const dispatch = useDispatch();
  const { partnersListSelect } = useSelector(
    (store) => store
  );
  const [stateValue, setStateValue] =
    useState(value);

  React.useEffect(() => {
    setStateValue((state) =>
      lodash.isEqual(value, state) ? state : value
    );
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function") {
      updateValue(option);
    }
    if (idNotAll(option)) {
      dispatch(
        getSkuListSelect({
          partnerId: option.id,
        })
      );
    }
  }
  const options = (
    validated || !value
      ? []
      : [autocompletesInitialValues.partner]
  ).concat(partnersListSelect ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label={label}
      options={partnersListSelect}
      getOptionLabel={(option) =>
        getOptionLabel(option, "partnerName")
      }
      isOptionEqualToValue={(option, value) =>
        isOptionEqualToValue(
          option,
          value,
          "partnerName",
          "id"
        )
      }
      fetchOptions={(searchText, callback) =>
        dispatch(
          getPartnerListSelect(
            {
              searchText,
            },
            callback
          )
        )
      }
      validated={validated}
      name={name}
    />
  );
}
