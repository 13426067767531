import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { login } from "api";
import logo from "assets/icons/logo_transparent.svg";
import { ValidatedFormInput, ThemeButton } from "shared";

export default function AuthorizationPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const submitHandler = (values) => {
    setLoading(true);
    dispatch(
      login(values, (role) => {
        if (role) {
          navigate(
            `/admin/${role === process.env.REACT_APP_ADMIN ? "agents" : "sku"}`
          );
        }
        setLoading(false);
      })
    );
  };
  return (
    <div className="flex_box" style={{ height: "100vh" }}>
      <Box className={classes.flexItem}>
        <div className="flex_box">
          <Formik
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Введенный email не корректный")
                .required("Поле обязательно к заполнению"),
              password: Yup.string().required("Поле обязательно к заполнению"),
            })}
            initialValues={{ email: "", password: "" }}
            onSubmit={submitHandler}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <img src={logo} alt="merchandising logo" />
                <Typography
                  variant="h3"
                  style={{ marginTop: 55, marginBottom: 50 }}>
                  Вход в систему
                </Typography>
                <ValidatedFormInput
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Введите email"
                />
                <ValidatedFormInput
                  type="password"
                  name="password"
                  label="Пароль"
                  placeholder="Введите пароль"
                />
                <ThemeButton
                  type="submit"
                  style={{ width: "100%", marginTop: 32 }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={20} style={{ color: "#FFFFFF" }} />
                  ) : (
                    "Войти"
                  )}
                </ThemeButton>
              </Form>
            )}
          </Formik>
        </div>
      </Box>
      <Box className={classes.flexItem} style={{ position: "relative" }}>
        <div className="unsplash" />
        <div className="unsplash_layer" />
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  flexItem: {
    width: "50%",
    height: "100%",
    backgroundColor: "#fff",
  },
}));
