import cookie_js from "cookie_js";
import moment from "moment";

export const autocompletesInitialValues = {
  country: {
    id: "all",
    countryName: "Все страны",
  },
  city: {
    id: "all",
    cityName: "Все города",
  },
  district: {
    id: "all",
    districtName: "Все районы",
  },
  partner: {
    id: "all",
    partnerName: "Все партнеры",
  },
  skuGroup: {
    skuGroupId: "all",
    groupName: "Все группы товаров",
  },
  sku: {
    skuId: "all",
    skuName: "Все товары",
  },
  salePointCategory: {
    referenceId: "all",
    value: "Все категории ТТ",
  },
  salePointType: {
    referenceId: "all",
    value: "Все типы ТТ",
  },
  brand: {
    referenceId: "all",
    value: "Все бренды",
  },
  typeOfPackaging: {
    referenceId: "all",
    value: "Все упаковки",
  },
  packagingFormat: {
    referenceId: "all",
    value: "Все форматы упаковки",
  },
};

export const initialState = {
  token: cookie_js.get("merchandising_token"),
  user: {},
  totals: {
    withdrawalRequests: {
      totalCount: 1,
      pageCount: 1,
    },
  },
  msl: {},
  agents: {},
  skuList: {},
  outlets: {},
  packages: {},
  partners: {},
  category: {},
  tasksList: {},
  countries: null,
  cities: null,
  districts: null,
  agentsList: {},
  skuOutlets: {},
  partnersSku: {},
  outletsList: {},
  citiesStore: {},
  taskResults: {},
  partnersList: {},
  partnerTotals: {},
  countryCities: {},
  cityDistricts: {},
  currentOutlet: {},
  districtsStore: {},
  countriesStore: {},
  applicationsList: {},
  outletsListSelect: {},
  withdrawalRequests: {},
  applicationsListSelect: [],
  currentApplication: {},
  transactionsHistory: {},
  registrationRequests: {},
  currentPartner: { id: "" },
  alert: { severity: "success", message: "" },
  partnersListSelect: [],
  countriesSelect: null,
  citiesSelect: null,
  districtsSelect: null,
  skuListSelect: [],
  brands: null,
  packagingFormats: null,
  typesOfPackaging: null,
  salePointTypes: null,
  categories: [],
  skuGroupListSelect: null,
  prompDialog: {
    open: false,
    confirm: () => {},
    reject: () => {},
    title: "",
    question: "",
    message: "",
    btnText: "",
    positive: true,
  },
  CaURackModal: {
    open: false,
    currentRack: {},
  },
  persisting: {
    Товары: 0,
    agentsTab: 0,
    skuPageSize: 20,
    directoryTab: 0,
    withdrawalTab: 0,
    agentsPageIndex: 0,
    outletPageSize: 20,
    "Торговые точки": 0,
    partnerSkuPageSize: 20,
    withdrawRequestsPageIndex: 0,
    transactionsHistoryPageIndex: 0,
    registrationRequestsPageIndex: 0,
  },
  directorySearch: {
    "Группы товаров": "",
    Товары: "",
    Бренд: "",
    Упаковка: "",
    "Формат упаковки": "",
    "Торговые точки": "",
    Страны: "",
    Города: "",
    Районы: "",
  },
  applicationsPersisting: {
    pageIndex: 0,
    status: "all",
    date: new Date().toISOString(),
    partner: {
      id: "all",
      partnerName: "Все партнеры",
    },
  },
  analiticsPersisting: {
    pageIndex: 0,
    status: "all",
    date: null,
    partner: {
      id: "all",
      partnerName: "Все партнеры",
    },
  },
  reportsByApplication: [],
  reportsBySector: [],
  reportsByGroupSku: [],
  reportsByShelfShares: [],
  reportsByShelfSharesSalePoint: [],
  reportsByShelfSharesPartners: [],
  reportsBySegmentBrands: [],
  reportsBySegmentTypeOfPackaging: [],
  reportsBySegmentPackagingFormat: [],
  reportsByMSL: [],
  reportsTotal: {
    reportsByShelfShares: {},
    reportsByShelfSharesSalePoint: {},
    reportsByShelfSharesPartners: {},
    reportsBySegmentBrands: {},
    reportsBySegmentTypeOfPackaging: {},
    reportsBySegmentPackagingFormat: {},
  },
  reportsPersisting: {
    reportType: 0,
    dateRange: [
      moment()
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toISOString(),
      moment()
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toISOString(),
    ],
    country: autocompletesInitialValues.country,
    city: autocompletesInitialValues.city,
    district: autocompletesInitialValues.district,
    partner: autocompletesInitialValues.partner,
    skuGroup: autocompletesInitialValues.skuGroup,
    sku: autocompletesInitialValues.sku,
    applicationIds: [],
    salePointCategory: autocompletesInitialValues.salePointCategory,
    brand: autocompletesInitialValues.brand,
    typeOfPackaging: autocompletesInitialValues.typeOfPackaging,
    packagingFormat: autocompletesInitialValues.packagingFormat,
  },
};
