import {
  getOptionLabel,
  getSkuListSelect,
  isOptionEqualToValue,
} from "api";
import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import lodash from "lodash";

import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function SkuAutocomplete({
  value,
  updateValue,
  partnerId,
  multiple = false,
  validated = false,
  name = "skuId",
  label = "Товары",
}) {
  const dispatch = useDispatch();
  const { skuListSelect } = useSelector(
    (store) => store
  );
  const [stateValue, setStateValue] =
    useState(value);

  React.useEffect(() => {
    setStateValue((state) =>
      lodash.isEqual(value, state) ? state : value
    );
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function")
      updateValue(option);
  }

  const options = (
    validated || !value
      ? []
      : [autocompletesInitialValues.sku]
  ).concat(skuListSelect ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label={label}
      multiple={multiple}
      options={options}
      getOptionLabel={(option) =>
        getOptionLabel(option, "skuName")
      }
      isOptionEqualToValue={(option, value) =>
        isOptionEqualToValue(
          option,
          value,
          "skuName",
          "skuId"
        )
      }
      fetchOptions={(searchText, callback) =>
        dispatch(
          getSkuListSelect(
            {
              partnerId,
              searchText,
            },
            callback
          )
        )
      }
      validated={validated}
      name={name}
    />
  );
}
