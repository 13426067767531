import { getReferences } from "api";
import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import lodash from "lodash";

import AutocompleteInput from "shared/AutocompleteInput";
import { autocompletesInitialValues } from "store/initialState";

export function PackagingFormatAutocomplete({
  value,
  updateValue,
  validated = false,
  name = "packagingFormatId",
}) {
  const dispatch = useDispatch();
  const { packagingFormats } = useSelector(
    (store) => store
  );
  const [stateValue, setStateValue] =
    useState(value);

  React.useEffect(() => {
    setStateValue((state) =>
      lodash.isEqual(value, state) ? state : value
    );
  }, [value]);

  function onChange(option) {
    setStateValue(option);
    if (typeof updateValue === "function")
      updateValue(option);
  }

  const options = (
    validated || !value
      ? []
      : [
          autocompletesInitialValues.packagingFormat,
        ]
  ).concat(packagingFormats ?? []);
  return (
    <AutocompleteInput
      value={stateValue}
      onChange={onChange}
      label="Формат упаковки"
      options={options}
      getOptionLabel={(option) => option.value}
      isOptionEqualToValue={(option, value) =>
        option.referenceId === value.referenceId
      }
      fetchOptions={(searchText, callback) =>
        dispatch(
          getReferences(
            "PackagingFormat",
            { searchText },
            callback
          )
        )
      }
      validated={validated}
      name={name}
    />
  );
}
