import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { NoResult } from "shared";
import chevronDown from "assets/icons/ChevronDown.svg";

const ByGroupSku = React.memo(
  ({ reportList, isLoading }) => {
    const classes = useStyles();
    const [skuGroupExpanded, setSkuGroupIndex] =
      useState(null);
    const [partnerExpanded, setPartnerIndex] =
      useState(null);

    function getValueFormatted(number, percent) {
      return typeof number === "number"
        ? `${number
            .toString()
            .match(/^\d+(?:\.\d{0,2})?/)}${
            percent ? "%" : ""
          }`
        : false;
    }
    return (
      <Box className={classes.tableContainer}>
        <Grid
          item
          xs={12}
          container
          className={classes.row}
          style={{
            padding: "16px 146px 16px 22px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid
            item
            className={classes.head}
            xs={5}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Группа товаров\Производитель
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Всего точек на маршруте\секторе
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Посещенно ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              % посещенных ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              ТТ где присутствовал товар
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Покрытие товара (по выбранному
              фильтру)
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Наличие ХО в ТТ
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.head}
            xs={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#7A8BA7",
                fontWeight: "bold",
              }}
            >
              Покрытие товара в ХО (по выбранному
              фильтру)
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <div
            className="flex_box"
            style={{ marginTop: 20 }}
          >
            <CircularProgress />
          </div>
        ) : reportList.length ? (
          reportList.map(
            (skuGroup, skuGroupIndex) => (
              <Accordion
                key={`${skuGroupIndex}-${skuGroup.skuGroupId}`}
                expanded={
                  skuGroupExpanded ===
                  skuGroupIndex
                }
                onChange={() => {
                  setSkuGroupIndex(
                    skuGroupExpanded ===
                      skuGroupIndex
                      ? false
                      : skuGroupIndex
                  );
                }}
                sx={{
                  borderTop: "1px solid #ECECEC",
                  "&:before": {
                    display: "none",
                  },
                  "&.Mui-expanded": {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      src={chevronDown}
                      alt=""
                    />
                  }
                >
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.row}
                    style={{
                      padding: "16px 22px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={5}
                    >
                      <Typography variant="body1">
                        {skuGroup?.skuGroupName ??
                          ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.salePointCount
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.visitedSalePoints
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.percentVisitedSalePoints,
                          true
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.numberOfSalePointsWhereProductWas
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.percentProductCoverageByFilter,
                          true
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.haveFridge
                        ) ?? ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.row}
                      style={{
                        padding: 0,
                      }}
                      xs={1}
                    >
                      <Typography variant="body1">
                        {getValueFormatted(
                          skuGroup?.percentProductCoverageByFilterInFridge,
                          true
                        ) ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {skuGroup.childItems.length ? (
                    skuGroup.childItems.map(
                      (partner, partnerIndex) => (
                        <Accordion
                          key={`${skuGroupIndex}-${partnerIndex}-${partner.partnerId}`}
                          expanded={
                            skuGroupExpanded ===
                              skuGroupIndex &&
                            partnerExpanded ===
                              partnerIndex
                          }
                          onChange={() => {
                            setPartnerIndex(
                              skuGroupExpanded ===
                                skuGroupIndex &&
                                partnerExpanded ===
                                  partnerIndex
                                ? false
                                : partnerIndex
                            );
                          }}
                          sx={{
                            borderTop:
                              "1px solid #ECECEC",
                            "&:before": {
                              display: "none",
                            },
                            "&.Mui-expanded": {
                              margin: 0,
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <img
                                src={chevronDown}
                                alt=""
                              />
                            }
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              className={
                                classes.row
                              }
                              style={{
                                padding:
                                  "16px 22px",
                                backgroundColor:
                                  "#FFFFFF",
                              }}
                            >
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={5}
                              >
                                <Typography variant="body1">
                                  {partner?.partnerName ??
                                    ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.salePointCount
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.visitedSalePoints
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.percentVisitedSalePoints,
                                    true
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.numberOfSalePointsWhereProductWas
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.percentProductCoverageByFilter,
                                    true
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.haveFridge
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                className={
                                  classes.row
                                }
                                style={{
                                  padding: 0,
                                }}
                                xs={1}
                              >
                                <Typography variant="body1">
                                  {getValueFormatted(
                                    partner?.percentProductCoverageByFilterInFridge,
                                    true
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            {partner.childItems
                              .length ? (
                              partner.childItems.map(
                                (
                                  sku,
                                  skuIndex,
                                  array
                                ) => {
                                  return (
                                    <React.Fragment
                                      key={`${skuGroupIndex}-${partnerIndex}-${skuIndex}-${sku.skuId}`}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        container
                                        className={
                                          classes.row
                                        }
                                        style={{
                                          padding:
                                            "16px 146px 16px 22px",
                                          backgroundColor:
                                            "#FFFFFF",
                                        }}
                                      >
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={5}
                                        >
                                          <Typography variant="body1">
                                            {sku?.skuName ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.salePointCount
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.visitedSalePoints
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.percentVisitedSalePoints,
                                              true
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.numberOfSalePointsWhereProductWas
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.percentProductCoverageByFilter,
                                              true
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.haveFridge
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          className={
                                            classes.row
                                          }
                                          style={{
                                            padding: 0,
                                          }}
                                          xs={1}
                                        >
                                          <Typography variant="body1">
                                            {getValueFormatted(
                                              sku?.percentProductCoverageByFilterInFridge,
                                              true
                                            ) ??
                                              ""}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      {array.length >
                                        1 &&
                                        skuIndex !==
                                          array.length -
                                            1 && (
                                          <Divider />
                                        )}
                                    </React.Fragment>
                                  );
                                }
                              )
                            ) : (
                              <NoResult
                                style={{
                                  height: 100,
                                }}
                                title="Данные не найдены"
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )
                    )
                  ) : (
                    <NoResult
                      style={{ height: 100 }}
                      title="Данные не найдены"
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )
        ) : (
          <NoResult
            title="Данные не найдены"
            style={{ marginTop: 20 }}
          />
        )}
      </Box>
    );
  }
);

export default ByGroupSku;

const useStyles = makeStyles((theme) => ({
  head: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center !important",
      fontSize: "12px !important",
    },
  },
  row: {
    padding: "0px 5px",
    wordWrap: "break-word",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: 400,
      fontSize: 15,
    },
  },
}));
