import React from "react";
import { makeStyles } from "@mui/styles";
import { Pagination, Typography, Grid, IconButton } from "@mui/material";

import trash from "assets/icons/Trash.svg";
import view from "assets/icons/Eye.svg";
import edit from "assets/icons/Edit.svg";
import pin from "assets/icons/GreyPin.svg";
import PageSizeSelector from "./PageSizeSelector";
import { ThemeButton } from "shared";

export default function CustomDataTable({
  pageCount,
  pageIndex,
  setPageIndex,
  onView,
  onEdit,
  onDelete,
  onProcessed,
  pageSize,
  changePageSize,
  table = [],
  disableSelect = false,
}) {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={0} className={classes.tableContainer}>
        {Boolean(table.length) &&
          table.map((row, rowIndex) => {
            let isActiveRow = row[row.length - 1] === "activeItem",
              borderBottom = {
                padding: "16px 22px",
                borderBottom:
                  rowIndex + 1 !== table.length
                    ? `1px solid ${false ? "#FFFFFF" : "#F4F4F5"}`
                    : "none",
              };
            return (
              <Grid
                container
                item
                xs={12}
                key={rowIndex}
                className={
                  Boolean(rowIndex) && !disableSelect
                    ? classes.tableRow
                    : undefined
                }
                onClick={() =>
                  !disableSelect && rowIndex ? onView(rowIndex - 1) : undefined
                }
                style={
                  typeof row[0] === "object" && Boolean(row[0].rowStyle)
                    ? { ...borderBottom, ...row[0].rowStyle }
                    : borderBottom
                }>
                {Boolean(row.length) &&
                  row.map((column, columnIndex) => {
                    if (column === null) {
                      throw new Error(
                        `Значение колонны ${
                          table[0][columnIndex].name
                        } равно null. Индекс строки ${rowIndex}. Строка:\n${JSON.stringify(
                          row
                        )}`
                      );
                    }
                    return column !== "activeItem" ? (
                      rowIndex === 0 ? (
                        <Grid
                          item
                          {...column.props}
                          className={classes.tableCell}
                          key={`${rowIndex}.${columnIndex}`}>
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#7A8BA7", fontWeight: "bold" }}>
                            {column.name}
                          </Typography>
                        </Grid>
                      ) : Array.isArray(column) ? (
                        <Grid
                          item
                          xs={1}
                          className={classes.tableCell}
                          key={`${rowIndex}.${columnIndex}`}>
                          {Boolean(column.length) &&
                            column.map((action) =>
                              action === "processed" ? (
                                <ThemeButton
                                  key={action + rowIndex}
                                  onClick={() => onProcessed(rowIndex - 1)}>
                                  Исполнить
                                </ThemeButton>
                              ) : (
                                <IconButton
                                  key={action + rowIndex}
                                  style={{
                                    position: "relative",
                                    zIndex: 10,
                                    pointerEvents: "all",
                                  }}
                                  onClick={() =>
                                    action === "view"
                                      ? onView(rowIndex - 1)
                                      : action === "edit"
                                      ? onEdit(rowIndex - 1)
                                      : action === "delete"
                                      ? onDelete(rowIndex - 1)
                                      : () => alert("Not found action")
                                  }>
                                  <img
                                    src={
                                      action === "view"
                                        ? view
                                        : action === "edit"
                                        ? edit
                                        : action === "delete"
                                        ? trash
                                        : action === "pin"
                                        ? pin
                                        : "Not found action"
                                    }
                                    alt=""
                                  />
                                </IconButton>
                              )
                            )}
                        </Grid>
                      ) : (
                        ((typeof column === "object" &&
                          typeof column.rowStyle === "undefined") ||
                          typeof column !== "object") && (
                          <Grid
                            item
                            {...table[0][columnIndex].props}
                            className={classes.tableCell}
                            key={`${rowIndex}.${columnIndex}`}>
                            {typeof column === "object" ? (
                              column
                            ) : (
                              <Typography
                                className={classes.cellText}
                                style={{
                                  fontWeight: isActiveRow ? 700 : 400,
                                }}>
                                {column}
                              </Typography>
                            )}
                          </Grid>
                        )
                      )
                    ) : null;
                  })}
              </Grid>
            );
          })}
      </Grid>
      <div className="flex_box" style={{ marginTop: 33, marginBottom: 27 }}>
        <div className="flex_box">
          {pageCount > 1 && (
            <Pagination
              page={pageIndex + 1}
              onChange={(_, page) => setPageIndex(page - 1)}
              count={pageCount}
              siblingCount={2}
              boundaryCount={1}
              hideNextButton={true}
              hidePrevButton={true}
              classes={{ root: classes.pagination }}
            />
          )}
        </div>
        {pageSize && (
          <PageSizeSelector
            pageSize={pageSize}
            onChangePageSize={changePageSize}
          />
        )}
      </div>
    </>
  );
}

const useStyles = makeStyles({
  separator: {
    color: "#000",
  },
  error: {
    color: "red",
    textAlign: "center",
    width: "100%",
  },
  pageSizeSelect: {
    height: 40,
    width: "70px !important",

    "& fieldset": {
      width: "100%",
      border: "1px solid #ECECF6",
      borderRadius: 6,
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
    "& .MuiSelect-select": {
      fontFamily: "Roboto !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      color: "#000000 !important",
    },
  },
  pagination: {
    marginRight: 30,
    "& .MuiPaginationItem-root": {
      height: 25,
      width: 25,
      minWidth: 25,
      color: "#757575",
    },
    // "& .MuiPaginationItem-root.Mui-selected": {
    //   color: "#034F94",
    //   backgroundColor: "#E9EFFA",
    // },
  },
  tableContainer: { backgroundColor: "#FFFFFF", borderRadius: 6 },
  tableRow: {
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#f1f2f3",
    },
  },
  tableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 10px",
  },
  cellText: {
    fontFamily: "Inter",
    fontSize: 15,
  },
});
