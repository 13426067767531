import React from "react";
import { Typography } from "@mui/material";

import noResult from "assets/icons/noResult.svg";

export function NoResult({
  title,
  style = {},
  withImage = true,
}) {
  return (
    <div
      className="flex_box"
      style={{ height: "30vh", ...style }}
    >
      <div
        style={{
          height: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          component="p"
          style={{
            color: "#034F94",
            textAlign: "center",
            marginBottom: "calc(100% / 10)",
          }}
        >
          {title}
        </Typography>
        {withImage && (
          <img
            alt=""
            src={noResult}
            style={{
              height: "calc(100% - (10% + 24px))",
            }}
          />
        )}
      </div>
    </div>
  );
}
