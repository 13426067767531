import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  updateStoreData,
  createStoreData,
} from "store";
import {
  getTransactionsHistory,
  getWithdrawalList,
  withdrawalRequestProcessed,
} from "api";
import { HocMini, TabsComponent } from "shared";
import CustomDataTable from "components/CustomDataTable";
import moment from "moment";

const tabs = [
  "Заявки на вывод средств",
  "История транзакций",
];

export function Withdrawal() {
  const dispatch = useDispatch();
  const {
    totals,
    withdrawalRequests,
    transactionsHistory,
    persisting: {
      withdrawRequestsPageIndex,
      transactionsHistoryPageIndex,
      withdrawalTab,
    },
  } = useSelector((store) => store);
  const [
    isWithdrawalRequestsLoading,
    setWithdrawalRequestsLoading,
  ] = useState(false);
  const [
    isTransactionHistoryLoading,
    setTransactionHistoryLoading,
  ] = useState(false);
  const [withdrawalTable, setWithdrawalTable] =
    useState([]);
  const [
    transactionsHistoryTable,
    setTransactionsHistoryTable,
  ] = useState([]);
  let isWithdrawalRequests =
      tabs[withdrawalTab] ===
      "Заявки на вывод средств",
    isTransactionHistory =
      tabs[withdrawalTab] ===
      "История транзакций";

  React.useEffect(() => {
    if (isWithdrawalRequests) {
      if (
        typeof withdrawalRequests[
          withdrawRequestsPageIndex
        ] !== "undefined"
      ) {
        setWithdrawalTable([
          withdrawalTableData,
          ...withdrawalRequests[
            withdrawRequestsPageIndex
          ].map((request) => [
            <div
              className="flex_box"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="subtitle2">
                {`${request.name} ${request.surname}`}
              </Typography>
            </div>,
            request.phone,
            request.bank ?? "",
            request.paymentAccount ?? "",
            request.sum
              .toString()
              .replace(
                /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                "$1 "
              ),
            ["processed"],
          ]),
        ]);
        setWithdrawalRequestsLoading(false);
      } else {
        setWithdrawalRequestsLoading(true);
        dispatch(
          getWithdrawalList({
            pageIndex: withdrawRequestsPageIndex,
          })
        );
      }
    }
    if (isTransactionHistory) {
      if (
        typeof transactionsHistory[
          transactionsHistoryPageIndex
        ] !== "undefined"
      ) {
        setTransactionsHistoryTable([
          transactionsHistoryTableData,
          ...transactionsHistory[
            transactionsHistoryPageIndex
          ].map((transaction) => [
            <div
              className="flex_box"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="subtitle2">
                {`${transaction.name} ${transaction.surname}`}
              </Typography>
            </div>,
            transaction.phone,
            transaction.bank ?? "",
            transaction.paymentAccount ?? "",
            transaction.sum
              .toString()
              .replace(
                /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                "$1 "
              ),
            moment(
              transaction.requestDate
            ).format("DD.MM.YYYY"),
            moment(
              transaction.paymentDate
            ).format("DD.MM.YYYY"),
          ]),
        ]);
        setTransactionHistoryLoading(false);
      } else {
        setTransactionHistoryLoading(true);
        dispatch(
          getTransactionsHistory({
            pageIndex:
              transactionsHistoryPageIndex,
          })
        );
      }
    }
  }, [
    dispatch,
    withdrawalRequests,
    setWithdrawalTable,
    transactionsHistory,
    isTransactionHistory,
    isWithdrawalRequests,
    withdrawRequestsPageIndex,
    transactionsHistoryPageIndex,
  ]);

  function onChangeTab(tab) {
    dispatch(
      updateStoreData({
        key: "persisting",
        data: { withdrawalTab: tab },
      })
    );
    if (tabs[tab] === "Заявки на вывод средств") {
      setWithdrawalRequestsLoading(true);
      dispatch(
        getWithdrawalList({
          pageIndex: withdrawRequestsPageIndex,
        })
      );
    }
    if (tabs[tab] === "История транзакций") {
      setTransactionHistoryLoading(true);
      dispatch(
        getTransactionsHistory({
          pageIndex: transactionsHistoryPageIndex,
        })
      );
    }
  }
  const withdrawRequestsTableProps = {
    pageIndex: withdrawRequestsPageIndex,
    setPageIndex: (page) =>
      dispatch(
        updateStoreData({
          key: "persisting",
          data: {
            withdrawRequestsPageIndex: page,
          },
        })
      ),
    pageCount:
      totals.withdrawalRequests?.totalPages || 1,
    onProcessed: (index) => {
      let currentRequest =
        withdrawalRequests[
          withdrawRequestsPageIndex
        ][index];
      dispatch(
        createStoreData({
          prompDialog: {
            open: true,
            title:
              "Исполнение запроса на вывод средств",
            question:
              "Вы подтверждаете исполнение заявки на вывод средств ?",
            btnText: "Да, подтверждаю",
            positive: true,
            confirm: () => {
              dispatch(
                withdrawalRequestProcessed(
                  currentRequest.withdrawalRequestId,
                  (success) => {
                    if (success) {
                      dispatch(
                        createStoreData({
                          alert: {
                            severity: "success",
                            message:
                              "Заявка успешно исполнена",
                          },
                        })
                      );
                    }
                  }
                )
              );
            },
          },
        })
      );
    },
    table: withdrawalTable,
    disableSelect: true,
  };
  const transactionsHistoryTableProps = {
    pageIndex: transactionsHistoryPageIndex,
    setPageIndex: (page) =>
      dispatch(
        updateStoreData({
          key: "persisting",
          data: {
            transactionsHistoryPageIndex: page,
          },
        })
      ),
    pageCount:
      totals.transactionsHistory?.totalPages || 1,
    table: transactionsHistoryTable,
    disableSelect: true,
  };
  return (
    <div>
      <header
        className="flex_box"
        style={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">
          Заявки на вывод средств
        </Typography>
      </header>
      <TabsComponent
        tab={withdrawalTab}
        setTab={onChangeTab}
        buttons={tabs}
      />
      <div
        style={{ width: "100%", height: 50 }}
      ></div>
      {isWithdrawalRequests && (
        <HocMini
          haveData={Boolean(
            totals.withdrawalRequests?.totalCount
          )}
          isLoading={isWithdrawalRequestsLoading}
        >
          <CustomDataTable
            {...withdrawRequestsTableProps}
          />
        </HocMini>
      )}
      {isTransactionHistory && (
        <HocMini
          haveData={Boolean(
            totals.transactionsHistory?.totalCount
          )}
          isLoading={isTransactionHistoryLoading}
        >
          <CustomDataTable
            {...transactionsHistoryTableProps}
          />
        </HocMini>
      )}
    </div>
  );
}

const withdrawalTableData = [
  {
    name: "ФИО",
    props: {
      xs: 2,
    },
  },
  {
    name: "Телефон",
    props: {
      xs: 2,
    },
  },
  {
    name: "Банк",
    props: {
      xs: 2,
    },
  },
  {
    name: "Счет",
    props: {
      xs: 2,
    },
  },
  {
    name: "Сумма",
    props: {
      xs: 2,
    },
  },
  {
    name: "Действие",
    props: {
      xs: 2,
    },
  },
];
const transactionsHistoryTableData = [
  {
    name: "ФИО",
    props: {
      xs: 2,
    },
  },
  {
    name: "Телефон",
    props: {
      xs: 2,
    },
  },
  {
    name: "Банк",
    props: {
      xs: 2,
    },
  },
  {
    name: "Счет",
    props: {
      xs: 2,
    },
  },
  {
    name: "Сумма",
    props: {
      xs: 1,
    },
  },
  {
    name: "Дата запроса",
    props: {
      xs: 1.5,
    },
  },
  {
    name: "Дата выплаты",
    props: {
      xs: 1.5,
    },
  },
];
