import React from "react";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, useField } from "formik";
import { Typography } from "@mui/material";
import { FormInput } from "./TextFields";

export function ValidatedFormInput({
  exception,
  label,
  ...props
}) {
  const classes = useStyles();
  const [field, meta] = useField(props);
  let error = Boolean(meta.touched && meta.error);
  return (
    <>
      {label && (
        <Typography
          variant="body2"
          className={classes.label}
          style={{ marginTop: 24 }}
        >
          {label}
        </Typography>
      )}
      <FormInput
        value={field.value}
        {...field}
        error={error}
        helperText={
          Boolean(exception) ? (
            exception
          ) : error ? (
            <ErrorMessage name={field.name} />
          ) : (
            ""
          )
        }
        fullWidth
        {...props}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
}));
