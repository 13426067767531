import React from "react";
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
} from "react-virtualized";
import lodash from "lodash";

import SearchIcon from "assets/icons/Search.svg";
import { StyledTextField } from "./SearchComponent";
import { DEBOUNCE_DELEY } from "utils/constants";

// In this example, average cell height is assumed to be about 50px.
// This value will be used for the initial `Grid` layout.
// Width is not dynamic.
const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

export default function InfiniteScroll({
  width,
  height,
  maxLength,
  searchText,
  onChangeSearchText,
  RenderItem,
  hasNextPage,
  RenderHeader = false,
  searchPlaceholder,
  onSearch = () => {},
  onEndReachedThreshold,
}) {
  const rowRenderer = ({
    index,
    key,
    parent,
    style,
  }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      columnIndex={0}
      parent={parent}
      rowIndex={index}
    >
      {({ registerChild }) => (
        <RenderItem
          registerChild={registerChild}
          style={style}
          index={index}
        />
      )}
    </CellMeasurer>
  );
  const debouncedSearch = lodash.debounce(
    onSearch,
    DEBOUNCE_DELEY
  );
  function handleOnChange(value) {
    onChangeSearchText(value);
    debouncedSearch(value);
  }
  return (
    <>
      <div
        style={{ width: "100%", paddingTop: 32 }}
        onClick={(e) => e.stopPropagation()}
      >
        <StyledTextField
          fullWidth
          autoFocus
          value={searchText}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
          placeholder={searchPlaceholder}
          InputProps={{
            startAdornment: (
              <img
                style={{ marginRight: 12 }}
                src={SearchIcon}
                alt="search icon"
              />
            ),
          }}
          style={{
            marginBottom: 20,
          }}
        />
      </div>
      {RenderHeader && <RenderHeader />}
      <InfiniteLoader
        isRowLoaded={({ index }) =>
          !hasNextPage || index < maxLength
        }
        loadMoreRows={onEndReachedThreshold}
        rowCount={1000}
      >
        {({ onRowsRendered, registerChild }) => (
          <List
            width={width}
            height={height ?? 300}
            ref={registerChild}
            rowCount={maxLength}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            onRowsRendered={onRowsRendered}
          />
        )}
      </InfiniteLoader>
    </>
  );
}
