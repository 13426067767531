import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DatePickerReport(props) {
  const { label, value, onChange } = props;

  const [date, setDate] = React.useState(dayjs(value));

  const dateChange = (newDate) => {
    setDate(newDate);
    onChange(`${newDate.format("YYYY-MM-DD")}T18:00:00.000Z`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        sx={{
          "& .MuiOutlinedInput-root": { lineHeight: "40px" },
          "& .MuiOutlinedInput-input": {
            paddingTop: "9.5px",
            paddingBottom: "9.5px",
            fontWeight: 400,
          },
          width: "170px",
          bgcolor: "#ffffff",
        }}
        value={date}
        onChange={(newValue) => dateChange(newValue)}
        format="DD.MM.YYYY"
      />
    </LocalizationProvider>
  );
}
